import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";

const VerifyButton = ({ loading, nonLoadingContent, onClick, disabled }) => {
  return (
    <button
      style={{
        width: "fit-content",
        opacity: disabled ? 0.6 : 1, // Visually indicate disabled state
        cursor: disabled ? "not-allowed" : "pointer",
      }}
      className="btn_brnd-outline mr-2"
      type="button"
      onClick={disabled ? null : onClick} // Prevent interaction when disabled
      disabled={disabled || loading} // Prevent interaction when disabled or loading
    >
      {loading ? (
        <ThreeDots
          height="20"
          width="52"
          radius="9"
          color="#ed1717"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            display: "block",
          }}
          wrapperClassName="spinner"
          visible={true}
        />
      ) : (
        <>{nonLoadingContent}</>
      )}
    </button>
  );
};

export default VerifyButton;
