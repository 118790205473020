import React from "react";
import Header from "../../layouts/header";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { downloadBudget, getBudgetanalysisData } from "../../api/server";
import { Link, useLocation } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";

const BudgetAnalysisExpenses = () => {
  const user = useSelector((state) => state.user);
  const [getloading, setGetLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const retake = Boolean(
    queryParams.get("retake") !== null &&
      queryParams.get("retake") != "null" &&
      queryParams.get("retake") != "false" &&
      queryParams.get("retake") !== false
  );
  const [showData, setshowData] = useState();

  const [updateLoading, setUpdateLoading] = useState(false);

  const handleDownload = async (values) => {
    setUpdateLoading(true);
    try {
      const response = await downloadBudget(
        values.budgetAnalysis.userid,
        values
      );
      if (response) {
        window.open(response.data.documentLink);
        setUpdateLoading(false);
      }
    } catch (error) {
      setUpdateLoading(false);
      toast.error(error.response.data.error);
      console.error("Error updating user data:", error);
    }
  };

  useEffect(() => {
    getBudgetanalysisData(user.user.data.id)
      .then((res) => {
        setGetLoading(false);
        setshowData(res.data);
      })
      .catch((err) => {
        setGetLoading(false);
      });
  }, []);
  return (
    <div>
      <div className="theme_container mt-250">
        <Header />
        <h1 className="card-title mb-4">Budget Analysis</h1>
        <section className="breadcrumb-section d-lg-flex justify-content-between align-items-center">
          <nav
            style={{ "--bs-breadcrumb-divider": " '>';" }}
            aria-label="breadcrumb"
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Clients Budget Analysis</a>
              </li>
              <li className="breadcrumb-item">Income</li>
              <li className="breadcrumb-item">Expenses</li>
              <li className="breadcrumb-item " aria-current="page">
                Debts
              </li>
            </ol>
          </nav>
          {!getloading && (
            <button
              className="btn_brnd"
              type="submit"
              disabled={updateLoading}
              onClick={() => {
                handleDownload(showData);
              }}
            >
              {updateLoading ? (
                <ThreeDots
                  height="20"
                  width="217"
                  radius="9"
                  color="#4fa94d"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{
                    display: "block",
                  }}
                  wrapperClassName="spinner"
                  visible={true}
                />
              ) : (
                "Download Budget Analysis"
              )}
            </button>
          )}
        </section>
        <section className="budget-analysis">
          {getloading ? (
            <div className="d-flex justify-content-center">
              <ThreeDots
                height="40"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                  display: "flex",
                  justifyContent: "center",
                }}
                wrapperClassName="spinner"
                visible={true}
              />
            </div>
          ) : (
            <>
              {showData.Tab3.expenseSecFirst.expenseSecFirstParaFirstText && (
                <div className="card main-card">
                  <div className="card-body ">
                    <div className="row align-items-center">
                      <div className="col-lg-9">
                        <h6
                          dangerouslySetInnerHTML={{
                            __html:
                              showData.Tab3.expenseSecFirst
                                .expenseSecFirstParaFirstText,
                          }}
                        ></h6>
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              showData.Tab3.expenseSecFirst
                                .expenseSecFirstParaSecondText,
                          }}
                        ></p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              showData.Tab3.expenseSecFirst
                                .expenseSecFirstParaThirdText,
                          }}
                        ></p>
                      </div>
                      <div className="col-lg-3 text-lg-end">
                        <button className="grade-btn btn_brnd mb-2">
                          <span>${showData.Tab3.expenseSecFirst.leftover}</span>
                          The amount you are overspending each month.
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {showData.Tab3.expenseSecSecond.debtToIncomeParaFirst && (
                <div className="card main-card">
                  <div className="card-body ">
                    <div className="row align-items-center">
                      <div className="col-lg-9">
                        <h6
                          className="text_danger"
                          dangerouslySetInnerHTML={{
                            __html:
                              showData.Tab3.expenseSecSecond
                                .debtToIncomeParaFirst,
                          }}
                        ></h6>
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              showData.Tab3.expenseSecSecond
                                .debtToIncomeParaSecond,
                          }}
                        ></p>
                      </div>
                      <div className="col-lg-3 text-lg-end">
                        <button className="grade-btn btn_brnd mb-2">
                          {showData.Tab3.expenseSecSecond.debtToIncome !==
                            null && (
                            <span>
                              {showData.Tab3.expenseSecSecond.debtToIncome.toFixed(
                                2
                              )}
                              %
                            </span>
                          )}
                          Percent of your income going to housing and debt.
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {showData.Tab3.housingSecThird.housingSecParaFirst && (
                <div className="card main-card">
                  <div className="card-body p-0">
                    <div className="row ">
                      <div className="col-lg-6 bordr-right">
                        <div className="p-lg-4 p-2">
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                showData.Tab3.housingSecThird.housingLeftSide,
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-lg-4 p-2">
                          <h6
                            className="text_success"
                            dangerouslySetInnerHTML={{
                              __html:
                                showData.Tab3.housingSecThird
                                  .housingSecParaFirst,
                            }}
                          ></h6>
                          <p
                            className="mb-0"
                            dangerouslySetInnerHTML={{
                              __html:
                                showData.Tab3.housingSecThird
                                  .housingSecParaSecond,
                            }}
                          ></p>
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                showData.Tab3.housingSecThird
                                  .housingSecParaThird,
                            }}
                          ></p>
                          <h6
                            className="text_success"
                            dangerouslySetInnerHTML={{
                              __html:
                                showData.Tab3.housingSecThird
                                  .housingSecParaFourth,
                            }}
                          ></h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {showData.Tab3.paymentSecFourth.paymentSecParaFirst && (
                <div className="card main-card">
                  <div className="card-body p-0">
                    <div className="row ">
                      <div className="col-lg-6 bordr-right">
                        <div className="p-lg-4 p-2">
                          <h6
                            className="text_danger"
                            dangerouslySetInnerHTML={{
                              __html:
                                showData.Tab3.paymentSecFourth
                                  .paymentSecLeftParaFirst,
                            }}
                          ></h6>
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                showData.Tab3.paymentSecFourth
                                  .paymentSecLeftParaSecond,
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-lg-4 p-2">
                          <h6
                            className="text_success"
                            dangerouslySetInnerHTML={{
                              __html: showData.Tab3.expenseSecFirst.leftover,
                            }}
                          ></h6>
                          <p
                            className="mb-0"
                            dangerouslySetInnerHTML={{
                              __html:
                                showData.Tab3.paymentSecFourth
                                  .paymentSecParaSecond,
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {showData.Tab3.expenseSecFirst.leftover && (
                <div className="card main-card">
                  <div className="card-body">
                    <div className="row ">
                      <div className="col-lg-12 text-center pt-4">
                        <p>
                          Net income leftover after expenses are paid{" "}
                          <b className="text_success">
                            {showData.Tab3.leftoverTab3}
                          </b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="text-center my-4">
                <Link to={`/budget-analysis-income?retake=${retake}`}>
                  {" "}
                  <a href="#" className="text_success mt-4 me-3">
                    <u>Previous</u>
                  </a>
                </Link>
                <Link to={`/budget-analysis-debts?retake=${retake}`}>
                  <button className="btn_brnd-outline mt-4 me-3">Next</button>
                </Link>
                <Link to={`/add-info-multistep?retake=${retake}`}>
                  {" "}
                  <button className="btn_brnd mt-4 me-3">
                    Add More Info to My Budget
                  </button>
                </Link>
              </div>
            </>
          )}
        </section>
      </div>
    </div>
  );
};

export default BudgetAnalysisExpenses;
