import React from "react";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { courseData2, courseUpdate2 } from "../../api/server";
import { useLocation, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";

const Course2 = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");
  const retake = Boolean(
    queryParams.get("retake") !== null &&
      queryParams.get("retake") != "null" &&
      queryParams.get("retake") != "false" &&
      queryParams.get("retake") !== false
  );
  const navigate = useNavigate();
  const [getloading, setGetLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [courseInfo2, setCourseInfo2] = useState(null);
  const user = useSelector((state) => state.user);
  const isJoint = localStorage.getItem("isJoint");

  async function fetchCourseInfo2() {
    try {
      const response = await courseData2(
        userId ? "admin" : "user",
        userId ? userId : user.user.data.id,
        isJoint == "true" ? "true" : "false",
        retake ? 1 : 0
      );

      setCourseInfo2(response.data);
      setGetLoading(false);
      setUpdateLoading(false);
    } catch (error) {
      setGetLoading(false);
      setUpdateLoading(false);
      console.error("Error fetching course data:", error);
    }
  }
  useEffect(() => {
    fetchCourseInfo2();
  }, []);

  const handleComplete = async (rank) => {
    setUpdateLoading(true);
    const updatedCourseInfo = { audio: `${rank}` };
    await courseUpdate2(
      userId ? "admin" : "user",
      userId ? userId : user.user.data.id,
      updatedCourseInfo,
      isJoint == "true" ? "true" : "false",
      retake ? 1 : 0
    )
      .then((res) => {
        if (courseInfo2.contentData.rank === 14) {
          navigate(
            `${
              userId ? `/net-worth-user?id=${userId}` : "/net-worth"
            }&retake=${retake}`
          );
        }
        if (courseInfo2.contentData.rank === 22) {
          navigate(
            `${
              userId ? `/spending-plan-user?id=${userId}` : "/spending-plan"
            }&retake=${retake}`
          );
        }
        if (courseInfo2.contentData.rank === 49) {
          navigate(
            `${
              userId
                ? `/review-questions-user?id=${userId}&retake=${retake}`
                : `/review-questions?retake=${retake}`
            }`
          );
        }
        fetchCourseInfo2();
        if (rank === 51) {
          if (userId) {
            navigate(`/editstudent?id=${userId}&retake=${retake}`);
          } else {
            navigate(`/creditcounseling?course=second&retake=${retake}`);
          }
        }
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };

  return (
    <div>
      <Header />
      {getloading ? (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "200px" }}
        >
          <ThreeDots
            height="40"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{
              display: "flex",
              justifyContent: "center",
            }}
            wrapperClassName="spinner"
            visible={true}
          />
        </div>
      ) : courseInfo2?.contentData ? (
        <div className="theme_container mt-250 course_container">
          <div className="course-list-video">
            <div className="d-flex flex-column-reverse flex-lg-row row">
              <div className="col-lg-5">
                <h4 className="f-26 d-none d-lg-block">
                  Post-Filing Debtor Education
                </h4>
                <ul className="video-list">
                  {Array.from({ length: 49 }).map((_, index) => (
                    <li
                      key={index}
                      style={{
                        padding: "10px 0px",
                        borderLeft:
                          index + 1 === courseInfo2.contentData.rank
                            ? "5px solid #4E9C4B"
                            : "",
                        background:
                          index + 1 === courseInfo2.contentData.rank
                            ? "#E0E0E066"
                            : "",
                        paddingLeft:
                          index + 1 === courseInfo2.contentData.rank
                            ? "5px"
                            : "",
                      }}
                    >
                      {index + 1 < courseInfo2.contentData.rank ? ( // Check if the video has been watched
                        <span className="checkbox">
                          <i className="fa-solid fa-circle-check"></i>
                        </span>
                      ) : (
                        <span className="play">
                          <i className="fa-regular fa-circle-play"></i>
                        </span>
                      )}
                      <div>
                        <p>
                          <span>Lesson {index + 1}:</span> of 49
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              {updateLoading ? (
                <div className="col-lg-7 mb-5 mb-lg-0">
                  <ThreeDots
                    height="40"
                    width="80"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    wrapperClassName="spinner"
                    visible={true}
                  />
                </div>
              ) : (
                <div className="col-lg-7 video-container mb-5 mb-lg-0">
                  <h4 class="f-26 d-lg-none">Post-Filing Debtor Education </h4>
                  {courseInfo2.contentData?.image ? (
                    //   <video
                    //   width="100%"
                    //   height="450"
                    //   src={
                    //     courseInfo.contentData.image
                    //       ? courseInfo.contentData?.image
                    //       : "https://youtu.be/9xwazD5SyVg"
                    //   }
                    //   autoPlay
                    // ></video>
                    <audio
                      width="100%"
                      src={courseInfo2.contentData.image}
                      autoPlay
                      controls
                      style={{ width: "100%" }}
                      id="customAudio"
                      controlsList="nodownload noplaybackrate "
                      onEnded={() => {
                        handleComplete(courseInfo2.contentData.rank);
                      }}
                    ></audio>
                  ) : (
                    <iframe
                      width="100%"
                      height="450"
                      src={"https://www.youtube.com/embed/9xwazD5SyVg"}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  )}

                  <td
                    dangerouslySetInnerHTML={{
                      __html: courseInfo2.contentData.text,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <h4 className="f-26 text-center" style={{ marginTop: "200px" }}>
          Post-Filing Debtor Education Completed
        </h4>
      )}
      <Footer />
    </div>
  );
};

export default Course2;
