import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { getCourseProgress, updateCourseProgress } from "../../api/server";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";

const CoursePrograme = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");
  const course = queryParams.get("course");
  const retake = Boolean(
    queryParams.get("retake") !== null &&
      queryParams.get("retake") != "null" &&
      queryParams.get("retake") != "false" &&
      queryParams.get("retake") !== false
  );
  const [response, setResponse] = useState();
  const [getloading, setGetLoading] = useState(true);
  const [postLoading, setPostLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await getCourseProgress(userId, {
        course: course,
        retake: retake ? 1 : 0,
      })
        .then((res) => {
          setResponse(res.data.data);
          setGetLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data.error);
          setGetLoading(false);
        });
    };
    fetchData();
  }, []);

  const handleUpdate = () => {
    setPostLoading(true);
    updateCourseProgress(userId, {
      course: course,
      retake: retake ? 1 : 0,
      data: [response[0], response[1]],
    })
      .then((res) => {
        toast.success("Course updated successfully");
        setPostLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setPostLoading(false);
      });
  };

  return (
    <div>
      <div className="wrapper">
        <DashBoardSidebar />
        {/* <!-- Page Content Holder --> */}
        <div id="content">
          <DashboardNavbar />
          <section className="manage-students course-progress">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-lg-12 d-flex justify-content-between">
                    <h4 className="card-title">Course Progress</h4>
                    {response && (
                      <div>
                        <button
                          className="btn_brnd"
                          onClick={() => {
                            let obj = {};
                            Array.from({
                              length: course === "first" ? 19 : 51,
                            }).map(
                              (_, i) =>
                                (obj = { ...obj, [`aud${i + 1}`]: "checked" })
                            );
                            setResponse([
                              {
                                ...response[0],
                                ...obj,
                                completed: "checked",
                              },
                              response[1],
                            ]);
                          }}
                        >
                          Click All
                        </button>
                        {Object.keys(response[1]).length > 0 && (
                          <button
                            className="btn_brnd ms-4"
                            onClick={() => {
                              let obj = {};
                              Array.from({
                                length: course === "first" ? 19 : 51,
                              }).map(
                                (_, i) =>
                                  (obj = { ...obj, [`aud${i + 1}`]: "checked" })
                              );
                              setResponse([
                                response[0],
                                {
                                  ...response[1],
                                  ...obj,
                                  completed: "checked",
                                },
                              ]);
                            }}
                          >
                            Click All (Joint)
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="card-body">
                <table className="table  table-responsive">
                  <thead>
                    {response && (
                      <tr>
                        <th scope="col">Lesson</th>
                        <th scope="col">Main Filer</th>
                        {Object.keys(response[1]).length > 0 && (
                          <th scope="col">Joint Filer</th>
                        )}
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {getloading ? (
                      <tr>
                        <td colSpan={3}>
                          <ThreeDots
                            height="40"
                            width="80"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            wrapperClassName="spinner"
                            visible={true}
                          />
                        </td>
                      </tr>
                    ) : (
                      response &&
                      Array.from({
                        length: course === "first" ? 19 : 51,
                      }).map((item, index) => (
                        <tr key={index}>
                          <td>
                            {index < 9 ? "0" + (index + 1) : "" + (index + 1)}
                          </td>
                          <td>
                            <div className="iagree_radio">
                              <input
                                type="checkbox"
                                checked={
                                  response[0][`aud${index + 1}`] ===
                                    "checked" ||
                                  response[0][`aud${index + 1}`] ===
                                    "Checked" ||
                                  response[0][`aud${index + 1}`] ===
                                    "complete" ||
                                  response[0][`aud${index + 1}`] === "Complete"
                                }
                                name="tickbox"
                                id={`check${index + 1}`}
                                onChange={(e) => {
                                  setResponse([
                                    {
                                      ...response[0],
                                      [`aud${index + 1}`]: e.target.checked
                                        ? "Checked"
                                        : "",
                                    },
                                    response[1],
                                  ]);
                                }}
                                className=" "
                                key={index}
                              />
                              <label htmlFor={`check${index + 1}`}> </label>
                            </div>
                          </td>
                          {Object.keys(response[1]).length > 0 && (
                            <td>
                              <div className="iagree_radio">
                                <input
                                  type="checkbox"
                                  checked={
                                    response[1][`aud${index + 1}`] ===
                                      "checked" ||
                                    response[1][`aud${index + 1}`] ===
                                      "Checked" ||
                                    response[1][`aud${index + 1}`] ===
                                      "complete" ||
                                    response[1][`aud${index + 1}`] ===
                                      "Complete"
                                  }
                                  name="tickbox"
                                  id={`jointcheck${index + 1}`}
                                  onChange={(e) => {
                                    setResponse([
                                      response[0],
                                      {
                                        ...response[1],
                                        [`aud${index + 1}`]: e.target.checked
                                          ? "Checked"
                                          : "",
                                      },
                                    ]);
                                  }}
                                  className=" "
                                  key={index}
                                />
                                <label htmlFor={`jointcheck${index + 1}`}>
                                  {" "}
                                </label>
                              </div>
                            </td>
                          )}
                        </tr>
                      ))
                    )}
                    {response && (
                      <>
                        <tr>
                          <td>Completed (Show in Certificate List)</td>
                          <td>
                            <div className="iagree_radio">
                              <input
                                type="checkbox"
                                checked={
                                  response[0].completed === "checked" ||
                                  response[0].completed === "Checked" ||
                                  response[0].completed === "complete" ||
                                  response[0].completed === "Completed"
                                }
                                name="tickbox"
                                id={`completed`}
                                onChange={(e) => {
                                  setResponse([
                                    {
                                      ...response[0],
                                      [`completed`]: e.target.checked
                                        ? "checked"
                                        : "",
                                    },
                                    response[1],
                                  ]);
                                }}
                                className=" "
                              />
                              <label htmlFor={`completed`}> </label>
                            </div>
                          </td>
                          {Object.keys(response[1]).length > 0 && (
                            <td>
                              <div className="iagree_radio">
                                <input
                                  type="checkbox"
                                  checked={
                                    response[1].completed === "checked" ||
                                    response[1].completed === "Checked" ||
                                    response[1].completed === "complete" ||
                                    response[1].completed === "Completed"
                                  }
                                  name="tickbox"
                                  id={`jointcompleted`}
                                  onChange={(e) => {
                                    setResponse([
                                      response[0],
                                      {
                                        ...response[1],
                                        [`completed`]: e.target.checked
                                          ? "checked"
                                          : "",
                                      },
                                    ]);
                                  }}
                                  className=" "
                                />
                                <label htmlFor={`jointcompleted`}> </label>
                              </div>
                            </td>
                          )}
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                {!getloading && response && (
                  <div className="text-center">
                    <Link to={`/editstudent?id=${userId}`}>
                      <button className="btn_brnd-outline my-2 me-3">
                        Back
                      </button>
                    </Link>
                    <button
                      className="btn_brnd my-3"
                      onClick={handleUpdate}
                      disabled={postLoading}
                    >
                      {postLoading ? (
                        <ThreeDots
                          height="20"
                          width="60"
                          radius="9"
                          color="#4fa94d"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{
                            display: "block",
                          }}
                          wrapperClassName="spinner"
                          visible={true}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CoursePrograme;
