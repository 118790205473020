import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import tick_white from "../../assets/images/tick_white.png";
import { quizSecond } from "../../api/server";
import Header from "../../layouts/header";
import { toast } from "react-toastify";

const questionData = [
  [
    {
      title:
        "According to this course, which of the following is not a financial personality type?",
      options: [
        "The Ostrich",
        "The Procrastinator",
        "Compulsive Spender",
        "The Peacock",
        "These are all financial personality types",
      ],
      explaination:
        "The four financial personality types discussed in this course are the Ostrich, the Procrastinator, the Peacock, and the Compulsive Spender. However, these are just a few examples of financial personality types. There are many other financial personality types. Your financial personality type reflects your attitudes and feelings about money, and understanding your financial personality type can provide insight into why you react to month the way you do and how you can better approach your financial weaknesses.",
    },
    {
      title:
        "Which of the following is an example of a short term financial goal (one that can be accomplished within a year)?",
      options: [
        "Completely pay off your home mortgage",
        "Start a rainy day fund (for emergencies)and set aside money each month",
        "Pay cash for a new car",
        "Live 100% debt free",
        "Retire from work at age 65 with the ability to live off investments",
      ],
      explaination:
        " Starting a rainy day fund is a realistic short term goal. The other examples listed are great goals, but would take much longer than a year to realize and are medium and long term goals.",
    },
  ],
  [
    {
      title:
        "Having financial goals is a critical part of financial security. Financial goals should be:",
      options: [
        "Short, medium, and long-term in nature",
        "Specific, measurable, and realistic",
        "Written down and reviewed periodically",
        "All of the above",
      ],
      explaination:
        " In this course we discussed how good financial goals are specific, measurable, and realistic and how financial goals can be short-term, midterm, and even long-term in nature. We also discussed how it is important to write down financial goals and to review them periodically.",
    },
    {
      title:
        "It's easy to accomplish long-term financial goals even if you don't have a monthly spending plan:",
      options: ["True", "False"],
      explaination:
        "All financial goals take planning ahead of time, especially long term goals. For example, by finding a way to save $30 dollars a month, in a few years time you could travel somewhere you've always wanted to visit. This could be difficult otherwise",
    },
  ],
  [
    {
      title: "A simple Net Worth equation is:",
      options: [
        "Assets Total + Liabilities Total = Net Worth",
        "Liabilities Total - Assets Total = Net Worth",
        "Assets Total - Liabilities Total = Net Worth",
      ],
      explaination:
        "Assessing your financial net worth simply means knowing what you own (your assets) and what you owe (your liabilities). To find out your net worth, add up all of your assets (Assets Total), add up all of your liabilities (Liabilities Total), and then subtract your Liabilities Total from your Assets Total.",
    },
    {
      title: "Doing which of the following will increase my net worth:",
      options: [
        "Taking out a second mortage to buy a boat",
        "Living within a budget",
        "Paying off debt AND investing or saving cash",
        "Paying rent instead of a mortgage",
      ],
      explaination:
        "Just living within a budget and avoiding debt alone will not build your net worth. In addition to paying off debt, you need to invest money where it will grow in value over time to increase your net worth.",
    },
  ],
  [
    {
      title:
        "According to the Psychology of Spending, it doesn't matter if your spending matches your values.",
      options: ["True", "False"],
      explaination:
        "Problems arise when your spending doesn't match your true values. If your spending doesn't reflect what is most important to you, you tend to feel unsettled and insecure. You probably have frequent arguments with your partner about finances and most likely do not have adequate financial safety nets in place. It is wise to determine what is most important to you and put your money where your mouth is, so to speak.",
    },
    {
      title:
        "Which of the following options would be a good vehicle for someone who values reliability and low cost to operate?",
      options: [
        "A brand new Chevy Suburban (8 passenger SUV) with an extended warranty.",
        "A newer Honda Civic (small sub-compact car)with low miles.",
        "An old collectable sports car",
        "An older Mercedes (luxury car) given to you by your grandmother.",
        "It doesn't really matter these days - most cars are reliable and cheap to operate.",
      ],
      explaination:
        "The correct answer is a newer Honda Civic. Someone who values reliability will talk to friends and read reports on cars that last a long time. Also, cars with lower costs to operate tend to be smaller economy model which are good on gas and other consumbles and cheaper to repair.",
    },
  ],
  [
    {
      title:
        "If my monthly expenses are greater than my monthly income, I should:",
      options: [
        "Cover the extra cost with a low-interest rate credit card",
        "Not panic. This is what my emergency fund is for.",
        "Figure out where I can make reasonable cuts in my expenses and how I can realistically increase my income",
        "All of the above",
      ],
      explaination:
        " If your monthly expenses are greater than your monthly income, it isn't wise to take money from your emergency fund or borrow money from a creditor. The smart, sustainable solution is to find ways to decrease your monthly expenses and ways to increase your monthly income.",
    },
    {
      title:
        " Which of the following is not a category of expense to include in your spending plan:",
      options: [
        "Monthly fixed expenses",
        "Discretionary expenses",
        "Miscellaneous expenses",
        "Monthly variable expenses",
        "Periodic expenses",
      ],
      explaination:
        "All of the categories listed were discussed in the course except miscellaneous expenses. All expenses, even if they are miscellaneous should fit into one of the five categories in the course.",
    },
  ],
  [
    {
      title:
        "In this course we talked about the importance of creating financial safety nets. Which of the following is a reliable safety net?",
      options: [
        "A variable-rate credit card",
        "An emergency fund of at least $1000",
        "A low-interest rate credit card",
        "All of the above",
      ],
      explaination:
        "The three financial safety nets discussed in this course are savings accounts, insurance, and consumer protection laws. Credit cards, no matter how low the interest, are not needed if you adequately prepare for the unexpected with proper financial safety nets.",
    },
    {
      title:
        " When a potential creditor pulls your credit report it is called an inquiry. According to the course, there are how many different types of inquiries",
      options: [
        "Only two: soft and hard inquiries",
        "Only three: soft, medium, and hard inquiries",
        "Only four: soft, medium, hard and nondescript inquiries",
      ],
      explaination:
        "There are only two types of inquiries: soft and hard. Soft inquiries have no effect on your score whereas hard inquiries can have a negative impact on your credit if they happen often. The idea behind hard inquiries is that if so many lenders want to know if your credit is good, it may be an indication of an unreliability.",
    },
  ],
  [
    {
      title: "All debt is bad.",
      options: ["True", "False"],
      explaination:
        "Debt can be a powerful financial planning tool if used correctly. Two examples of good debt are low-interest rate home mortgages and low-interest rate student loans with affordable monthly payments.",
    },
    {
      title: "If I am looking for a list of local charities I can contact:",
      options: [
        "The office of the attorney general for my state",
        "My local United Way office",
        "Both A and B",
        "None of the above",
      ],
      explaination:
        "If you are interested in contacting local public and private agencies for help during a financial crisis, your Attorney General's office and your local United Way should be able to give you a list of recommended charities.",
    },
  ],
  [
    {
      title:
        "While it is important to write down my financial goals, I shouldn't write down my spending plan because it is subject to change.",
      options: ["True", "False"],
      explaination:
        "Even though your spending plan should be flexible and subject to change, it is still important to write it down. Keeping a written record of your spending plan in addition to a written record of your financial goals can help you stay on course and help you hold yourself accountable.",
    },
    {
      title:
        "If I am faced with an unexpected financial crisis, Evergreen recommends that I access additional funds by:",
      options: [
        "Taking on a part-time job",
        "Taking money from an emergency savings account",
        "Borrowing money interest-free from a family member",
        "All of the above",
        "A & B.",
      ],
      explaination:
        "When faced with a financial crisis, Evergreen recommends that individuals avoid borrowing money at all costs. Even if the loan is through a family member and not a bank, it is still not recommended. Instead, we recommend individuals turn to their savings accounts, trim their spending, look for ways to earn additional money, investigate ways to have their unexpected bills reduced, and ask private and public agencies for assistance.",
    },
  ],
  [
    {
      title:
        "In Personal Story V, Ben Lopez and his spouse do not spend more than $5 without talking about it first:",
      options: ["True", "False"],
      explaination:
        "Ben Lopez and his wife have agreed to talk about any purchase above $5. While this amount may vary from couple to couple, it's wise to discuss financial decisions openly and honestly as a family.",
    },
    {
      title: "The number one cause of divorce today is the following:",
      options: [
        "Financial Stress",
        "Parenting Issues",
        "Infidelity",
        "Emotional or Physical Abuse",
      ],
      explaination:
        "The number one cause of divorce today is financial stress. It is very important for couples and family members to have an open dialogue about finances in the home.",
    },
  ],
  [
    {
      title:
        "Help! I'm the victim of identity theft. How can I establish a 7 year fraud alert on my credit report?",
      options: [
        "I need a police report and evidence of attempts to open fraudulent accounts in my name.",
        "I don't need to do anything, under the Fair Credit Reporting Act all three credit bureaus hold all responsibility.",
      ],
      explaination:
        "Under the new provisions of the Fair Credit Reporting Act, you can place an initial fraud alert for only 90 days. If you wish to have a 7 year fraud alert, you need to file a police report and have evidence of attempts to open fraudulent accounts. You may cancel this fraud alert at any time.",
    },
    {
      title:
        "At the end of the course a bankruptcy attorney gave some financial advice. Part of this advice was to never pay full price for vacation packages and to instead consider saving money by buying a time-share.",
      options: ["True", "False"],
      explaination:
        "According to a bankruptcy attorney of over 30 years, it is financially unwise to purchase any type of time-share travel.",
    },
  ],
];

const CourseReviewQuestion = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");
  const retake = Boolean(
    queryParams.get("retake") !== null &&
      queryParams.get("retake") != "null" &&
      queryParams.get("retake") != "false" &&
      queryParams.get("retake") !== false
  );
  const user = useSelector((state) => state.user);
  const [currentStep, setCurrentStep] = useState(0);
  const [stats, setStats] = useState();
  const isJoint = localStorage.getItem("isJoint");
  const [data, setData] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
    q6: "",
    q7: "",
    q8: "",
    q9: "",
    q10: "",
    q11: "",
    q12: "",
    q13: "",
    q14: "",
    q15: "",
    q16: "",
    q17: "",
    q18: "",
    q19: "",
    q20: "",
  });

  const handleNextStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    if (currentStep !== 11) {
      setCurrentStep((prev) => prev + 1);
    } else {
    }
  };

  const handleprevStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };

  const Step = ({ next, prev, data, item, index }) => {
    const firstQuestion = index * 2 + 1;
    const secondQuestion = index * 2 + 2;
    const [postLoading, setPostLoading] = useState(false);
    const validationSchema = Yup.object().shape({
      [`q${firstQuestion}`]: Yup.string().required("Field is required"),
      [`q${secondQuestion}`]: Yup.string().required("Field is required"),
    });
    const handleSubmit = async (values) => {
      setPostLoading(true);
      await quizSecond(
        values,
        userId ? "admin" : "user",
        userId ? userId : user.user.data.id,
        isJoint == "true" ? "true" : "false",
        retake ? 1 : 0
      )
        .then((res) => {
          setPostLoading(false);
          setStats(res.data);
          next(values);
        })
        .catch((err) => {
          setPostLoading(false);
        });
    };
    return (
      <Formik
        initialValues={data}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (index === 9) {
            handleSubmit(values);
          } else {
            next(values);
          }
        }}
      >
        {({ values, setValues, errors }) => (
          <Form>
            <div className="mt-4">
              <div className="radio_wrap_question">
                <div className="iagree_radio mb-20">
                  <p className="fw-600">{item[0].title}</p>
                  {item[0].options.map((item, ind) => (
                    <div className="mt-3" key={ind}>
                      <input
                        type="radio"
                        name={[`q${firstQuestion}`]}
                        id={"q1" + ind}
                        checked={values[`q${firstQuestion}`] === ind}
                        onClick={() => {
                          setValues({
                            ...values,
                            [`q${firstQuestion}`]: ind,
                          });
                        }}
                        className=" "
                      />
                      <label htmlFor={"q1" + ind}>{item}</label>
                    </div>
                  ))}
                  <ErrorMessage
                    name={[`q${firstQuestion}`]}
                    component="div"
                    className="error text-danger"
                  />
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="radio_wrap_question">
                <div className="iagree_radio mb-20">
                  <p className="fw-600">{item[1].title}</p>
                  {item[1].options.map((item, ind) => (
                    <div className="mt-3" key={ind}>
                      <input
                        type="radio"
                        name={[`q${secondQuestion}`]}
                        id={"q2" + ind}
                        checked={values[`q${secondQuestion}`] === ind}
                        onClick={() => {
                          setValues({
                            ...values,
                            [`q${secondQuestion}`]: ind,
                          });
                        }}
                        className=" "
                      />
                      <label htmlFor={"q2" + ind}>{item}</label>
                    </div>
                  ))}
                  <ErrorMessage
                    name={[`q${secondQuestion}`]}
                    component="div"
                    className="error text-danger"
                  />
                </div>
              </div>
            </div>
            <div className="btn_wrap mt-40 text-center">
              {index > 0 && (
                <button
                  className="btn_brnd-outline mr-22"
                  disabled={postLoading}
                  type="button"
                  onClick={() => {
                    prev(data);
                  }}
                >
                  Go Back
                </button>
              )}
              <button
                type="submit"
                disabled={postLoading}
                className="btn_brnd"
                onClick={() => {
                  if (Object.keys(errors).length > 0) {
                    toast.error("Form validation failed");
                  }
                }}
              >
                {postLoading ? (
                  <ThreeDots
                    height="20"
                    width="75"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: "block",
                    }}
                    wrapperClassName="spinner"
                    visible={true}
                  />
                ) : (
                  "Continue"
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };
  const Result = ({ prev, data }) => {
    return (
      <>
        {questionData.map((item, index) => {
          const firstQuestion = index * 2 + 1;
          const secondQuestion = index * 2 + 2;
          return (
            <>
              <div className="mt-4">
                <div className="radio_wrap_question">
                  <div className="iagree_radio mb-20">
                    <p className="fw-600">{item[0].title}</p>
                    {item[0].options.map((item, ind) => (
                      <div className="mt-3" key={ind}>
                        <input
                          type="radio"
                          name={[`q${firstQuestion}`]}
                          id={"q1" + ind}
                          checked={data[`q${firstQuestion}`] === ind}
                          disabled
                          className=" "
                        />
                        <label htmlFor={"q1" + ind}>{item}</label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="head ps-4 mb-5" style={{ height: "auto" }}>
                  <span
                    className="mb-0"
                    style={{
                      fontSize: "15px",
                      fontWeight: "700",
                      color:
                        data[`q${firstQuestion}`] ===
                        stats.ansObject[`q${firstQuestion}C`]
                          ? "#4E9C4B"
                          : "#ED1717",
                    }}
                  >
                    {data[`q${firstQuestion}`] ===
                    stats.ansObject[`q${firstQuestion}C`]
                      ? "Correct"
                      : "Wrong"}
                  </span>
                  {data[`q${firstQuestion}`] !==
                    stats.ansObject[`q${firstQuestion}C`] && (
                    <>
                      <p>
                        <span style={{ color: "#4E9C4B" }}>
                          Correct Answer:{" "}
                        </span>
                        {item[0].options[stats.ansObject[`q${firstQuestion}C`]]}
                      </p>
                      <p>
                        <b>Explanation: </b>
                        {item[0].explaination}
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="mt-4">
                <div className="radio_wrap_question">
                  <div className="iagree_radio mb-20">
                    <p className="fw-600">{item[1].title}</p>
                    {item[1].options.map((item, ind) => (
                      <div className="mt-3" key={ind}>
                        <input
                          type="radio"
                          name={[`q${secondQuestion}`]}
                          id={"q2" + ind}
                          checked={data[`q${secondQuestion}`] === ind}
                          disabled
                          className=" "
                        />
                        <label htmlFor={"q2" + ind}>{item}</label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="head ps-4 mb-5" style={{ height: "auto" }}>
                  <span
                    className="mb-0"
                    style={{
                      fontSize: "15px",
                      fontWeight: "700",
                      color:
                        data[`q${secondQuestion}`] ===
                        stats.ansObject[`q${secondQuestion}C`]
                          ? "#4E9C4B"
                          : "#ED1717",
                    }}
                  >
                    {data[`q${secondQuestion}`] ===
                    stats.ansObject[`q${secondQuestion}C`]
                      ? "Correct"
                      : "Wrong"}
                  </span>
                  {data[`q${secondQuestion}`] !==
                    stats.ansObject[`q${secondQuestion}C`] && (
                    <>
                      <p>
                        <span style={{ color: "#4E9C4B" }}>
                          Correct Answer:{" "}
                        </span>
                        {
                          item[1].options[
                            stats.ansObject[`q${secondQuestion}C`]
                          ]
                        }
                      </p>
                      <p>
                        <b>Explanation: </b> {item[1].explaination}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </>
          );
        })}
        <div className="btn_wrap mt-40 text-center">
          <button
            onClick={() => {
              if (userId) {
                navigate(`/editstudent?id=${userId}&retake=${retake}`);
              } else {
                navigate(`/creditcounseling?course=second&retake=${retake}`);
              }
            }}
            className="btn_brnd"
          >
            Continue
          </button>
        </div>
      </>
    );
  };

  const steps = questionData.map((item, index) => (
    <Step
      next={handleNextStep}
      data={data}
      index={index}
      prev={handleprevStep}
      item={item}
    />
  ));
  return (
    <div className="step_form w-75 mx-auto">
      <Header />
      <div className="pt-4 mt-60">
        <h4 className="f-26 mb-5">Pre-Filing Credit Counseling </h4>
        <div className="step_progress_bar">
          <ul>
            {["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"].map(
              (item, index) => (
                <li
                  className={`${currentStep >= index ? "active-step" : ""}`}
                  key={index}
                >
                  {item}
                </li>
              )
            )}
          </ul>
        </div>
        {currentStep !== 10 && (
          <div className="head d-flex align-content-center ps-4 mt-5">
            <span
              className="mb-0"
              style={{
                fontSize: "15px",
                fontWeight: "700",
              }}
            >
              Course Review Questions
            </span>
          </div>
        )}
        {currentStep === 10 ? (
          <div className="netWorth mt-5">
            <div
              className="card-body d-flex align-content-center"
              style={{ background: "#E0E0E04D", borderRadius: "15px" }}
            >
              <div className="row align-content-center">
                <div className="col-lg-9">
                  <h6 style={{ fontWeight: "700" }}>Congratulations!</h6>
                  <p className="mb-0">
                    You have completed the review questions. You answered 6 of
                    the questions correctly.
                  </p>
                </div>
                <div
                  className="col-lg-3 text-lg-end"
                  style={{ minWidth: "150px" }}
                >
                  <button className="grade-btn btn_brnd ">
                    <span>{stats.percentage}%</span>
                    <p>Your Score</p>
                  </button>
                </div>
              </div>
            </div>
            <Result data={data} prev={handleprevStep} />
          </div>
        ) : (
          steps[currentStep]
        )}
      </div>
    </div>
  );
};

export default CourseReviewQuestion;
