import React from "react";
import { Link } from "react-router-dom";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";

const AttorneySignupSuccess = () => {
  return (
    <>
      <Header />
      <div className="d-flex justify-content-center align-items-center mt-60 pt-40">
        <div className="text-center mt-60 pt-40" style={{ color: "#000000" }}>
          <h4 className="alert-heading" style={{ fontWeight: "600" }}>
            Account setup successfully!
          </h4>
          <p>Login now to access your account.</p>
          <Link to={`/login`} className="">
            <h5
              className="btn_brnd  mt-4 me-3  login_btn"
              style={{ fontSize: "18px" }}
            >
              Login Now
            </h5>
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AttorneySignupSuccess;
