import React, { useEffect, useLayoutuseLocationEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Footer from "../../layouts/footer";
import cross from "../../assets/images/SiteImg/cross.png";

import updateInstance, {
  VerifyUser,
  getCounty,
  getDistrict,
  getStates,
  userSignup,
} from "../../api/server";
import Header from "../../layouts/header";
import { useDispatch, useSelector } from "react-redux";
import { signup } from "../../store/userSlice";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

const RegistrationPage = () => {
  const [showJointFiler, setShowJointFiler] = useState(false);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    middleInitial: Yup.string(),
    lastName: Yup.string().required("Last Name is required"),
    ssn: Yup.string().required("Last Four Digits of SSN is required").matches(/^\d{4}$/, "SSN must be exactly 4 digits"),
    phone: Yup.string().required("Phone Number is required"),
    address1: Yup.string().required("Address Line 1 is required"),
    address2: Yup.string(),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zip: Yup.number().required("Zip Code is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Your Email is required"),
    confirmEmail: Yup.string()
      .oneOf([Yup.ref("email"), null], "Emails must match")
      .required("Confirm Email is required"),
    username: Yup.string()
      .matches(/^(\S+)$/, "Username should not contain spaces")
      .required("Username is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /[A-Z]/,
        "Password must contain at least one uppercase character"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    attyemail: Yup.string().email("Invalid email address"),
    jointFiler_firstName: showJointFiler
      ? Yup.string().required("First name is required")
      : Yup.string(),
    jointFiler_lastName: showJointFiler
      ? Yup.string().required("Last name is required")
      : Yup.string(),
    jointFiler_ssn: showJointFiler
      ? Yup.string().required("SSN is required").matches(/^\d{4}$/, "SSN must be exactly 4 digits")
      : Yup.string(),
    jointPhoneNumber: showJointFiler
      ? Yup.string().required("Phone Number is required")
      : Yup.string(),
    // couponCode: Yup.string(),
    // attyemail: Yup.string().required("Attorney's Email is required"),
    // usercoupon: Yup.string().required("User's Coupon is required"),
    // attorneyname: Yup.string().required("Attorney's Name is required"),
    // attyphone: Yup.string(),
    couponcode_first: Yup.string(),
    judicialdistrict_state: Yup.string().required("State is required"),
    judicialdistrict_county: Yup.string().required("County is required"),
    judicialdistrict: Yup.string().required("Judicial District is required"),

    jointFiler_username: showJointFiler
      ? Yup.string().required("Username is required")
      : Yup.string(),
    jointFiler_password: showJointFiler
      ? Yup.string()
          .required("Password is required")
          .min(8, "Password must be at least 8 characters")
          .matches(
            /[A-Z]/,
            "Password must contain at least one uppercase character"
          )
      : Yup.string()
          .min(8, "Password must be at least 8 characters")
          .matches(
            /[A-Z]/,
            "Password must contain at least one uppercase character"
          ),
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const AttorneyCode = queryParams.get("code");
  // const axiosInstance = updateInstance(localStorage.getItem("token"));
  const token = localStorage.getItem("token");
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [postLoading, setPostLoading] = useState(false);
  const [seeAll, setSeeAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statesData, setStatesData] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [countiesData, setCountiesData] = useState([]);
  const [districtsData, setDistrictsData] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [userAttorney, setUserAttorney] = useState(
    AttorneyCode ? AttorneyCode : ""
  );
  const [readOnly, setReadOnly] = useState(false);
  const [atternyName, setAtternyName] = useState("");
  const [atternyEmail, setAtternyEmail] = useState("");
  const [atternyNumber, setAtternyNumber] = useState("");
  const [attCodeValidation, setattCodeValidation] = useState(false);

  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [showAttymPass, setShowAttymPass] = useState(false);
  const [noCode, setNoCode] = useState(false);

  //////// function for handle add joint filer info///////
  const handleCheckboxChange = (e) => {
    setShowJointFiler(e.target.checked);
  };
  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< handle Verify Attorney code >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  const handleVerifyAttorney = async (e) => {
    setattCodeValidation(false);
    // e.preventDefault();
    const body = {
      code: userAttorney,
    };
    try {
      setLoading(true);
      const response = await VerifyUser(body);
      if (response.status === 200) {
        setReadOnly(true);
        const data = response.data;
        valuesSetter(response?.data?.data);
        setLoading(false);
      } else {
        setattCodeValidation(true);
      }
    } catch (error) {
      setattCodeValidation(true);
      console.error("Error fetching states data:", error);
      setLoading(false);
    }
  };
  //<<<<<<<<<<<<<<<<<<<<  Set Value If Coupon Exist  >>>>>>>>>>>>>>>>>>>>>>>>>>>>
  function valuesSetter(data) {
    setAtternyEmail(data.email);
    setAtternyName(data?.firstname);
    setAtternyNumber(data?.phone);
  }
  //////// function for handle submit the page///////
  const handleSubmit = async (values) => {
    if (userAttorney && (!atternyNumber || !atternyEmail || !atternyName)) {
      toast.error("Please verify attorney code.");
    } else {
      setPostLoading(true);
      let payload = { ...values };
      if (readOnly) {
        payload.attycode = userAttorney;
      }
      payload.attyphone = atternyNumber;
      payload.attyemail = atternyEmail;
      payload.attorneyname = atternyName;

      // let payload = { ...values };
      await userSignup(payload)
        .then((res) => {
          setPostLoading(false);
          const authToken = res.data.token;
          updateInstance(res.data.token);
          // axiosInstance.defaults.headers[
          //   "Authorization"
          // ] = `Bearer ${res.data.token}`;
          localStorage.setItem("authToken", authToken);
          dispatch(signup(res.data));
          navigate("/dashboard");
        })
        .catch((err) => {
          setPostLoading(false);
          if (err.response && err.response.data) {
            toast.error(err.response.data.error);
            setShowModal(false);
          } else {
            toast.error("An unexpected error occurred.");
            setShowModal(false);
          }
        });
    }
  };

  useEffect(() => {
    const fetchStatesData = async () => {
      try {
        const response = await getStates();
        const data = response.data;
        setStatesData(data);
      } catch (error) {
        console.error("Error fetching states data:", error);
      }
    };
    fetchStatesData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setCountiesData([]);
      try {
        if (selectedState) {
          /////////////////////// get data of county////////////////
          const response = await getCounty({ state: selectedState });
          const countiesData = response.data;
          setCountiesData(countiesData);
        }
      } catch (error) {}
    };

    fetchData();
  }, [selectedState]);

  useEffect(() => {
    const fetchData = async () => {
      setDistrictsData([]);
      try {
        if (selectedCounty) {
          /////////////////////// get data of District////////////////
          const districtResponse = await getDistrict({
            state: selectedState,
            county: selectedCounty,
          });
          const distData = districtResponse.data;

          setDistrictsData(distData);
        }
      } catch (error) {}
    };

    fetchData();
  }, [selectedCounty]);

  useEffect(() => {
    if (token) {
      if (user.user.data.type === "Admin") {
        navigate("/admin-dashboard");
      } else if (user.user.data.type === "Atty") {
        navigate("/manage-clients");
      } else {
        navigate("/dashboard");
      }
    } else {
      if (AttorneyCode) {
        handleVerifyAttorney();
      }
    }
  }, []);

  return (
    <div>
      <Header />
      <section className="breadcrumb-section mt-250">
        <div className="theme_container">
          <nav
            style={{ "--bs-breadcrumb-divider": " '>';" }}
            aria-label="breadcrumb"
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/"}>Home</Link>
              </li>
              <li className="breadcrumb-item">Disclosures</li>
              <li className="breadcrumb-item">Profile</li>
            </ol>
          </nav>
          <section className="Who_Evergreen mt-60">
            <div className="theme_container">
              <div className="Who_Evergreen_card">
                <h1 className="f-26">
                  Who is <span className="text_success">Evergreen?</span>
                </h1>
                <p className="f-16 mt-20">
                  We are a non-profit credit counseling and educational provider
                  approved by the US Trustees to offer pre-bankruptcy credit
                  counseling and post-bankruptcy personal financial management
                  in compliance with the Bankruptcy Code.*
                  <br />
                  <p style={{ color: "#4e9c4b", fontWeight: "600" }}>
                    For more information read more below
                  </p>
                </p>
              </div>
            </div>
          </section>
          <div className="head mt-60">
            <h1 className="f18">Main Applicant Info</h1>
          </div>
          <div className="row mt-40">
            <Formik
              // innerRef={formref}
              initialValues={{
                type: "User",
                firstName: "",
                middleInitial: "",
                lastName: "",
                ssn: "",
                phone: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                zip: "",
                email: "",
                confirmEmail: "",
                username: "",
                password: "",
                confirmPassword: "",
                attyemail: "",
                isJointFiler: false,
                preFilingCreditCounseling: false,
                postBankruptcyDebtorEducation: false,
                jointFiler_firstName: "",
                jointFiler_middleInitial: "",
                jointFiler_lastName: "",
                jointFiler_ssn: "",
                jointPhoneNumber: "",
                attyemail: "",
                attycode: "",
                attyphone: "",
                attorneyname: "",
                attypref: "checked",
                // couponCode: "",
                judicialdistrict_state: "",
                judicialdistrict_county: "",
                judicialdistrict: "",
                jointFiler_username: "",
                jointFiler_password: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setValues, errors }) => (
                <Form>
                  <div className="row mt-40">
                    <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">First Name</label>
                        <Field
                          type="text"
                          name="firstName"
                          className="form-control"
                          placeholder="Enter your first name"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Middle Initial</label>
                        <Field
                          type="text"
                          name="middleInitial"
                          className="form-control"
                          placeholder="Enter your middle name"
                        />
                        <ErrorMessage
                          name="middleInitial"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Last Name</label>
                        <Field
                          type="text"
                          name="lastName"
                          className="form-control"
                          placeholder="Enter your last name"
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">
                          Last Four Digits of SSN
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          name="ssn"
                          placeholder="Enter digit of SSN"
                        />
                        <ErrorMessage
                          name="ssn"
                          component="div"
                          className="error-message text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Phone Number</label>
                        <Field
                          type="text"
                          className="form-control"
                          name="phone"
                          placeholder="Enter your phone no."
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="error-message text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12" style={{ userSelect: "none" }}>
                    <div className="iagree_radio">
                      <input
                        type="checkbox"
                        name="isJointFiler"
                        id="joint"
                        onChange={handleCheckboxChange}
                        className=""
                      />

                      <label htmlFor="joint">Add a joint applicant</label>
                    </div>
                  </div>
                  {/* conditional rendering */}
                  {showJointFiler && (
                    <div>
                      <div className="head mt-40">
                        <h1 className="f18">Joint Applicant Info</h1>
                      </div>
                      <div className="row mt-40">
                        <div className="col-lg-4 col-md-6">
                          <div className="input_wrap">
                            <label className="label-title">First Name</label>
                            <Field
                              type="text"
                              className="form-control"
                              name="jointFiler_firstName"
                              placeholder="Enter your first name"
                            />
                            <ErrorMessage
                              name="jointFiler_firstName"
                              component="div"
                              className="error-message text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="input_wrap">
                            <label className="label-title">
                              Middle Initial
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              name="jointFiler_middleInitial"
                              placeholder="Enter your middle name"
                            />
                            <ErrorMessage
                              name="jointFiler_middleInitial"
                              component="div"
                              className="error-message text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="input_wrap">
                            <label className="label-title">Last Name</label>
                            <Field
                              type="text"
                              className="form-control"
                              name="jointFiler_lastName"
                              placeholder="Enter your last name"
                            />
                            <ErrorMessage
                              name="jointFiler_lastName"
                              component="div"
                              className="error-message text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="input_wrap">
                            <label className="label-title">
                              Last Four Digits of SSN
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              name="jointFiler_ssn"
                              placeholder="Enter digit of SSN"
                            />
                            <ErrorMessage
                              name="jointFiler_ssn"
                              component="div"
                              className="error-message text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="input_wrap">
                            <label className="label-title">Phone Number</label>
                            <Field
                              type="text"
                              className="form-control"
                              name="jointPhoneNumber"
                              placeholder="Enter your phone no."
                            />
                            <ErrorMessage
                              name="jointPhoneNumber"
                              component="div"
                              className="error-message text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="input_wrap">
                            <label className="label-title">
                              Joint Filer's Username
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              name="jointFiler_username"
                              placeholder="Enter your username"
                            />
                            <ErrorMessage
                              name="jointFiler_username"
                              component="div"
                              className="error-message text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="input_wrap">
                            <label className="label-title">
                              Joint Filer's Password
                            </label>
                            <Field
                              type={showAttymPass ? "text" : "password"}
                              className="form-control"
                              name="jointFiler_password"
                              placeholder="Enter your password"
                            />
                            {showAttymPass ? (
                              <i
                                className="fa fa-eye position-absolute cursor-pointer pt-lg-1"
                                style={{ top: "20px", right: "10px" }}
                                onClick={() => {
                                  setShowAttymPass(false);
                                }}
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i
                                className="fa fa-eye-slash position-absolute cursor-pointer pt-lg-1"
                                style={{ top: "20px", right: "10px" }}
                                onClick={() => {
                                  setShowAttymPass(true);
                                }}
                                aria-hidden="true"
                              ></i>
                            )}
                            <ErrorMessage
                              name="jointFiler_password"
                              component="div"
                              className="error-message text-danger"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="head mt-40">
                    <h1 className="f18">Your Contact Info</h1>
                  </div>
                  <div className="row mt-40">
                    <div className="col-lg-6 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Address Line 1</label>
                        <Field
                          type="text"
                          className="form-control"
                          name="address1"
                          placeholder="Enter your address"
                        />
                        <ErrorMessage
                          name="address1"
                          component="div"
                          className="error-message text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Address Line 2</label>
                        <Field
                          type="text"
                          className="form-control"
                          name="address2"
                          placeholder="Enter your address"
                        />
                        <ErrorMessage
                          name="address2"
                          component="div"
                          className="error-message text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">City</label>
                        <Field
                          type="text"
                          className="form-control"
                          name="city"
                          placeholder="Enter your city"
                        />
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="error-message text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">State</label>
                        <Field name="state">
                          {({ field }) => (
                            <select
                              {...field}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  state: e.target.value,
                                });
                              }}
                              className="form-control"
                            >
                              {statesData.length > 0 && (
                                <option value="">Select a State</option>
                              )}

                              {statesData.length > 0 ? (
                                statesData.map((state, index) => (
                                  <option key={index} value={state.state}>
                                    {state.state}
                                  </option>
                                ))
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          )}
                        </Field>
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Zip Code</label>
                        <Field
                          type="text"
                          className="form-control"
                          name="zip"
                          placeholder="Enter zip code"
                        />
                        <ErrorMessage
                          name="zip"
                          component="div"
                          className="error-message text-danger"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="head mt-40">
                    <h1 className="f18">Your Account Info</h1>
                  </div>
                  <div className="row mt-40">
                    <div className="col-lg-6 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Your Email</label>
                        <Field
                          type="text"
                          className="form-control"
                          name="email"
                          placeholder="Enter your email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error-message text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Confirm Email</label>
                        <Field
                          type="text"
                          className="form-control"
                          name="confirmEmail"
                          placeholder="Enter your email"
                        />
                        <ErrorMessage
                          name="confirmEmail"
                          component="div"
                          className="error-message text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Choose a Username</label>
                        <Field
                          type="text"
                          className="form-control"
                          name="username"
                          placeholder="Enter a username"
                        />
                        <ErrorMessage
                          name="username"
                          component="div"
                          className="error-message text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Choose a Password</label>
                        <Field
                          type={showPass ? "text" : "password"}
                          className="form-control"
                          name="password"
                          placeholder="Enter your Password"
                        />
                        {showPass ? (
                          <i
                            className="fa fa-eye position-absolute cursor-pointer pt-lg-1"
                            style={{ top: "20px", right: "10px" }}
                            onClick={() => {
                              setShowPass(false);
                            }}
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-eye-slash position-absolute cursor-pointer pt-lg-1"
                            style={{ top: "20px", right: "10px" }}
                            onClick={() => {
                              setShowPass(true);
                            }}
                            aria-hidden="true"
                          ></i>
                        )}
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error-message text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Confirm Password</label>
                        <Field
                          type={showConfirmPass ? "text" : "password"}
                          className="form-control"
                          name="confirmPassword"
                          placeholder="Enter your Password"
                        />
                        {showConfirmPass ? (
                          <i
                            className="fa fa-eye position-absolute cursor-pointer pt-lg-1"
                            style={{ top: "20px", right: "10px" }}
                            onClick={() => {
                              setShowConfirmPass(false);
                            }}
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-eye-slash position-absolute cursor-pointer pt-lg-1"
                            style={{ top: "20px", right: "10px" }}
                            onClick={() => {
                              setShowConfirmPass(true);
                            }}
                            aria-hidden="true"
                          ></i>
                        )}
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="error-message text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="head mt-40">
                    <h1 className="f18">
                      Your Attorney's Info{" "}
                      <span style={{ fontSize: "16px", fontWeight: "500" }}>
                        (If you don't have one, leave blank)
                      </span>
                    </h1>
                  </div>
                  <div className="row mt-40">
                    {!noCode && (
                      <div className="col-lg-4 col-md-6">
                        <div className="input_wrap">
                          <label className="label-title">
                            Enter Attorney Code
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            value={userAttorney}
                            onChange={(e) => {
                              setUserAttorney(e?.target?.value);
                              setattCodeValidation(false);
                            }}
                            name="attycode"
                            placeholder="Enter Attorney code"
                          />
                          {attCodeValidation && (
                            <p className="text-danger">
                              Invalid Attorney Code{" "}
                            </p>
                          )}
                          {/* <ErrorMessage
                          name="attycode"
                          component="div"
                          className="error-message text-danger"
                        /> */}
                        </div>
                      </div>
                    )}
                    <div className="col-lg-4 col-md-6">
                      {!noCode && (
                        <button
                          className="btn_brnd-outline mr-22"
                          // disabled={postLoading}
                          type="button"
                          onClick={handleVerifyAttorney}
                        >
                          {loading ? (
                            <ThreeDots
                              height="20"
                              width="52"
                              radius="9"
                              color="#ed1717"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{
                                display: "block",
                              }}
                              wrapperClassName="spinner"
                              visible={true}
                            />
                          ) : (
                            "Verify"
                          )}
                        </button>
                      )}
                      <button
                        className="btn_brnd my-3"
                        type="button"
                        onClick={() => {
                          setNoCode(!noCode);
                          setReadOnly(false);
                          setUserAttorney("");
                          setAtternyName("");
                          setAtternyEmail("");
                          setAtternyNumber("");
                        }}
                      >
                        {noCode ? "Add Code" : " No Code ?"}
                      </button>
                    </div>
                  </div>
                  <div className="row mt-40">
                    <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Attorney Email</label>
                        <Field
                          type="email"
                          className="form-control"
                          name="attyemail"
                          placeholder="Attorney Email"
                          readOnly={!noCode}
                          onChange={(e) => {
                            setAtternyEmail(e.target.value);
                          }}
                          value={atternyEmail}
                        />
                        <ErrorMessage
                          name="attyemail"
                          component="div"
                          className="error-message text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Attorney Name</label>
                        <Field
                          type="text"
                          className="form-control"
                          name="attorneyname"
                          placeholder="Attorney Name"
                          readOnly={!noCode}
                          onChange={(e) => {
                            setAtternyName(e.target.value);
                          }}
                          value={atternyName}
                        />
                        <ErrorMessage
                          name="attorneyname"
                          component="div"
                          className="error-message text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Attorney Phone</label>
                        <Field
                          type="text"
                          className="form-control"
                          name="attyphone"
                          placeholder="Attorney Phone"
                          readOnly={!noCode}
                          onChange={(e) => {
                            setAtternyNumber(e.target.value);
                          }}
                          value={atternyNumber}
                        />
                        <ErrorMessage
                          name="attyphone"
                          component="div"
                          className="error-message text-danger"
                        />
                      </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Coupon Code</label>
                        <Field
                          type="text"
                          className="form-control"
                          name="couponCode"
                          placeholder="Enter Coupon Code"
                        />
                        <ErrorMessage
                          name="couponCode"
                          component="div"
                          className="error-message text-danger"
                        />
                      </div>
                    </div> */}
                    <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Filing State</label>
                        <Field name="judicialdistrict_state">
                          {({ field }) => (
                            <select
                              {...field}
                              value={selectedState}
                              onChange={(e) => {
                                setSelectedState(e.target.value);
                                setSelectedCounty("");
                                setValues({
                                  ...values,
                                  judicialdistrict_state: e.target.value,
                                });
                              }}
                              className="form-control"
                            >
                              {statesData.length > 0 && (
                                <option value="">Select a State</option>
                              )}

                              {statesData.length > 0 ? (
                                statesData.map((state, index) => (
                                  <option key={index} value={state.state}>
                                    {state.state}
                                  </option>
                                ))
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          )}
                        </Field>
                        <ErrorMessage
                          name="judicialdistrict_state"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    {selectedState && (
                      <>
                        <div className="col-lg-4 col-md-6">
                          <div className="input_wrap">
                            <label className="label-title">Filing County</label>
                            <Field name="judicialdistrict_county">
                              {({ field }) => (
                                <select
                                  {...field}
                                  className="form-control"
                                  value={selectedCounty}
                                  onChange={(e) => {
                                    setSelectedCounty(e.target.value);
                                    setValues({
                                      ...values,
                                      judicialdistrict_county: e.target.value,
                                    });
                                  }}
                                >
                                  {countiesData.length > 0 && (
                                    <option value="">Select a county</option>
                                  )}
                                  {/* {values.judicialdistrict_county === "" && (
                                <option value="">Select a county</option>
                              )} */}
                                  {countiesData.length > 0 ? (
                                    countiesData.map((county, index) => (
                                      <option key={index} value={county.county}>
                                        {county.county}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="">Loading...</option>
                                  )}
                                </select>
                              )}
                            </Field>
                            <ErrorMessage
                              name="judicialdistrict_county"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>

                        {selectedCounty && (
                          <div className="col-lg-4 col-md-6">
                            <div className="input_wrap">
                              <label className="label-title">
                                Filing District
                              </label>
                              <Field name="judicialdistrict">
                                {({ field }) => (
                                  <select {...field} className="form-control">
                                    {districtsData.length > 0 && (
                                      <option value="">
                                        Select a Judicial District
                                      </option>
                                    )}
                                    {/* {values.judicialdistrict === "" && (
                                <option value="">
                                  Select a Judicial District
                                </option>
                              )} */}
                                    {districtsData.length > 0 ? (
                                      districtsData.map((district, index) => (
                                        <option
                                          key={index}
                                          value={district.subdistrict}
                                        >
                                          {district.subdistrict ==
                                          "District of Oregon - Portland"
                                            ? "I live closer to Portland"
                                            : district.subdistrict ==
                                              "District of Oregon - Eugene"
                                            ? "I live closer to Eugene"
                                            : district.subdistrict}
                                          {/* {district.subdistrict} */}
                                        </option>
                                      ))
                                    ) : (
                                      <option value="">Loading...</option>
                                    )}
                                  </select>
                                )}
                              </Field>
                              <ErrorMessage
                                name="judicialdistrict"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  {/* //////////conditional rendering end */}

                  {/* new Button Added for Create an Account */}
                  <section className="Who_Evergreen mt-60">
                    <div className="theme_container">
                      <div className="Who_Evergreen_card">
                        <h1 className="f-26">
                          Who is{" "}
                          <span className="text_success">Evergreen?</span>
                        </h1>
                        <p className="f-16 mt-20">
                          We are a non-profit credit counseling and educational
                          provider approved by the US Trustees to offer
                          pre-bankruptcy credit counseling and post-bankruptcy
                          personal financial management in compliance with the
                          Bankruptcy Code.* The educational and training
                          background of all our counselors either meets or
                          exceeds standards of the US Trustees. All of our
                          counselors are trained and experienced in the credit
                          counseling and personal finance arena. We are funded
                          exclusively on the nominal fee for service charged to
                          students and we do not pay or receive fees or other
                          considerations for referrals, nor do our courses
                          contain any commercial advertising. We do not promote,
                          market, or sell any financial products, or solicit
                          business of any type. We do not offer alternative
                          payment schedules regarding unsecured debt, such as
                          debt repayment plans. Participation in our courses
                          will not impact a student's credit report in any way.
                          No information obtained during our credit counseling
                          session, including the results of our budget analysis
                          are reported to any Credit Reporting Agency.
                          Bankruptcy, or bankruptcy alternatives, included but
                          not limited to debt negotiation, debt consolidation,
                          partial debt payments or non-payment of debt, can
                          negatively impact a student's credit rating for up to
                          seven to ten years. Further information on the
                          potential negative impact to credit rating should be
                          obtained from an attorney or outside source.
                        </p>
                        <p className="f-16  ">
                          We are a non-profit credit counseling and educational
                          provider approved by the US Trustees to offer
                          pre-bankruptcy credit counseling and post-bankruptcy
                          personal financial management in compliance with the
                          Bankruptcy Code.* The educational and training
                          background of all our counselors either meets or
                          exceeds standards of the US Trustees. All of our
                          counselors are trained and experienced in the credit
                          counseling and personal finance arena. We are funded
                          exclusively on the nominal fee for service charged to
                          students and we do not pay or receive fees or other
                          considerations for referrals, nor do our courses
                          contain any commercial advertising. We do not promote,
                          market, or sell any financial products, or solicit
                          business of any type. We do not offer alternative
                          payment schedules regarding unsecured debt, such as
                          debt repayment plans. Participation in our courses
                          will not impact a student's credit report in any way.
                          No information obtained during our credit counseling
                          session, including the results of our budget analysis
                          are reported to any Credit Reporting Agency.
                          Bankruptcy, or bankruptcy alternatives, included but
                          not limited to debt negotiation, debt consolidation,
                          partial debt payments or non-payment of debt, can
                          negatively impact a student's credit rating for up to
                          seven to ten years. Further information on the
                          potential negative impact to credit rating should be
                          obtained from an attorney or outside source.
                        </p>
                      </div>
                    </div>
                  </section>

                  <div className="btn_wrap text-center mt-40">
                    <Link
                      className="btn_brnd-outline mr-22 mb-2"
                      to="/registration"
                    >
                      Go Back
                    </Link>
                    <button
                      type="button"
                      className="btn_brnd"
                      data-bs-toggle="modal"
                      data-bs-target="#Serious"
                      disabled={postLoading}
                    >
                      {postLoading ? (
                        <ThreeDots
                          height="20"
                          width="125"
                          radius="9"
                          color="#4fa94d"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{
                            display: "block",
                          }}
                          wrapperClassName="spinner"
                          visible={true}
                        />
                      ) : (
                        "Create Account"
                      )}
                    </button>
                  </div>

                  {/*1st custom Modal */}
                  <div
                    className={`modal fade custom_modal `}
                    id="Serious"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex="-1"
                    aria-labelledby="SeriousLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content pb-60">
                        <button
                          type="button"
                          className="btn_close"
                          data-bs-dismiss="modal"
                        >
                          <img src={cross} alt="cross" className="cross_btn" />
                        </button>
                        <div className="modal-body">
                          <div className="modal_content">
                            <h1 className="f-26 text-center">Please Confirm</h1>
                            <p className="f-16 text-center mt-12 fw-700">
                              Your username is shown as{" "}
                              <span className="text_success">
                                {values.firstName} {values.lastName}
                              </span>
                              . <br />
                              If this is correct please continue. If this is
                              incorrect, please make the corrections before
                              continuing.
                            </p>

                            <div className="btn_wrap text-center mt-40">
                              <button
                                className="btn_brnd-outline mr-22 mb-2"
                                data-bs-dismiss="modal"
                                type="button"
                              >
                                Go Back
                              </button>
                              <button
                                data-bs-dismiss="modal"
                                className="btn_brnd"
                                type="submit"
                                disabled={postLoading}
                                onClick={() => {
                                  if (Object.keys(errors).length > 0) {
                                    toast.error("Form validation failed");
                                  }
                                }}
                              >
                                Confirm & Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default RegistrationPage;
