import React, { useState } from "react";
import arrow from "../../assets/images/SiteImg/Arrow.png";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { evaluateData } from "../../api/server";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

const validationSchema = Yup.object().shape({
  q1: Yup.string().required("This field is required"),
  q2: Yup.string().required("This field is required"),
  q3: Yup.string().required("This field is required"),
  q4: Yup.string().required("This field is required"),
  q5: Yup.string().required("This field is required"),
  q6: Yup.string().required("This field is required"),
  q7: Yup.string().required("This field is required"),
  q8: Yup.string().required("This field is required"),
  q9: Yup.string().required("This field is required"),
  course: Yup.string().required("This field is required"),
  feedback: Yup.string().required("Feedback is required"),
});

const CreditCounseling = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const courseValue = queryParams.get("course");
  const retake = Boolean(
    queryParams.get("retake") !== null &&
      queryParams.get("retake") != "null" &&
      queryParams.get("retake") != "false" &&
      queryParams.get("retake") !== false
  );
  const [feedbackRating, setFeedbackRating] = useState(0);
  const [loading, setLoading] = useState(false);
  const isJoint = localStorage.getItem("isJoint");

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const feedbackData = {
        ...values,
        rating: feedbackRating,
      };
      await evaluateData(
        user.user.data.id,
        feedbackData,
        isJoint == "true" ? "true" : "false",
        retake ? 1 : 0
      )
        .then((res) => {
          setLoading(false);
          if (courseValue === "first") {
            navigate(
              `/contactwithcounselor?completed=${courseValue}&retake=${retake}`
            );
          } else {
            navigate(
              `/congratulationmassage?completed=${courseValue}&retake=${retake}`
            );
          }
        })
        .catch((err) => {
          toast.error(err.response.data.error);
          setLoading(false);
        });
      setLoading(false);
    } catch (error) {
      // Handle error, e.g., show an error message
      setLoading(false);
      console.error("Error submitting evaluation:", error);
    }
  };

  return (
    <div>
      <Header />

      <section className="CreditCounseling_page mt-250">
        <div className="theme_container">
          <h1 className="f-52 text-center">
            {courseValue === "first"
              ? "CREDIT COUNSELING COURSE"
              : "DEBTOR EDUCATION"}
            - <span>COURSE EVALUATION</span>
          </h1>

          <Formik
            initialValues={{
              q1: "",
              q2: "",
              q3: "",
              q4: "",
              q5: "",
              q6: "",
              q7: "",
              q8: "",
              q9: "",
              course: courseValue,
              rating: "",
              feedback: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors }) => (
              <Form>
                <div className="CreditCounseling_box mt-60">
                  <div className="CreditCounseling_inner">
                    <div className="question">
                      <div className="number_question">
                        <p className="f-16 f-700 text_success">
                          01 <img src={arrow} alt="Arrow" className="w-12" />
                        </p>
                      </div>
                      <div className="question_text">
                        <h5 className="f-16 fw-600">
                          The goals of our course were explained clearly:
                        </h5>
                      </div>
                    </div>
                    <div className="radio_wrap_question pl-45">
                      <div className="iagree_radio mb-20">
                        <Field
                          type="radio"
                          name="q1"
                          id="goals-yes"
                          value="yes"
                          className=" "
                        />
                        <label htmlFor="goals-yes">Yes</label>
                      </div>
                      <div className="iagree_radio">
                        <Field
                          type="radio"
                          name="q1"
                          id="goals-no"
                          value="no"
                          className=" "
                        />

                        <label htmlFor="goals-no">No </label>
                      </div>
                      <ErrorMessage
                        name="q1"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div className="CreditCounseling_inner pt-40">
                    <div className="question">
                      <div className="number_question">
                        <p className="f-16 f-700 text_success">
                          02 <img src={arrow} alt="Arrow" className="w-12" />
                        </p>
                      </div>
                      <div className="question_text">
                        <h5 className="f-16 fw-600">
                          The course topics were relevant to my life:
                        </h5>
                      </div>
                    </div>
                    <div className="radio_wrap_question pl-45">
                      <div className="iagree_radio mb-20">
                        <Field
                          type="radio"
                          name="q2"
                          id="topics-yes"
                          value="yes"
                          className=" "
                        />
                        <label htmlFor="topics-yes">Yes</label>
                      </div>
                      <div className="iagree_radio">
                        <Field
                          type="radio"
                          name="q2"
                          id="topics-no"
                          value="no"
                          className=" "
                        />
                        <label htmlFor="topics-no">No </label>
                      </div>
                      <ErrorMessage
                        name="q2"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div className="CreditCounseling_inner pt-40">
                    <div className="question">
                      <div className="number_question">
                        <p className="f-16 f-700 text_success">
                          03 <img src={arrow} alt="Arrow" className="w-12" />
                        </p>
                      </div>
                      <div className="question_text">
                        <h5 className="f-16 fw-600">
                          The learning materials were helpful:
                        </h5>
                      </div>
                    </div>
                    <div className="radio_wrap_question pl-45">
                      <div className="iagree_radio mb-20">
                        <Field
                          type="radio"
                          name="q3"
                          id="learning-yes"
                          value="yes"
                          className=" "
                        />
                        <label htmlFor="learning-yes">Yes</label>
                      </div>
                      <div className="iagree_radio">
                        <Field
                          type="radio"
                          name="q3"
                          id="learning-no"
                          value="no"
                          className=" "
                        />
                        <label htmlFor="learning-no">No </label>
                      </div>
                      <ErrorMessage
                        name="q3"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div className="CreditCounseling_inner pt-40">
                    <div className="question">
                      <div className="number_question">
                        <p className="f-16 f-700 text_success">
                          04 <img src={arrow} alt="Arrow" className="w-12" />
                        </p>
                      </div>
                      <div className="question_text">
                        <h5 className="f-16 fw-600">
                          The course content was easy to understand:
                        </h5>
                      </div>
                    </div>
                    <div className="radio_wrap_question pl-45">
                      <div className="iagree_radio mb-20">
                        <Field
                          type="radio"
                          name="q4"
                          id="content-yes"
                          value="yes"
                          className=" "
                        />
                        <label htmlFor="content-yes">Yes</label>
                      </div>
                      <div className="iagree_radio">
                        <Field
                          type="radio"
                          name="q4"
                          id="content-no"
                          value="no"
                          className=" "
                        />
                        <label htmlFor="content-no">No </label>
                      </div>
                      <ErrorMessage
                        name="q4"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>

                  <div className="CreditCounseling_inner pt-40">
                    <div className="question">
                      <div className="number_question">
                        <p className="f-16 f-700 text_success">
                          05 <img src={arrow} alt="Arrow" className="w-12" />
                        </p>
                      </div>
                      <div className="question_text">
                        <h5 className="f-16 fw-600">
                          The teacher was well prepared:
                        </h5>
                      </div>
                    </div>
                    <div className="radio_wrap_question pl-45">
                      <div className="iagree_radio mb-20">
                        <Field
                          type="radio"
                          name="q5"
                          id="teacher-yes"
                          value="yes"
                          className=" "
                        />
                        <label htmlFor="teacher-yes">Yes</label>
                      </div>
                      <div className="iagree_radio">
                        <Field
                          type="radio"
                          name="q5"
                          id="teacher-no"
                          value="no"
                          className=" "
                        />
                        <label htmlFor="teacher-no">No </label>
                      </div>
                      <ErrorMessage
                        name="q5"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>

                  <div className="CreditCounseling_inner pt-40">
                    <div className="question">
                      <div className="number_question">
                        <p className="f-16 f-700 text_success">
                          06 <img src={arrow} alt="Arrow" className="w-12" />
                        </p>
                      </div>
                      <div className="question_text">
                        <h5 className="f-16 fw-600">
                          The website was easy to use:
                        </h5>
                      </div>
                    </div>
                    <div className="radio_wrap_question pl-45">
                      <div className="iagree_radio mb-20">
                        <Field
                          type="radio"
                          name="q6"
                          id="website-yes"
                          value="yes"
                          className=" "
                        />
                        <label htmlFor="website-yes">Yes</label>
                      </div>
                      <div className="iagree_radio">
                        <Field
                          type="radio"
                          name="q6"
                          id="website-no"
                          value="no"
                          className=" "
                        />
                        <label htmlFor="website-no">No </label>
                      </div>
                      <ErrorMessage
                        name="q6"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>

                  <div className="CreditCounseling_inner pt-40">
                    <div className="question">
                      <div className="number_question">
                        <p className="f-16 f-700 text_success">
                          07 <img src={arrow} alt="Arrow" className="w-12" />
                        </p>
                      </div>
                      <div className="question_text">
                        <h5 className="f-16 fw-600">
                          The teacher was helpful:
                        </h5>
                      </div>
                    </div>
                    <div className="radio_wrap_question pl-45">
                      <div className="iagree_radio mb-20">
                        <Field
                          type="radio"
                          name="q7"
                          id="helpful-yes"
                          value="yes"
                          className=" "
                        />
                        <label htmlFor="helpful-yes">Yes</label>
                      </div>
                      <div className="iagree_radio">
                        <Field
                          type="radio"
                          name="q7"
                          id="helpful-no"
                          value="no"
                          className=" "
                        />
                        <label htmlFor="helpful-no">No </label>
                      </div>
                      <ErrorMessage
                        name="q7"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>

                  <div className="CreditCounseling_inner pt-40">
                    <div className="question">
                      <div className="number_question">
                        <p className="f-16 f-700 text_success">
                          08 <img src={arrow} alt="Arrow" className="w-12" />
                        </p>
                      </div>
                      <div className="question_text">
                        <h5 className="f-16 fw-600">
                          I learned something I can use:
                        </h5>
                      </div>
                    </div>
                    <div className="radio_wrap_question pl-45">
                      <div className="iagree_radio mb-20">
                        <Field
                          type="radio"
                          name="q8"
                          id="learned-yes"
                          value="yes"
                          className=" "
                        />
                        <label htmlFor="learned-yes">Yes</label>
                      </div>
                      <div className="iagree_radio">
                        <Field
                          type="radio"
                          name="q8"
                          id="learned-no"
                          value="no"
                          className=" "
                        />
                        <label htmlFor="learned-no">No </label>
                      </div>
                      <ErrorMessage
                        name="q8"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>

                  <div className="CreditCounseling_inner pt-40">
                    <div className="question">
                      <div className="number_question">
                        <p className="f-16 f-700 text_success">
                          09 <img src={arrow} alt="Arrow" className="w-12" />
                        </p>
                      </div>
                      <div className="question_text">
                        <h5 className="f-16 fw-600">
                          I will use a budget at home:
                        </h5>
                      </div>
                    </div>
                    <div className="radio_wrap_question pl-45">
                      <div className="iagree_radio mb-20">
                        <Field
                          type="radio"
                          name="q9"
                          id="budget-yes"
                          value="yes"
                          className=" "
                        />
                        <label htmlFor="budget-yes">Yes</label>
                      </div>
                      <div className="iagree_radio">
                        <Field
                          type="radio"
                          name="q9"
                          id="budget-no"
                          value="no"
                          className=" "
                        />
                        <label htmlFor="budget-no">No </label>
                      </div>
                      <ErrorMessage
                        name="q9"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                </div>

                <div className="feadback_box  mt-60">
                  <div className="feadback_head">
                    {courseValue === "first" ? (
                      <h1 className="f-26">
                        Pre-Filing{" "}
                        <span className="text_success">Credit Counseling </span>
                      </h1>
                    ) : (
                      <h1 className="f-26">
                        Post-Filing{" "}
                        <span className="text_success">Debtor Education</span>
                      </h1>
                    )}

                    <div className="feadback_img star-rating">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <span
                          key={star}
                          className={`star ${
                            star <= feedbackRating ? "selected" : ""
                          }`}
                          onClick={() =>
                            setFeedbackRating(
                              feedbackRating === star ? star - 1 : star
                            )
                          }
                        >
                          &#9734;
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="form-group Feedback_input mt-20">
                    <Field
                      as="textarea"
                      className="form-control"
                      name="feedback"
                      rows="6"
                      placeholder="Feedback"
                    />
                    <ErrorMessage
                      name="feedback"
                      component="div"
                      className="error text-danger"
                    />
                  </div>
                  <div className="btn_wrap mt-20 text-center">
                    {courseValue === "second" && (
                      <button
                        className="btn_brnd-outline mr-22"
                        onClick={() => {
                          navigate(
                            `/congratulationmassage?completed=${courseValue}&retake=${retake}`
                          );
                        }}
                      >
                        Skip
                      </button>
                    )}
                    <button
                      disabled={loading}
                      className="btn_brnd"
                      type="submit"
                      onClick={() => {
                        if (Object.keys(errors).length > 0) {
                          toast.error("Form validation failed");
                        }
                      }}
                    >
                      {loading ? (
                        <ThreeDots
                          height="20"
                          width="148"
                          radius="9"
                          color="#4fa94d"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{
                            display: "block",
                          }}
                          wrapperClassName="spinner"
                          visible={true}
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default CreditCounseling;
