import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import tick_white from "../../assets/images/tick_white.png";

import { useState } from "react";
import options from "../../constants";
import { budgetAnalysis, courseUpdate } from "../../api/server";
import Header from "../../layouts/header";
import { getBudgetAnalysis } from "../../api/server";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";

// const   validationSchema = Yup.object().shape({
// //     //////////////////////////////// step 1 schema////////////////////////////////
//     // householdSize: Yup.number().typeError('Invalid number').required('Required'),
//     householdSize: Yup.number()
//     .required('Required')
//     .min(1, 'Household size must be at least 1')
//     .max(14, 'Household size cannot exceed 14'),

//     brigitteGrossIncome: Yup.number().typeError('Invalid amount').required('Required'),
//     brigitteNetIncome: Yup.number().typeError('Invalid amount').required('Required'),
//     brigitteOver65: Yup.boolean().required('Required'),
//     additionalGrossIncome: Yup.number().typeError('Invalid amount').required('Required'),
//     additionalNetIncome: Yup.number().typeError('Invalid amount').required('Required'),
//     additionalOver65: Yup.boolean().required('Required'),
//     alimony: Yup.number().typeError('Invalid amount').required('Required'),
//     childSupport: Yup.number().typeError('Invalid amount').required('Required'),
//     miscIncome: Yup.number().typeError('Invalid amount').required('Required'),
//     ///////////////////////////// step 2 schema////////////////////////
//     rentOrMortgage: Yup.number().typeError('Invalid amount').required('Required'),
//     realEstateTaxes: Yup.number().typeError('Invalid amount').required('Required'),
//     propertyInsurance: Yup.number().typeError('Invalid amount').required('Required'),
//     mortgageInsurance: Yup.number().typeError('Invalid amount').required('Required'),
//     homeMaintenance: Yup.number().typeError('Invalid amount').required('Required'),
//     ///////////////////////////// step 3 schema////////////////////////
//     homePhone: Yup.number().typeError('Invalid amount').required('Required'),
//     internet: Yup.number().typeError('Invalid amount').required('Required'),
//     cableTelevision: Yup.number().typeError('Invalid amount').required('Required'),
//     electricity: Yup.number().typeError('Invalid amount').required('Required'),
//     naturalGas: Yup.number().typeError('Invalid amount').required('Required'),
//     water: Yup.number().typeError('Invalid amount').required('Required'),
//     garbage: Yup.number().typeError('Invalid amount').required('Required'),
//     otherUtilities: Yup.number().typeError('Invalid amount').required('Required'),
//     ///////////////////////////// step 4 schema////////////////////////
//     groceries: Yup.number().typeError('Invalid amount').required('Required'),
//     fastFood: Yup.number().typeError('Invalid amount').required('Required'),
//     restaurant: Yup.number().typeError('Invalid amount').required('Required'),
//     coffee: Yup.number().typeError('Invalid amount').required('Required'),
//     drinksAlcohol: Yup.number().typeError('Invalid amount').required('Required'),
//     petFood: Yup.number().typeError('Invalid amount').required('Required'),
//     otherFood: Yup.number().typeError('Invalid amount').required('Required'),

//     ///////////////////////////// step 5 schema////////////////////////
//     carInsurance: Yup.number().typeError('Invalid amount').required('Required'),
//     gasAndOil: Yup.number().typeError('Invalid amount').required('Required'),
//     privateParking: Yup.number().typeError('Invalid amount').required('Required'),
//     publicTransportation: Yup.number().typeError('Invalid amount').required('Required'),
//     otherTransportation: Yup.number().typeError('Invalid amount').required('Required'),

//     ///////////////////////////// step 6 schema////////////////////////
//     cellPhone: Yup.number().typeError('Invalid amount').required('Required'),
//     lifeInsurance: Yup.number().typeError('Invalid amount').required('Required'),
//     hairBarberShop: Yup.number().typeError('Invalid amount').required('Required'),
//     laundry: Yup.number().typeError('Invalid amount').required('Required'),
//     spaPersonalGrooming: Yup.number().typeError('Invalid amount').required('Required'),
//     clothing: Yup.number().typeError('Invalid amount').required('Required'),
//     booksMagazines: Yup.number().typeError('Invalid amount').required('Required'),
//     electronicsHousewares: Yup.number().typeError('Invalid amount').required('Required'),
//     hobbiesTravel: Yup.number().typeError('Invalid amount').required('Required'),
//     childSupport1: Yup.number().typeError('Invalid amount').required('Required'),
//     alimony1: Yup.number().typeError('Invalid amount').required('Required'),
//     otherMisc: Yup.number().typeError('Invalid amount').required('Required'),

//     ///////////////////////////// step 7 schema////////////////////////
//     moviesOutings: Yup.number().typeError('Invalid amount').required('Required'),
//     mediaRentalsNetflix: Yup.number().typeError('Invalid amount').required('Required'),
//     partiesGatherings: Yup.number().typeError('Invalid amount').required('Required'),
//     musicConcerts: Yup.number().typeError('Invalid amount').required('Required'),
//     newspaperSubscription: Yup.number().typeError('Invalid amount').required('Required'),
//     otherRecreation: Yup.number().typeError('Invalid amount').required('Required'),

//     ///////////////////////////// step 8 schema////////////////////////
//     giftsHolidays: Yup.number().typeError('Invalid amount').required('Required'),
//     charitableDonations: Yup.number().typeError('Invalid amount').required('Required'),
//     churchDonationsTithing: Yup.number().typeError('Invalid amount').required('Required'),

//     ///////////////////////////// step 9 schema////////////////////////
//     healthInsurance: Yup.number().typeError('Invalid amount').required('Required'),
//     doctor: Yup.number().typeError('Invalid amount').required('Required'),
//     dentalVisits: Yup.number().typeError('Invalid amount').required('Required'),
//     eyeCare: Yup.number().typeError('Invalid amount').required('Required'),
//     medicinePrescriptions: Yup.number().typeError('Invalid amount').required('Required'),
//     gymMemberships: Yup.number().typeError('Invalid amount').required('Required'),
//     otherHealth: Yup.number().typeError('Invalid amount').required('Required'),

//     ///////////////////////////// step 10 schema////////////////////////
//     securedLoanType: Yup.string().required('Required'),
//     creditor: Yup.string().required('Required'),
//     balanceOwed: Yup.number().typeError('Invalid amount').required('Required'),
//     equity: Yup.number().typeError('Invalid amount').required('Required'),
//     annualInterestRate: Yup.number().typeError('Invalid interest rate').required('Required'),
//     monthlyPayment: Yup.number().typeError('Invalid amount').required('Required'),
//     propertyTaxes: Yup.number().typeError('Invalid amount').required('Required'),
//     mortgageInsurance1: Yup.number().typeError('Invalid amount').required('Required'),

//     ///////////////////////////// step 11 schema////////////////////////
//     unsecuredLoanType: Yup.string().required('Required'),
//     creditorName: Yup.string().required('Required'),
//     balanceOwed1: Yup.number().typeError('Invalid amount').required('Required'),
//     annualInterestRate1: Yup.string().required('Required'),
//     monthlyPayment1: Yup.number().typeError('Invalid amount').required('Required'),

//     ///////////////////////////// step 12 schema////////////////////////
//     homeValue: Yup.number().typeError('Invalid amount').required('Required'),
//     otherHomesValue: Yup.string().required('Required'),
//     carsValue: Yup.number().typeError('Invalid amount').required('Required'),
//     jewelryValue: Yup.number().typeError('Invalid amount').required('Required'),
//     householdItemsValue: Yup.number().typeError('Invalid amount').required('Required'),
//     retirementAccountsValue: Yup.string().required('Required'),
//     stocksValue: Yup.string().required('Required'),
//     bondsValue: Yup.string().required('Required'),
//     insuranceValue: Yup.string().required('Required'),
//     mutualFundsValue: Yup.string().required('Required'),
//     checkingSavingsValue: Yup.string().required('Required'),
//     cashValue: Yup.string().required('Required'),
//     othersValue: Yup.number().typeError('Invalid amount').required('Required'),

//     ///////////////////////////// step 13 schema////////////////////////
//     mortgagesValue: Yup.string().required('Required'),
//     vehicleLoansValue: Yup.string().required('Required'),
//     studentLoansValue: Yup.string().required('Required'),
//     otherLoansValue: Yup.string().required('Required'),

// });

/////////////////////  Step 1 /////////////////////////

const submitData = async (
  values,
  props,
  isSubmit = false,
  setPostLoading,
  navigate,
  moveStep = true,
  location
) => {
  setPostLoading(true);
  const isJoint = localStorage.getItem("isJoint");

  await budgetAnalysis(
    values,
    props?.userId ? "admin" : "user",
    props?.userId ? props.userId : props.user.user.data.id
  )
    .then((res) => {
      console.log("testsubmitData1", location, location.search);
      if (isSubmit) {
        const queryParams = new URLSearchParams(location.search);
        const retake = Boolean(
          queryParams.get("retake") !== null &&
            queryParams.get("retake") != "null" &&
            queryParams.get("retake") != "false" &&
            queryParams.get("retake") !== false
        );

        console.log(queryParams, retake);

        const updatedCourseInfo = { video: `7` };
        courseUpdate(
          props.userId ? "admin" : "user",
          props.userId ? props.userId : props.user.user.data.id,
          updatedCourseInfo,
          isJoint == "true" ? "true" : "false",
          retake ? 1 : 0
        )
          .then((res) => {
            setPostLoading(false);

            if (props?.userId) {
              navigate(
                `/budget-analysis-report?id=${props?.userId}&retake=${retake}`
              );
            } else {
              navigate(`/budget-analysis?retake=${retake}`);
            }
          })
          .catch((err) => {
            toast.error(err.response?.data.error);
            setPostLoading(false);
          });
      } else {
        setPostLoading(false);
        if (moveStep) {
          console.log(values);
          props.next(values);
        }
      }
    })
    .catch((err) => {
      toast.error(err.response?.data.error);
      setPostLoading(false);
    });
};

const Step1 = (props) => {
  const user = useSelector((state) => state.user);
  const userData = user.user.data;

  const [postLoading, setPostLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    submitData(values, props, false, setPostLoading, navigate);
  };
  return (
    <Formik
      initialValues={props.data}
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ resetForm, submitForm, values }) => (
        <Form>
          <div className="Budget_Analysis mt-60">
            <h1 className="title">
              Budget <span className="text_success">Analysis</span>
            </h1>
            {/* Step 1 */}
            <div className="step_progress_bar mt-60">
              <ul>
                <li className="active-step">1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
                <li>5</li>
                <li>6</li>
                <li>7</li>
                <li>8</li>
                <li>9</li>
                <li>10</li>
                <li>11</li>
                <li>12</li>
                <li>13</li>
              </ul>
            </div>
            <div className="head mt-60">
              <h1 className="f18">Account Details</h1>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group mt-40">
                  <label className="label-title" htmlFor="householdSize">
                    How many people do you have in your household?
                  </label>
                  <Field
                    type="number"
                    id="householdSize"
                    name="budgetAnalysis.household_size"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="budgetAnalysis.household_size"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              </div>
            </div>
            {/* Step 2 */}
            <div className="head mt-60">
              <h1 className="f18">
                {props.data.user.firstname} {props.data.user.middleinitial}{" "}
                {props.data.user.lastname}'s Income
              </h1>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group mt-40">
                  <label className="label-title" htmlFor="brigitteGrossIncome">
                    {props.data.user.firstname} {props.data.user.middleinitial}{" "}
                    {props.data.user.lastname}'s Monthly Gross Income (before
                    taxes)
                  </label>
                  <div className="double_input">
                    <Field
                      type="number"
                      id="brigitteGrossIncome"
                      name="budgetAnalysis.sp1_grossincome"
                      className="form-control"
                    />
                    <div className="input_wrap">
                      <Field
                        as="select"
                        id="brigitteIncomeType"
                        name="budgetAnalysis.main_work_status"
                        className="form-control custom_select"
                      >
                        <option value="full_time">Full-time income</option>
                        <option value="part_time">Part-Time Income</option>
                        <option value="variable">My Income Varies</option>
                        <option value="seeking">Seeking Employment </option>
                        <option value="disabled">Unable Work</option>
                        <option value="retired">Retired</option>
                      </Field>
                    </div>
                  </div>
                  <ErrorMessage
                    name="budgetAnalysis.main_work_status"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mt-40">
                  <label className="label-title" htmlFor="brigitteNetIncome">
                    {props.data.user.firstname} {props.data.user.middleinitial}{" "}
                    {props.data.user.lastname}'s Monthly Net Income (take home
                    pay)
                  </label>
                  <Field
                    type="number"
                    id="brigitteNetIncome"
                    name="budgetAnalysis.sp1_netincome"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="budgetAnalysis.sp1_netincome"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="check_inline mt-40">
                  <div className="checkbox_wrap">
                    <label className="label-title">
                      Is {props.data.user.firstname}{" "}
                      {props.data.user.middleinitial} {props.data.user.lastname}{" "}
                      over the age of 65?
                    </label>
                  </div>
                  <div className="box_tick d-flex">
                    <div className="iagree_radio mb-3 mr-32">
                      <Field
                        type="radio"
                        name="budgetAnalysis.main_age"
                        id="check1"
                        value="yes"
                        className=""
                      />
                      <label htmlFor="check1">Yes</label>
                    </div>
                    <div className="iagree_radio mb-3">
                      <Field
                        type="radio"
                        name="budgetAnalysis.main_age"
                        value="no"
                        id="check2"
                        className=""
                      />
                      <label htmlFor="check2">No</label>
                    </div>
                  </div>
                  <ErrorMessage
                    name="budgetAnalysis.main_age"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              </div>
            </div>

            {/* Step 3 */}
            <div className="head mt-60">
              <h1 className="f18">Additional Earner's Income</h1>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group mt-40">
                  <label
                    className="label-title"
                    htmlFor="additionalGrossIncome"
                  >
                    Additional Earner's Monthly Gross Income (before taxes)
                  </label>
                  <div className="double_input">
                    <Field
                      type="number"
                      id="additionalGrossIncome"
                      name="budgetAnalysis.sp2_grossincome"
                      className="form-control"
                    />
                    <div className="input_wrap">
                      <Field
                        as="select"
                        id="additionalIncomeType"
                        name="budgetAnalysis.joint_work_status"
                        className="form-control custom_select"
                      >
                        <option value="full_time">Full-time income</option>
                        <option value="part_time">Part-Time Income</option>
                        <option value="variable">My Income Varies</option>
                        <option value="seeking ">Seeking Employment </option>
                        <option value="disabled">Unable Work</option>
                        <option value="retired">Retired</option>
                      </Field>
                    </div>
                  </div>
                  <ErrorMessage
                    name="budgetAnalysis.joint_work_status"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mt-40">
                  <label className="label-title" htmlFor="additionalNetIncome">
                    Additional Earner's Monthly Net Income (take home pay)
                  </label>
                  <Field
                    type="number"
                    id="additionalNetIncome"
                    name="budgetAnalysis.sp2_netincome"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="budgetAnalysis.sp2_netincome"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="check_inline mt-40">
                  <div className="checkbox_wrap">
                    <label className="label-title">
                      Is Additional Earner over the age of 65?
                    </label>
                  </div>

                  <div className="box_tick d-flex">
                    <div className="iagree_radio mb-3 mr-32">
                      <Field
                        type="radio"
                        name="budgetAnalysis.joint_age"
                        id="check3"
                        value="yes"
                        className=""
                      />
                      <label htmlFor="check3">Yes</label>
                    </div>
                    <div className="iagree_radio mb-3">
                      <Field
                        type="radio"
                        name="budgetAnalysis.joint_age"
                        id="check4"
                        value="no"
                        className=""
                      />
                      <label htmlFor="check4">No</label>
                    </div>
                  </div>
                  <ErrorMessage
                    name="budgetAnalysis.joint_age"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              </div>
              <div className="col-lg-6"></div>
              <div className="col-lg-4">
                <div className="form-group mt-40">
                  <label className="label-title" htmlFor="alimony">
                    Alimony
                  </label>
                  <Field
                    type="number"
                    id="alimony"
                    name="budgetAnalysis.alimony"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="budgetAnalysis.alimony"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group mt-40">
                  <label className="label-title" htmlFor="childSupport">
                    Child Support
                  </label>
                  <Field
                    type="number"
                    id="childSupport"
                    name="budgetAnalysis.childsupport"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="budgetAnalysis.childsupport"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group mt-40">
                  <label className="label-title" htmlFor="miscIncome">
                    Misc. Income
                  </label>
                  <Field
                    type="number"
                    id="miscIncome"
                    name="budgetAnalysis.miscincome"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="budgetAnalysis.miscincome"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="text-center mt-60">
              {props?.userId && (
                <button
                  type="button"
                  className="text_success mt-4 me-3"
                  onClick={() => {
                    navigate(`/budget-analysis-report?id=${props?.userId}`);
                  }}
                  disabled={postLoading}
                >
                  Previous
                </button>
              )}
              {/* <button
                                type="button"
                                onClick={() => resetForm()}
                                className="btn_brnd-outline mt-4 me-3"
                            >
                                Return To This Client Account
                            </button> */}
              <button
                type="submit"
                className="btn_brnd mt-4 me-3"
                disabled={postLoading}
              >
                {postLoading ? (
                  <ThreeDots
                    height="20"
                    width="140"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: "block",
                    }}
                    wrapperClassName="spinner"
                    visible={true}
                  />
                ) : (
                  "Save & Continue"
                )}
              </button>
              <button
                onClick={() => {
                  props.next(values);
                }}
                className="text_success mt-4 me-3"
                disabled={postLoading}
              >
                Next
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

/////////////////////  Step 2 /////////////////////////
const Step2 = (props) => {
  const [postLoading, setPostLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    submitData(values, props, false, setPostLoading, navigate);
  };

  return (
    <div>
      <Formik
        initialValues={props.data}
        // validationSchema={validationSchema}={ // validationSchema={validationSchema}}
        onSubmit={handleSubmit}
      >
        {({ resetForm, submitForm, values }) => (
          <Form className="Budget_Analysis mt-60">
            <h1 className="title">
              Budget <span className="text_success">Analysis</span>
            </h1>
            {/* Step 2 */}
            <div className="step_progress_bar mt-60">
              <ul>
                <li className="complete_step bg_success">
                  <img src={tick_white} alt="tick_white" />
                </li>
                <li className="active-step">2</li>
                <li>3</li>
                <li>4</li>
                <li>5</li>
                <li>6</li>
                <li>7</li>
                <li>8</li>
                <li>9</li>
                <li>10</li>
                <li>11</li>
                <li>12</li>
                <li>13</li>
              </ul>
            </div>
            <div className="text-box mt-60">
              <p>
                It is now time for us to take a look at your monthly expenses.
                Please complete this exercise by estimating the actual or
                average amounts of each category. If payments are made biweekly,
                quarterly, or annually, please adjust the amount to reflect
                monthly payment. In order for this Budget Analysis to work, it
                is important you accurately record each amount. We value your
                privacy and will never share any of the financial information
                you enter with anyone, so please provide the most honest answers
                possible. If a category does not apply to you, it is okay to
                leave it blank.
              </p>
            </div>
            <div className="head mt-60">
              <h1 className="f18">Housing</h1>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group mt-40">
                  <label className="label-title" htmlFor="rentOrMortgage">
                    Rent or Home Mortgage (include lot rented for mobile home)
                  </label>
                  <Field
                    type="number"
                    id="rentOrMortgage"
                    name="budgetAnalysis.housing_rent"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="budgetAnalysis.housing_rent"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mt-40">
                  <label className="label-title" htmlFor="realEstateTaxes">
                    Are real estate taxes included? If not, how much are they?
                  </label>
                  <Field
                    type="number"
                    id="realEstateTaxes"
                    name="budgetAnalysis.housing_taxes"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="budgetAnalysis.housing_taxes"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mt-40">
                  <label className="label-title" htmlFor="propertyInsurance">
                    Is property insurance included? If not, how much is it?
                  </label>
                  <Field
                    type="number"
                    id="propertyInsurance"
                    name="budgetAnalysis.housing_propins"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="budgetAnalysis.housing_propins"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mt-40">
                  <label className="label-title" htmlFor="mortgageInsurance">
                    Is mortgage insurance included? If not, how much is it?
                  </label>
                  <Field
                    type="number"
                    id="mortgageInsurance"
                    name="budgetAnalysis.housing_mortins"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="budgetAnalysis.housing_mortins"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mt-40">
                  <label className="label-title" htmlFor="homeMaintenance">
                    Home Maintenance
                  </label>
                  <Field
                    type="number"
                    id="homeMaintenance"
                    name="budgetAnalysis.housing_maintenance"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="budgetAnalysis.housing_maintenance"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              </div>
            </div>

            {/*////////////// Buttons for next, previous and continue ///////////// */}
            <div className="text-center mt-60">
              <button
                type="button"
                onClick={() => props.prev(values)}
                className="text_success mt-4 me-3"
                disabled={postLoading}
              >
                Previous
              </button>
              {/* <button
                                type="button"
                                onClick={() => resetForm()}
                                className="btn_brnd-outline mt-4 me-3"
                            >
                                Return To This Client Account
                            </button> */}
              <button
                type="submit"
                className="btn_brnd mt-4 me-3"
                disabled={postLoading}
              >
                {postLoading ? (
                  <ThreeDots
                    height="20"
                    width="140"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: "block",
                    }}
                    wrapperClassName="spinner"
                    visible={true}
                  />
                ) : (
                  "Save & Continue"
                )}
              </button>
              <button
                onClick={() => {
                  props.next(values);
                }}
                className="text_success mt-4 me-3"
                disabled={postLoading}
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

/////////////////////  Step 3/////////////////////////
const Step3 = (props) => {
  const [postLoading, setPostLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    submitData(values, props, false, setPostLoading, navigate);
  };

  return (
    <div>
      <Formik
        initialValues={props.data}
        // validationSchema={validationSchema}={ // validationSchema={validationSchema}}
        onSubmit={handleSubmit}
      >
        {({ resetForm, submitForm, values }) => (
          <Form>
            <div className="Budget_Analysis mt-60">
              <h1 className="title">
                Budget <span className="text_success">Analysis</span>
              </h1>
              <div className="step_progress_bar mt-60">
                {/* Progress bar JSX */}
                <ul>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="active-step">3</li>
                  <li>4</li>
                  <li>5</li>
                  <li>6</li>
                  <li>7</li>
                  <li>8</li>
                  <li>9</li>
                  <li>10</li>
                  <li>11</li>
                  <li>12</li>
                  <li>13</li>
                </ul>
              </div>
              <div className="head mt-60">
                <h1 className="f18">Utilities</h1>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="homePhone">
                      Home Phone
                    </label>
                    <Field
                      type="number"
                      id="homePhone"
                      name="budgetAnalysis.utilities_phone"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.utilities_phone"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="internet">
                      Internet
                    </label>
                    <Field
                      type="number"
                      id="internet"
                      name="budgetAnalysis.utilities_internet"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.utilities_internet"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="cableTelevision">
                      Cable Television
                    </label>
                    <Field
                      type="number"
                      id="cableTelevision"
                      name="budgetAnalysis.utilities_television"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.utilities_television"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="electricity">
                      Electricity
                    </label>
                    <Field
                      type="number"
                      id="electricity"
                      name="budgetAnalysis.utilities_electricity"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.utilities_electricity"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="naturalGas">
                      Natural Gas/Propane
                    </label>
                    <Field
                      type="number"
                      id="naturalGas"
                      name="budgetAnalysis.utilities_gas"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.utilities_gas"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="water">
                      Water
                    </label>
                    <Field
                      type="number"
                      id="water"
                      name="budgetAnalysis.utilities_water"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.utilities_water"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="garbage">
                      Garbage
                    </label>
                    <Field
                      type="number"
                      id="garbage"
                      name="budgetAnalysis.utilities_garbage"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.utilities_garbage"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="otherUtilities">
                      Other Utilities
                    </label>
                    <Field
                      type="number"
                      id="otherUtilities"
                      name="budgetAnalysis.utilities_other"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.utilities_other"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/*////////////// Buttons for next, previous and continue ///////////// */}
            <div className="text-center mt-60">
              <button
                type="button"
                onClick={() => props.prev(values)}
                className="text_success mt-4 me-3"
                disabled={postLoading}
              >
                Previous
              </button>

              <button
                type="submit"
                className="btn_brnd mt-4 me-3"
                disabled={postLoading}
              >
                {postLoading ? (
                  <ThreeDots
                    height="20"
                    width="140"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: "block",
                    }}
                    wrapperClassName="spinner"
                    visible={true}
                  />
                ) : (
                  "Save & Continue"
                )}
              </button>
              <button
                onClick={() => {
                  props.next(values);
                }}
                className="text_success mt-4 me-3"
                disabled={postLoading}
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

/////////////////////  Step 4 /////////////////////////
const Step4 = (props) => {
  const [postLoading, setPostLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    submitData(values, props, false, setPostLoading, navigate);
  };

  return (
    <div>
      <Formik
        initialValues={props.data}
        // validationSchema={validationSchema}={ // validationSchema={validationSchema}}
        onSubmit={handleSubmit}
      >
        {({ resetForm, submitForm, values }) => (
          <Form>
            <div className="Budget_Analysis mt-60">
              <h1 className="title">
                Budget <span className="text_success">Analysis</span>
              </h1>
              {/* Step 4 */}
              <div className="step_progress_bar mt-60">
                <ul>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="active-step">4</li>
                  <li>5</li>
                  <li>6</li>
                  <li>7</li>
                  <li>8</li>
                  <li>9</li>
                  <li>10</li>
                  <li>11</li>
                  <li>12</li>
                  <li>13</li>
                </ul>
              </div>
              <div className="head mt-60">
                <h1 className="f18">Food</h1>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="groceries">
                      Groceries
                    </label>
                    <Field
                      type="number"
                      id="groceries"
                      name="budgetAnalysis.food_groceries"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.food_groceries"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="fastFood">
                      Fast Food
                    </label>
                    <Field
                      type="number"
                      id="fastFood"
                      name="budgetAnalysis.food_fastfood"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.food_fastfood"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="restaurant">
                      Restaurant
                    </label>
                    <Field
                      type="number"
                      id="restaurant"
                      name="budgetAnalysis.food_restaurant"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.food_restaurant"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="coffee">
                      Coffee
                    </label>
                    <Field
                      type="number"
                      id="coffee"
                      name="budgetAnalysis.food_coffee"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.food_coffee"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="drinksAlcohol">
                      Drinks/Alcohol
                    </label>
                    <Field
                      type="number"
                      id="drinksAlcohol"
                      name="budgetAnalysis.food_alcohol"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="food_alcohol"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="petFood">
                      Pet Food
                    </label>
                    <Field
                      type="number"
                      id="petFood"
                      name="budgetAnalysis.food_pet"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.food_pet"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="otherFood">
                      Other Food
                    </label>
                    <Field
                      type="number"
                      id="otherFood"
                      name="budgetAnalysis.food_other"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.food_other"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/*////////////// Buttons for next, previous and continue ///////////// */}
            <div className="text-center mt-60">
              <button
                type="button"
                onClick={() => props.prev(values)}
                className="text_success mt-4 me-3"
                disabled={postLoading}
              >
                Previous
              </button>
              {/* <button
                                type="button"
                                onClick={() => resetForm()}
                                className="btn_brnd-outline mt-4 me-3"
                            >
                                Return To This Client Account
                            </button> */}
              <button
                type="submit"
                className="btn_brnd mt-4 me-3"
                disabled={postLoading}
              >
                {postLoading ? (
                  <ThreeDots
                    height="20"
                    width="140"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: "block",
                    }}
                    wrapperClassName="spinner"
                    visible={true}
                  />
                ) : (
                  "Save & Continue"
                )}
              </button>
              <button
                onClick={() => {
                  props.next(values);
                }}
                className="text_success mt-4 me-3"
                disabled={postLoading}
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

/////////////////  Step 5 /////////////////////////
const Step5 = (props) => {
  const [postLoading, setPostLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    submitData(values, props, false, setPostLoading, navigate);
  };

  return (
    <div>
      <Formik
        initialValues={props.data}
        // validationSchema={validationSchema}={ // validationSchema={validationSchema}}
        onSubmit={handleSubmit}
      >
        {({ resetForm, submitForm, values }) => (
          <Form>
            <div className="Budget_Analysis mt-60">
              <h1 className="title">
                Budget <span className="text_success">Analysis</span>
              </h1>
              {/* Step 5 */}
              <div className="step_progress_bar mt-60">
                <ul>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="active-step">5</li>
                  <li>6</li>
                  <li>7</li>
                  <li>8</li>
                  <li>9</li>
                  <li>10</li>
                  <li>11</li>
                  <li>12</li>
                  <li>13</li>
                </ul>
              </div>
              <div className="head mt-60">
                <h1 className="f18">Transportation</h1>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="carInsurance">
                      Car Insurance
                    </label>
                    <Field
                      type="number"
                      id="carInsurance"
                      name="budgetAnalysis.trans_insurance"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.trans_insurance"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="gasAndOil">
                      Gas And Oil
                    </label>
                    <Field
                      type="number"
                      id="gasAndOil"
                      name="budgetAnalysis.trans_gas"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.trans_gas"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="privateParking">
                      Private Parking
                    </label>
                    <Field
                      type="number"
                      id="privateParking"
                      name="budgetAnalysis.trans_parking"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.trans_parking"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label
                      className="label-title"
                      htmlFor="publicTransportation"
                    >
                      Public Transportation
                    </label>
                    <Field
                      type="number"
                      id="publicTransportation"
                      name="budgetAnalysis.trans_public"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.trans_public"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label
                      className="label-title"
                      htmlFor="otherTransportation"
                    >
                      Other Transportation
                    </label>
                    <Field
                      type="number"
                      id="otherTransportation"
                      name="budgetAnalysis.trans_other"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.trans_other"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/*////////////// Buttons for next, previous and continue ///////////// */}
            <div className="text-center mt-60">
              <button
                type="button"
                onClick={() => props.prev(values)}
                className="text_success mt-4 me-3"
                disabled={postLoading}
              >
                Previous
              </button>
              {/* <button
                                type="button"
                                onClick={() => resetForm()}
                                className="btn_brnd-outline mt-4 me-3"
                            >
                                Return To This Client Account
                            </button> */}
              <button
                type="submit"
                className="btn_brnd mt-4 me-3"
                disabled={postLoading}
              >
                {postLoading ? (
                  <ThreeDots
                    height="20"
                    width="140"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: "block",
                    }}
                    wrapperClassName="spinner"
                    visible={true}
                  />
                ) : (
                  "Save & Continue"
                )}
              </button>
              <button
                onClick={() => {
                  props.next(values);
                }}
                className="text_success mt-4 me-3"
                disabled={postLoading}
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

/////////////////  Step 6 /////////////////////////
const Step6 = (props) => {
  const [postLoading, setPostLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    submitData(values, props, false, setPostLoading, navigate);
  };

  return (
    <div>
      <Formik
        initialValues={props.data}
        // validationSchema={validationSchema}={ // validationSchema={validationSchema}}
        onSubmit={handleSubmit}
      >
        {({ resetForm, submitForm, values }) => (
          <Form>
            <div className="Budget_Analysis mt-60">
              <h1 className="title">
                Budget <span className="text_success">Analysis</span>
              </h1>

              <div className="step_progress_bar mt-60">
                <ul>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="active-step">6</li>
                  <li>7</li>
                  <li>8</li>
                  <li>9</li>
                  <li>10</li>
                  <li>11</li>
                  <li>12</li>
                  <li>13</li>
                </ul>
              </div>
              <div className="head mt-60">
                <h1 className="f18">Miscellaneous</h1>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="cellPhone">
                      Cell Phone
                    </label>
                    <Field
                      type="number"
                      id="cellPhone"
                      name="budgetAnalysis.misc_phone"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.misc_phone"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="lifeInsurance">
                      Life Insurance
                    </label>
                    <Field
                      type="number"
                      id="lifeInsurance"
                      name="budgetAnalysis.misc_lifeins"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.misc_lifeins"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="hairBarberShop">
                      Hair/Barber Shop
                    </label>
                    <Field
                      type="number"
                      id="hairBarberShop"
                      name="budgetAnalysis.misc_hair"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.misc_hair"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="laundry">
                      Laundry
                    </label>
                    <Field
                      type="number"
                      id="laundry"
                      name="budgetAnalysis.misc_laundry"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.misc_laundry"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label
                      className="label-title"
                      htmlFor="spaPersonalGrooming"
                    >
                      Spa/Personal Grooming
                    </label>
                    <Field
                      type="number"
                      id="spaPersonalGrooming"
                      name="budgetAnalysis.misc_spa"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.misc_spa"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="clothing">
                      Clothing
                    </label>
                    <Field
                      type="number"
                      id="clothing"
                      name="budgetAnalysis.misc_clothes"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.misc_clothes"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="booksMagazines">
                      Books/E-books/Magazines
                    </label>
                    <Field
                      type="number"
                      id="booksMagazines"
                      name="budgetAnalysis.misc_books"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.misc_books"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label
                      className="label-title"
                      htmlFor="electronicsHousewares"
                    >
                      Electronics And Housewares
                    </label>
                    <Field
                      type="number"
                      id="electronicsHousewares"
                      name="budgetAnalysis.misc_electronics"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.misc_electronics"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="hobbiesTravel">
                      Hobbies And Travel
                    </label>
                    <Field
                      type="number"
                      id="hobbiesTravel"
                      name="budgetAnalysis.misc_hobbies"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.misc_hobbies"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="childSupport1">
                      Child Support
                    </label>
                    <Field
                      type="number"
                      id="childSupport1"
                      name="budgetAnalysis.misc_childsupport"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.misc_childsupport"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="alimony1">
                      Alimony
                    </label>
                    <Field
                      type="number"
                      id="alimony1"
                      name="budgetAnalysis.misc_alimony"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.misc_alimony"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="otherMisc">
                      Other Misc
                    </label>
                    <Field
                      type="number"
                      id="otherMisc"
                      name="budgetAnalysis.misc_other"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.misc_other"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/*////////////// Buttons for next, previous and continue ///////////// */}
            <div className="text-center mt-60">
              <button
                type="button"
                onClick={() => props.prev(values)}
                className="text_success mt-4 me-3"
                disabled={postLoading}
              >
                Previous
              </button>
              {/* <button
                                type="button"
                                onClick={() => resetForm()}
                                className="btn_brnd-outline mt-4 me-3"
                            >
                                Return To This Client Account
                            </button> */}
              <button
                type="submit"
                className="btn_brnd mt-4 me-3"
                disabled={postLoading}
              >
                {postLoading ? (
                  <ThreeDots
                    height="20"
                    width="140"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: "block",
                    }}
                    wrapperClassName="spinner"
                    visible={true}
                  />
                ) : (
                  "Save & Continue"
                )}
              </button>
              <button
                onClick={() => {
                  props.next(values);
                }}
                className="text_success mt-4 me-3"
                disabled={postLoading}
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

/////////////////  Step 7 /////////////////////////
const Step7 = (props) => {
  const [postLoading, setPostLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    submitData(values, props, false, setPostLoading, navigate);
  };

  return (
    <div>
      <Formik
        initialValues={props.data}
        // validationSchema={validationSchema}={ // validationSchema={validationSchema}}
        onSubmit={handleSubmit}
      >
        {({ resetForm, submitForm, values }) => (
          <Form>
            <div className="Budget_Analysis mt-60">
              <h1 className="title">
                Budget <span className="text_success">Analysis</span>
              </h1>
              {/* Step 7 */}
              <div className="step_progress_bar mt-60">
                <ul>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="active-step">7</li>
                  <li>8</li>
                  <li>9</li>
                  <li>10</li>
                  <li>11</li>
                  <li>12</li>
                  <li>13</li>
                </ul>
              </div>
              <div className="head mt-60">
                <h1 className="f18">Recreation</h1>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="moviesOutings">
                      Movies And Outings
                    </label>
                    <Field
                      type="number"
                      id="moviesOutings"
                      name="budgetAnalysis.rec_movie"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.rec_movie"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label
                      className="label-title"
                      htmlFor="mediaRentalsNetflix"
                    >
                      Media Rentals/Netflix
                    </label>
                    <Field
                      type="number"
                      id="mediaRentalsNetflix"
                      name="budgetAnalysis.rec_dvdrental"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.rec_dvdrental"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="partiesGatherings">
                      Parties And Gatherings
                    </label>
                    <Field
                      type="number"
                      id="partiesGatherings"
                      name="budgetAnalysis.rec_amusement"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.rec_amusement"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="musicConcerts">
                      Music And Concerts
                    </label>
                    <Field
                      type="number"
                      id="musicConcerts"
                      name="budgetAnalysis.rec_music"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.rec_music"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label
                      className="label-title"
                      htmlFor="newspaperSubscription"
                    >
                      Newspaper Subscription
                    </label>
                    <Field
                      type="number"
                      id="newspaperSubscription"
                      name="budgetAnalysis.rec_newspaper"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.rec_newspaper"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="otherRecreation">
                      Other Recreation
                    </label>
                    <Field
                      type="number"
                      id="otherRecreation"
                      name="budgetAnalysis.rec_other"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.rec_other"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/*////////////// Buttons for next, previous and continue ///////////// */}
            <div className="text-center mt-60">
              <button
                type="button"
                onClick={() => props.prev(values)}
                className="text_success mt-4 me-3"
                disabled={postLoading}
              >
                Previous
              </button>
              {/* <button
                                type="button"
                                onClick={() => resetForm()}
                                className="btn_brnd-outline mt-4 me-3"
                            >
                                Return To This Client Account
                            </button> */}
              <button
                type="submit"
                className="btn_brnd mt-4 me-3"
                disabled={postLoading}
              >
                {postLoading ? (
                  <ThreeDots
                    height="20"
                    width="140"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: "block",
                    }}
                    wrapperClassName="spinner"
                    visible={true}
                  />
                ) : (
                  "Save & Continue"
                )}
              </button>
              <button
                onClick={() => {
                  props.next(values);
                }}
                className="text_success mt-4 me-3"
                disabled={postLoading}
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

/////////////////  Step 8 /////////////////////////
const Step8 = (props) => {
  const [postLoading, setPostLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    submitData(values, props, false, setPostLoading, navigate);
  };

  return (
    <div>
      <Formik
        initialValues={props.data}
        // validationSchema={validationSchema}={ // validationSchema={validationSchema}}
        onSubmit={handleSubmit}
      >
        {({ resetForm, submitForm, values }) => (
          <Form>
            <div className="Budget_Analysis mt-60">
              <h1 className="title">
                Budget <span className="text_success">Analysis</span>
              </h1>
              {/* Step 8 */}
              <div className="step_progress_bar mt-60">
                <ul>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="active-step">8</li>
                  <li>9</li>
                  <li>10</li>
                  <li>11</li>
                  <li>12</li>
                  <li>13</li>
                </ul>
              </div>
              <div className="head mt-60">
                <h1 className="f18">Gifts</h1>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="giftsHolidays">
                      Gifts And Holidays
                    </label>
                    <Field
                      type="number"
                      id="giftsHolidays"
                      name="budgetAnalysis.gifts_gifts"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.gifts_gifts"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label
                      className="label-title"
                      htmlFor="charitableDonations"
                    >
                      Charitable Donations
                    </label>
                    <Field
                      type="number"
                      id="charitableDonations"
                      name="budgetAnalysis.gifts_donations"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.gifts_donations"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label
                      className="label-title"
                      htmlFor="churchDonationsTithing"
                    >
                      Church Donations/Tithing
                    </label>
                    <Field
                      type="number"
                      id="churchDonationsTithing"
                      name="budgetAnalysis.gifts_tithes"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.gifts_tithes"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
              </div>
              {/*////////////// Buttons for next, previous and continue ///////////// */}
              <div className="text-center mt-60">
                <button
                  type="button"
                  onClick={() => props.prev(values)}
                  className="text_success mt-4 me-3"
                  disabled={postLoading}
                >
                  Previous
                </button>
                {/* <button
                                type="button"
                                onClick={() => resetForm()}
                                className="btn_brnd-outline mt-4 me-3"
                            >
                                Return To This Client Account
                            </button> */}
                <button
                  type="submit"
                  className="btn_brnd mt-4 me-3"
                  disabled={postLoading}
                >
                  {postLoading ? (
                    <ThreeDots
                      height="20"
                      width="140"
                      radius="9"
                      color="#4fa94d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{
                        display: "block",
                      }}
                      wrapperClassName="spinner"
                      visible={true}
                    />
                  ) : (
                    "Save & Continue"
                  )}
                </button>
                <button
                  onClick={() => {
                    props.next(values);
                  }}
                  className="text_success mt-4 me-3"
                  disabled={postLoading}
                >
                  Next
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

/////////////////  Step 9 /////////////////////////
const Step9 = (props) => {
  const [postLoading, setPostLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    submitData(values, props, false, setPostLoading, navigate);
  };
  return (
    <div>
      <Formik
        initialValues={props.data}
        // validationSchema={validationSchema}={ // validationSchema={validationSchema}}
        onSubmit={handleSubmit}
      >
        {({ resetForm, submitForm, values }) => (
          <Form>
            <div className="Budget_Analysis mt-60">
              <h1 className="title">
                Budget <span className="text_success">Analysis</span>
              </h1>
              {/* Step 9 */}
              <div className="step_progress_bar mt-60">
                <ul>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="active-step">9</li>
                  <li>10</li>
                  <li>11</li>
                  <li>12</li>
                  <li>13</li>
                </ul>
              </div>
              <div className="head mt-60">
                <h1 className="f18">Health</h1>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="healthInsurance">
                      Health Insurance
                    </label>
                    <Field
                      type="number"
                      id="healthInsurance"
                      name="budgetAnalysis.health_ins"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.health_ins"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="doctor">
                      Doctor
                    </label>
                    <Field
                      type="number"
                      id="doctor"
                      name="budgetAnalysis.health_doctor"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.health_doctor"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="dentalVisits">
                      Dental Visits
                    </label>
                    <Field
                      type="number"
                      id="dentalVisits"
                      name="budgetAnalysis.health_dentist"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.health_dentist"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="eyeCare">
                      Eye Care/Glasses/Contacts
                    </label>
                    <Field
                      type="number"
                      id="eyeCare"
                      name="budgetAnalysis.health_eyedoctor"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.health_eyedoctor"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label
                      className="label-title"
                      htmlFor="medicinePrescriptions"
                    >
                      Medicine/Prescriptions
                    </label>
                    <Field
                      type="number"
                      id="medicinePrescriptions"
                      name="budgetAnalysis.health_pharmacy"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.health_pharmacy"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="gymMemberships">
                      Gym Memberships
                    </label>
                    <Field
                      type="number"
                      id="gymMemberships"
                      name="budgetAnalysis.health_gym"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.health_gym"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="otherHealth">
                      Other Health
                    </label>
                    <Field
                      type="number"
                      id="otherHealth"
                      name="budgetAnalysis.health_other"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="budgetAnalysis.health_other"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/*////////////// Buttons for next, previous and continue ///////////// */}
            <div className="text-center mt-60">
              <button
                type="button"
                onClick={() => props.prev(values)}
                className="text_success mt-4 me-3"
                disabled={postLoading}
              >
                Previous
              </button>
              {/* <button
                                type="button"
                                onClick={() => resetForm()}
                                className="btn_brnd-outline mt-4 me-3"
                            >
                                Return To This Client Account
                            </button> */}
              <button
                type="submit"
                className="btn_brnd mt-4 me-3"
                disabled={postLoading}
              >
                {postLoading ? (
                  <ThreeDots
                    height="20"
                    width="140"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: "block",
                    }}
                    wrapperClassName="spinner"
                    visible={true}
                  />
                ) : (
                  "Save & Continue"
                )}
              </button>
              <button
                onClick={() => {
                  props.next(values);
                }}
                className="text_success mt-4 me-3"
                disabled={postLoading}
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

/////////////////  Step 10 /////////////////////////
const Step10 = (props) => {
  /////////////// handle Add data ////////////
  const [addedDebts, setAddedDebts] = useState(props.data.secureDebt);

  const handleAddData = (values, resetForm) => {
    setAddedDebts([...addedDebts, ...values]);
    resetForm();
  };

  // for remove data////////////////
  const handleDelete = (index, event) => {
    event.preventDefault();
    setAddedDebts(addedDebts.filter((item, ind) => ind !== index));
  };

  const [postLoading, setPostLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = (values, { resetForm }) => {
    handleAddData(values.secureDebt, resetForm);
    submitData(
      { ...props.data, secureDebt: [...addedDebts, ...values.secureDebt] },
      props,
      false,
      setPostLoading,
      navigate,
      false
    );
  };

  return (
    <div>
      <Formik
        initialValues={props.formData}
        // validationSchema={validationSchema}={ // validationSchema={validationSchema}}
        onSubmit={handleSubmit}
      >
        {({ resetForm, submitForm, values, setValues }) => (
          <Form>
            <div className="Budget_Analysis mt-60">
              <h1 className="title">
                Budget <span className="text_success">Analysis</span>
              </h1>
              {/* Step 10 */}
              <div className="step_progress_bar mt-60">
                <ul>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="active-step">10</li>
                  <li>11</li>
                  <li>12</li>
                  <li>13</li>
                </ul>
              </div>
              <div className="text-box mt-60">
                <p>
                  It is now time for us to take a look at your monthly expenses.
                  Please complete this exercise by estimating the actual or
                  average amounts of each category. If payments are made
                  biweekly, quarterly, or annually, please adjust the amount to
                  reflect monthly payment. In order for this Budget Analysis to
                  work, it is important you accurately record each amount. We
                  value your privacy and will never share any of the financial
                  information you enter with anyone, so please provide the most
                  honest answers possible. If a category does not apply to you,
                  it is okay to leave it blank.
                </p>
              </div>
              <section className="manage-students box_s mt-60">
                <div className="card ">
                  <div className="card-body">
                    <table className="table table-responsive">
                      <thead>
                        <tr>
                          <th scope="col">Type</th>
                          <th scope="col">Creditor</th>
                          <th scope="col">Balance</th>
                          <th scope="col">Equity</th>
                          <th scope="col">Interest</th>
                          <th scope="col">Payment</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {addedDebts &&
                          addedDebts.length > 0 &&
                          addedDebts.map((debts, index) => (
                            <tr key={index}>
                              <td>{debts.type}</td>

                              <td>{debts.creditor}</td>
                              <td>{debts.balance}</td>
                              <td>{debts.equity}</td>
                              <td>{debts.interest}</td>
                              <td>{debts.payment}</td>
                              <td>
                                <div className="action-btns">
                                  <button
                                    className="btn-delete"
                                    onClick={(event) =>
                                      handleDelete(index, event)
                                    }
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
              <div className="head mt-60">
                <h1 className="f18">Secured Debt</h1>
              </div>

              {/* add map over the fields */}
              {values.secureDebt &&
                values.secureDebt.length > 0 &&
                values.secureDebt.map((debt, index) => (
                  <div key={index}>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="form-group mt-40">
                          <label
                            className="label-title"
                            htmlFor="securedLoanType"
                          >
                            Type of Secured Loan
                          </label>
                          <Field
                            as="select"
                            id="securedLoanType"
                            name={`secureDebt[${index}].type`}
                            className="form-control custom_select"
                            required
                          >
                            <option value="">Select</option>
                            <option value="First Home Mortgage">
                              First Home Mortgage
                            </option>
                            <option value="Second Home Mortgage">
                              Second Home Mortgage
                            </option>
                            <option value="Third Home Mortgage">
                              Third Home Mortgage
                            </option>
                            <option value="Home Equity Loan">
                              Home Equity Loan
                            </option>
                            <option value="Car/Motorcycle Loan">
                              Car/Motorcycle Loan
                            </option>
                            <option value="Recreational Vehicle Loan">
                              Recreational Vehicle Loan
                            </option>
                            <option value="Personal Property Loan">
                              Personal Property Loan
                            </option>
                            <option value="Tax Lien">Tax Lien</option>
                            <option value="Other Secured Debt">
                              Other Secured Debt
                            </option>
                          </Field>
                          <ErrorMessage
                            name={`secureDebt[${index}].type`}
                            component="div"
                            className="error-message text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group mt-40">
                          <label className="label-title" htmlFor="creditor">
                            Creditor or Loan Name
                          </label>
                          <Field
                            type="text"
                            id="creditor"
                            name={`secureDebt[${index}].creditor`}
                            className="form-control"
                            required
                          />
                          <ErrorMessage
                            name={`secureDebt[${index}].creditor`}
                            component="div"
                            className="error-message text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group mt-40">
                          <label className="label-title" htmlFor="balanceOwed">
                            Balance Owed
                          </label>
                          <Field
                            type="number"
                            id="balanceOwed"
                            name={`secureDebt[${index}].balance`}
                            className="form-control"
                            required
                          />
                          <ErrorMessage
                            name={`secureDebt[${index}].balance`}
                            component="div"
                            className="error-message text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group mt-40">
                          <label className="label-title" htmlFor="equity">
                            Equity
                          </label>
                          <Field
                            type="number"
                            id="equity"
                            name={`secureDebt[${index}].equity`}
                            className="form-control"
                            required
                          />
                          <ErrorMessage
                            name={`secureDebt[${index}].equity`}
                            component="div"
                            className="error-message text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group mt-40">
                          <label
                            className="label-title"
                            htmlFor="annualInterestRate"
                          >
                            Annual Interest Rate
                          </label>
                          <Field
                            type="number"
                            id="annualInterestRate"
                            name={`secureDebt[${index}].interest`}
                            className="form-control"
                            required
                          />
                          <ErrorMessage
                            name={`secureDebt[${index}].interest`}
                            component="div"
                            className="error-message text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group mt-40">
                          <label
                            className="label-title"
                            htmlFor="monthlyPayment"
                          >
                            Monthly Payment
                          </label>
                          <Field
                            type="number"
                            id="monthlyPayment"
                            name={`secureDebt[${index}].payment`}
                            className="form-control"
                            required
                          />
                          <ErrorMessage
                            name={`secureDebt[${index}].payment`}
                            component="div"
                            className="error-message text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group mt-40">
                          <label
                            className="label-title"
                            htmlFor="propertyTaxes"
                          >
                            Property Taxes
                          </label>
                          <Field
                            type="number"
                            id="propertyTaxes"
                            name={`secureDebt[${index}].firstmort_taxes`}
                            className="form-control"
                            required
                          />
                          <ErrorMessage
                            name={`secureDebt[${index}].firstmort_taxes`}
                            component="div"
                            className="error-message text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group mt-40">
                          <label
                            className="label-title"
                            htmlFor="mortgageInsurance1"
                          >
                            Mortgage Insurance
                          </label>
                          <Field
                            type="number"
                            id="mortgageInsurance1"
                            name={`secureDebt[${index}].firstmort_homeins`}
                            className="form-control"
                            required
                          />
                          <ErrorMessage
                            name={`secureDebt[${index}].firstmort_homeins`}
                            component="div"
                            className="error-message text-danger"
                          />
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-group mt-40 data-button">
                          <button
                            type="submit"
                            className="btn_brnd"
                            disabled={postLoading}
                          >
                            {postLoading ? (
                              <ThreeDots
                                height="20"
                                width="140"
                                radius="9"
                                color="#4fa94d"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{
                                  display: "block",
                                }}
                                wrapperClassName="spinner"
                                visible={true}
                              />
                            ) : (
                              "Save and Add New"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {/*////////////// Buttons for next, previous and continue ///////////// */}
            <div className="text-center mt-60">
              <button
                type="button"
                onClick={() =>
                  props.prev({
                    ...props.data,
                    secureDebt: [...addedDebts],
                  })
                }
                className="text_success mt-4 me-3"
                disabled={postLoading}
              >
                Previous
              </button>
              {/* <button
                                type="button"
                                onClick={() => resetForm()}
                                className="btn_brnd-outline mt-4 me-3"
                            >
                                Return To This Client Account
                            </button> */}
              <button
                type="button"
                onClick={() => {
                  submitData(
                    {
                      ...props.data,
                      secureDebt: [...addedDebts],
                    },
                    props,
                    false,
                    setPostLoading,
                    navigate
                  );
                }}
                className="btn_brnd mt-4 me-3"
                disabled={postLoading}
              >
                {postLoading ? (
                  <ThreeDots
                    height="20"
                    width="140"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: "block",
                    }}
                    wrapperClassName="spinner"
                    visible={true}
                  />
                ) : (
                  "Save & Continue"
                )}
              </button>
              <button
                type="button"
                onClick={() => {
                  const combinedValues = {
                    secureDebt: addedDebts,
                  };
                  props.next(combinedValues);
                }}
                className="text_success mt-4 me-3"
                disabled={postLoading}
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

/////////////////  Step 11 /////////////////////////
const Step11 = (props) => {
  /////////////// handle Add data ////////////
  const [unDebts, setUnDebts] = useState(props.data.unsecureDebt);

  // for add data ////////////////////////
  const handleAddData = (values, resetForm) => {
    setUnDebts([...unDebts, ...values]);
    resetForm();
  };
  // for remove data////////////////
  const handleDelete = (index, event) => {
    event.preventDefault();
    setUnDebts(unDebts.filter((item, ind) => ind !== index));
  };

  const [postLoading, setPostLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = (values, { resetForm }) => {
    handleAddData(values.unsecureDebt, resetForm);
    submitData(
      { ...props.data, unsecureDebt: [...unDebts, ...values.unsecureDebt] },
      props,
      false,
      setPostLoading,
      navigate,
      false
    );
  };

  return (
    <div>
      <Formik
        initialValues={props.formData}
        // validationSchema={validationSchema}={ // validationSchema={validationSchema}}
        onSubmit={handleSubmit}
      >
        {({ resetForm, submitForm, values }) => (
          <Form>
            <div className="Budget_Analysis mt-60">
              <h1 className="title">
                Budget <span className="text_success">Analysis</span>
              </h1>
              {/* Step 11 */}
              <div className="step_progress_bar mt-60">
                <ul>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="active-step">11</li>
                  <li>12</li>
                  <li>13</li>
                </ul>
              </div>
              <div className="text-box mt-60">
                <p>
                  It is now time for us to take a look at your monthly expenses.
                  Please complete this exercise by estimating the actual or
                  average amounts of each category. If payments are made
                  biweekly, quarterly, or annually, please adjust the amount to
                  reflect monthly payment. In order for this Budget Analysis to
                  work, it is important you accurately record each amount. We
                  value your privacy and will never share any of the financial
                  information you enter with anyone, so please provide the most
                  honest answers possible. If a category does not apply to you,
                  it is okay to leave it blank.
                </p>
              </div>
              <section className="manage-students box_s mt-60">
                <div className="card">
                  <div className="card-body">
                    <table className="table table-responsive">
                      <thead>
                        <tr>
                          <th scope="col">Type</th>
                          <th scope="col">Creditor</th>
                          <th scope="col">Balance</th>
                          <th scope="col">Interest</th>
                          <th scope="col">Payment</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {unDebts &&
                          unDebts.length > 0 &&
                          unDebts.map((debts, index) => (
                            <tr key={index}>
                              <td>{debts.type}</td>
                              <td>{debts.creditor}</td>
                              <td>{debts.balance}</td>
                              <td>{debts.interest}</td>
                              <td>{debts.payment}</td>
                              <td>
                                <div className="action-btns">
                                  <button
                                    className="btn-delete"
                                    onClick={(event) =>
                                      handleDelete(index, event)
                                    }
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
              <div className="head mt-60">
                <h1 className="f18">UnSecured Debt</h1>
              </div>

              {values.unsecureDebt &&
                values.unsecureDebt.length > 0 &&
                values.unsecureDebt.map((debt, index) => (
                  <div key={index}>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="form-group mt-40">
                          <label
                            className="label-title"
                            htmlFor="unsecuredLoanType"
                          >
                            Type of Unsecured Loan
                          </label>
                          <Field
                            as="select"
                            id="unsecuredLoanType"
                            name={`unsecureDebt[${index}].type`}
                            className="form-control custom_select"
                            required
                          >
                            <option value="">Select</option>
                            <option value="Bank Credit Card">
                              Bank Credit Card
                            </option>
                            <option value="Store Credit Card">
                              Store Credit Card
                            </option>
                            <option value="Personal Loan">Personal Loan</option>
                            <option value="Medical Bill">Medical Bill</option>
                            <option value="Student Loan">Student Loan</option>
                            <option value="Child/Spousal Support">
                              Child/Spousal Support
                            </option>
                            <option value="Taxes">Taxes</option>
                            <option value="Other Unsecured Debt">
                              Other UnSecured Debt
                            </option>
                          </Field>
                          <ErrorMessage
                            name={`unsecureDebt[${index}].type`}
                            component="div"
                            className="error-message text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group mt-40">
                          <label className="label-title" htmlFor="creditorName">
                            Creditor Name
                          </label>
                          <Field
                            type="text"
                            id="creditorName"
                            name={`unsecureDebt[${index}].creditor`}
                            className="form-control"
                            required
                          />
                          <ErrorMessage
                            name={`unsecureDebt[${index}].creditor`}
                            component="div"
                            className="error-message text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group mt-40">
                          <label className="label-title" htmlFor="balanceOwed1">
                            Balance Owed $
                          </label>
                          <Field
                            type="number"
                            id="balanceOwed1"
                            name={`unsecureDebt[${index}].balance`}
                            className="form-control"
                            required
                          />
                          <ErrorMessage
                            name={`unsecureDebt[${index}].balance`}
                            component="div"
                            className="error-message text-danger"
                          />
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-group mt-40">
                          <label
                            className="label-title"
                            htmlFor="annualInterestRate1"
                          >
                            Annual Interest Rate APR (typically 15-25%)
                          </label>
                          <Field
                            as="select"
                            id="annualInterestRate1"
                            name={`unsecureDebt[${index}].interest`}
                            className="form-control custom_select"
                            required
                          >
                            <option value="">Select</option>
                            {options.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name={`unsecureDebt[${index}].interest`}
                            component="div"
                            className="error-message text-danger"
                          />
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-group mt-40">
                          <label
                            className="label-title"
                            htmlFor="monthlyPayment1"
                          >
                            Monthly Payment $
                          </label>
                          <Field
                            type="number"
                            id="monthlyPayment1"
                            name={`unsecureDebt[${index}].payment`}
                            className="form-control"
                            required
                          />
                          <ErrorMessage
                            name={`unsecureDebt[${index}].payment`}
                            component="div"
                            className="error-message text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group mt-40 data-button">
                          <button
                            type="submit"
                            className="btn_brnd"
                            disabled={postLoading}
                          >
                            {postLoading ? (
                              <ThreeDots
                                height="20"
                                width="140"
                                radius="9"
                                color="#4fa94d"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{
                                  display: "block",
                                }}
                                wrapperClassName="spinner"
                                visible={true}
                              />
                            ) : (
                              "Save and Add New"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {/*////////////// Buttons for next, previous and continue ///////////// */}
              <div className="text-center mt-60">
                <button
                  type="button"
                  onClick={() =>
                    props.prev({
                      ...props.data,
                      unsecureDebt: [...unDebts],
                    })
                  }
                  className="text_success mt-4 me-3"
                  disabled={postLoading}
                >
                  Previous
                </button>
                {/* <button
                                type="button"
                                onClick={() => resetForm()}
                                className="btn_brnd-outline mt-4 me-3"
                            >
                                Return To This Client Account
                            </button> */}
                <button
                  type="button"
                  onClick={() => {
                    submitData(
                      {
                        ...props.data,
                        unsecureDebt: [...unDebts],
                      },
                      props,
                      false,
                      setPostLoading,
                      navigate
                    );
                  }}
                  className="btn_brnd mt-4 me-3"
                  disabled={postLoading}
                >
                  {postLoading ? (
                    <ThreeDots
                      height="20"
                      width="140"
                      radius="9"
                      color="#4fa94d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{
                        display: "block",
                      }}
                      wrapperClassName="spinner"
                      visible={true}
                    />
                  ) : (
                    "Save & Continue"
                  )}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    const combinedValues = {
                      unsecureDebt: unDebts,
                    };

                    props.next(combinedValues);
                  }}
                  className="text_success mt-4 me-3"
                  disabled={postLoading}
                >
                  Next
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

/////////////////  Step 12 /////////////////////////
const Step12 = (props) => {
  const [postLoading, setPostLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (values) => {
    submitData(values, props, false, setPostLoading, navigate);
  };

  return (
    <div>
      <Formik
        initialValues={props.data}
        // validationSchema={validationSchema}={ // validationSchema={validationSchema}}
        onSubmit={handleSubmit}
      >
        {({ resetForm, submitForm, values }) => (
          <Form>
            <div className="Budget_Analysis mt-60">
              <h1 className="title">
                Budget <span className="text_success">Analysis</span>
              </h1>
              {/* Step 12 */}
              <div className="step_progress_bar mt-60">
                <ul>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="active-step">12</li>
                  <li>13</li>
                </ul>
              </div>
              <div className="text-box mt-60">
                <p>
                  Take a moment to think about the value of things you have. An
                  asset is anything that has value now and in the future. The
                  value of an item is how much it would sell for in the current
                  market, how much it appraises for, or what its face value is.
                  Please enter your best estimates for the value of your assets
                  below.
                </p>
              </div>
              <div className=" head  mt-60">
                <h1 className="f18">Value</h1>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="homeValue">
                      Home
                    </label>
                    <Field
                      type="number"
                      id="homeValue"
                      name="netWorth.home"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="netWorth.home"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="otherHomesValue">
                      Other Homes/Real Estate
                    </label>
                    <Field
                      type="number"
                      id="otherHomesValue"
                      name="netWorth.other_home"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="netWorth.other_home"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="carsValue">
                      Cars and Vehicles
                    </label>
                    <Field
                      type="number"
                      id="carsValue"
                      name="netWorth.car"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="netWorth.car"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="jewelryValue">
                      Jewelry
                    </label>
                    <Field
                      type="number"
                      id="jewelryValue"
                      name="netWorth.jewelry"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="netWorth.jewelry"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label
                      className="label-title"
                      htmlFor="householdItemsValue"
                    >
                      Household Items
                    </label>
                    <Field
                      type="number"
                      id="householdItemsValue"
                      name="netWorth.household_items"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="netWorth.household_items"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label
                      className="label-title"
                      htmlFor="retirementAccountsValue"
                    >
                      Retirement Accounts
                    </label>
                    <Field
                      type="number"
                      id="retirementAccountsValue"
                      name="netWorth.retirement_accounts"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="netWorth.retirement_accounts"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="stocksValue">
                      Stocks
                    </label>
                    <Field
                      type="number"
                      id="stocksValue"
                      name="netWorth.stocks"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="netWorth.stocks"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="bondsValue">
                      Bonds
                    </label>
                    <Field
                      type="number"
                      id="bondsValue"
                      name="netWorth.bonds"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="netWorth.bonds"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="insuranceValue">
                      Face Value of Insurance
                    </label>
                    <Field
                      type="number"
                      id="insuranceValue"
                      name="netWorth.insurance"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="netWorth.insurance"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="mutualFundsValue">
                      Mutual Funds
                    </label>
                    <Field
                      type="number"
                      id="mutualFundsValue"
                      name="netWorth.mutual_funds"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="netWorth.mutual_funds"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label
                      className="label-title"
                      htmlFor="checkingSavingsValue"
                    >
                      Checking and Savings
                    </label>
                    <Field
                      type="number"
                      id="checkingSavingsValue"
                      name="netWorth.checking_savings"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="netWorth.checking_savings"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="cashValue">
                      Cash
                    </label>
                    <Field
                      type="number"
                      id="cashValue"
                      name="netWorth.cash"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="netWorth.cash"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="othersValue">
                      Others
                    </label>
                    <Field
                      type="number"
                      id="othersValue"
                      name="netWorth.other"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="netWorth.other"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
              </div>
              {/*////////////// Buttons for next, previous and continue ///////////// */}
              <div className="text-center mt-60">
                <button
                  type="button"
                  onClick={() => props.prev(values)}
                  className="text_success mt-4 me-3"
                  disabled={postLoading}
                >
                  Previous
                </button>
                {/* <button
                                type="button"
                                onClick={() => resetForm()}
                                className="btn_brnd-outline mt-4 me-3"
                            >
                                Return To This Client Account
                            </button> */}
                <button
                  type="submit"
                  className="btn_brnd mt-4 me-3"
                  disabled={postLoading}
                >
                  {postLoading ? (
                    <ThreeDots
                      height="20"
                      width="140"
                      radius="9"
                      color="#4fa94d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{
                        display: "block",
                      }}
                      wrapperClassName="spinner"
                      visible={true}
                    />
                  ) : (
                    "Save & Continue"
                  )}
                </button>
                <button
                  onClick={() => {
                    props.next(values);
                  }}
                  className="text_success mt-4 me-3"
                  disabled={postLoading}
                >
                  Next
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

/////////////////  Step 13 /////////////////////////
const Step13 = (props) => {
  const location = useLocation();
  const [postLoading, setPostLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (values) => {
    submitData(values, props, true, setPostLoading, navigate, false, location);
  };
  return (
    <div>
      <Formik
        initialValues={props.data}
        // validationSchema={validationSchema}={ // validationSchema={validationSchema}}
        onSubmit={handleSubmit}
      >
        {({ resetForm, submitForm, values }) => (
          <Form>
            <div className="Budget_Analysis mt-60">
              <h1 className="title">
                Budget <span className="text_success">Analysis</span>
              </h1>
              {/* Step 13 */}
              <div className="step_progress_bar mt-60">
                <ul>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="complete_step bg_success">
                    <img src={tick_white} alt="tick_white" />
                  </li>
                  <li className="active-step">13</li>
                </ul>
              </div>
              <div className="text-box mt-60">
                <p>
                  Take a moment to think about the value of things you have. An
                  asset is anything that has value now and in the future. The
                  value of an item is how much it would sell for in the current
                  market, how much it appraises for, or what its face value is.
                  Please enter your best estimates for the value of your assets
                  below.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="mortgagesValue">
                      Mortgages
                    </label>
                    <Field
                      type="number"
                      id="mortgagesValue"
                      name="netWorth.mortgage"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="netWorth.mortgage"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="vehicleLoansValue">
                      Car and Vehicle Loans
                    </label>
                    <Field
                      type="number"
                      id="vehicleLoansValue"
                      name="netWorth.auto_loans"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="netWorth.auto_loans"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="studentLoansValue">
                      Student Loans
                    </label>
                    <Field
                      type="number"
                      id="studentLoansValue"
                      name="netWorth.student_loans"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="netWorth.student_loans"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group mt-40">
                    <label className="label-title" htmlFor="otherLoansValue">
                      Other Loans
                    </label>
                    <Field
                      type="number"
                      id="otherLoansValue"
                      name="netWorth.other_loans"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="netWorth.other_loans"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                </div>
              </div>
              {/*////////////// Buttons for next, previous and continue ///////////// */}
              <div className="text-center mt-60">
                <button
                  type="button"
                  onClick={() => props.prev(values)}
                  className="text_success mt-4 me-3"
                  disabled={postLoading}
                >
                  Previous
                </button>
                <button
                  type="submit"
                  disabled={postLoading}
                  className="btn_brnd-outline mt-4 me-3"
                >
                  {postLoading ? (
                    <ThreeDots
                      height="20"
                      width="125"
                      radius="9"
                      color="#4fa94d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{
                        display: "block",
                      }}
                      wrapperClassName="spinner"
                      visible={true}
                    />
                  ) : (
                    "View Summary"
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

////////////////////// Main Component//////////////////////////////
const AddInfoMultistep = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");
  const [getloading, setGetLoading] = useState(true);

  //////////////// for step 10 and 11////////////////
  const [formData, setFormData] = useState({
    secureDebt: [
      {
        ///////////////////// step 10 initail values/////////////////////////
        type: "",
        creditor: "",
        balance: 0,
        equity: 0,
        interest: 0,
        payment: 0,
        firstmort_taxes: 0,
        firstmort_homeins: 0,
      },
    ],
    ///////////////////// step 11 initail values/////////////////////////
    unsecureDebt: [
      {
        type: "",
        creditor: "",
        balance: 0,
        interest: 0,
        payment: 0,
      },
    ],
  });

  ///////////////// Handle all the data/////////////////
  const [data, setData] = useState({
    budgetAnalysis: {
      ///////////////////// step 1 initail values/////////////////////////
      household_size: 0,
      sp1_grossincome: 0,
      main_work_status: 0,
      sp1_netincome: 0,
      main_age: 0,
      sp2_grossincome: 0,
      joint_work_status: 0,
      sp2_netincome: 0,
      joint_age: 0,
      alimony: 0,
      childsupport: 0,
      miscincome: 0,
      ///////////////////// step 2 initail values/////////////////////////

      housing_rent: 0,
      housing_taxes: 0,
      housing_propins: 0,
      housing_mortins: 0,
      housing_maintenance: 0,
      ///////////////////// step 3 initail values/////////////////////////
      utilities_phone: 0,
      utilities_internet: 0,
      utilities_television: 0,
      utilities_electricity: 0,
      utilities_gas: 0,
      utilities_water: 0,
      utilities_garbage: 0,
      utilities_other: 0,
      ///////////////////// step 4 initail values/////////////////////////
      food_groceries: 0,
      food_fastfood: 0,
      food_restaurant: 0,
      food_coffee: 0,
      food_alcohol: 0,
      food_pet: 0,
      food_other: 0,
      ///////////////////// step 5 initail values/////////////////////////
      trans_insurance: 0,
      trans_gas: 0,
      trans_parking: 0,
      trans_public: 0,
      trans_other: 0,
      ///////////////////// step 6 initail values/////////////////////////
      misc_phone: 0,
      misc_lifeins: 0,
      misc_hair: 0,
      misc_laundry: 0,
      misc_spa: 0,
      misc_clothes: 0,
      misc_books: 0,
      misc_electronics: 0,
      misc_hobbies: 0,
      misc_childsupport: 0,
      misc_alimony: 0,
      misc_other: 0,
      ///////////////////// step 7 initail values/////////////////////////
      rec_movie: 0,
      rec_dvdrental: 0,
      rec_amusement: 0,
      rec_music: 0,
      rec_newspaper: 0,
      rec_other: 0,
      ///////////////////// step 8 initail values/////////////////////////
      gifts_gifts: 0,
      gifts_donations: 0,
      gifts_tithes: 0,
      ///////////////////// step 9 initail values/////////////////////////
      health_ins: 0,
      health_doctor: 0,
      health_dentist: 0,
      health_eyedoctor: 0,
      health_pharmacy: 0,
      health_gym: 0,
      health_other: 0,
    },
    secureDebt: [],

    ///////////////////// step 11 initail values/////////////////////////
    unsecureDebt: [],
    netWorth: {
      ///////////////////// step 12 initail values/////////////////////////
      home: 0,
      other_home: 0,
      car: 0,
      jewelry: 0,
      household_items: 0,
      retirement_accounts: 0,
      stocks: 0,
      bonds: 0,
      insurance: 0,
      mutual_funds: 0,
      checking_savings: 0,
      cash: 0,
      other: 0,

      ///////////////////// step 13 initail values/////////////////////////
      mortgage: 0,
      auto_loans: 0,
      student_loans: 0,
      other_loans: 0,
    },
  });

  ////////////////// Handle all the steps/////////////////
  const [currentStep, setCurrentStep] = useState(0);

  // handle the fetch data for update the form
  const user = useSelector((state) => state.user);
  const [apiDataAvailable, setApiDataAvailable] = useState(false);
  useEffect(() => {
    getBudgetAnalysis(
      userId ? userId : user.user.data.id,
      userId ? "admin" : "user"
    )
      .then((res) => {
        const responseData = res.data.data;
        setGetLoading(false);
        if (responseData) {
          setApiDataAvailable(true);
          setData(responseData);
        } else {
          setApiDataAvailable(false);
        }
      })
      .catch((error) => {
        setGetLoading(false);
        // console.error('Error while fetching budget analysis data:', error);
      });
  }, []);

  ///////////////////// for all other steps///////////////////////
  const handleNextStep = (newData) => {
    console.log(newData);
    console.log({ ...data, ...newData });
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev + 1);
  };

  const handleprevStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };

  ////////////////////////// stepper//////////////////////
  const steps = [
    <Step1 next={handleNextStep} data={data} userId={userId} user={user} />,
    <Step2
      next={handleNextStep}
      data={data}
      prev={handleprevStep}
      userId={userId}
      user={user}
    />,
    <Step3
      next={handleNextStep}
      data={data}
      prev={handleprevStep}
      userId={userId}
      user={user}
    />,
    <Step4
      next={handleNextStep}
      data={data}
      prev={handleprevStep}
      userId={userId}
      user={user}
    />,
    <Step5
      next={handleNextStep}
      data={data}
      prev={handleprevStep}
      userId={userId}
      user={user}
    />,
    <Step6
      next={handleNextStep}
      data={data}
      prev={handleprevStep}
      userId={userId}
      user={user}
    />,
    <Step7
      next={handleNextStep}
      data={data}
      prev={handleprevStep}
      userId={userId}
      user={user}
    />,
    <Step8
      next={handleNextStep}
      data={data}
      prev={handleprevStep}
      userId={userId}
      user={user}
    />,
    <Step9
      next={handleNextStep}
      data={data}
      prev={handleprevStep}
      userId={userId}
      user={user}
    />,

    <Step10
      next={handleNextStep}
      data={data}
      prev={handleprevStep}
      formData={formData}
      userId={userId}
      user={user}
    />,
    <Step11
      next={handleNextStep}
      data={data}
      prev={handleprevStep}
      formData={formData}
      userId={userId}
      user={user}
    />,
    <Step12
      next={handleNextStep}
      data={data}
      prev={handleprevStep}
      userId={userId}
      user={user}
    />,
    <Step13
      next={handleNextStep}
      data={data}
      prev={handleprevStep}
      userId={userId}
      user={user}
    />,
  ];

  return (
    <div className="step_form">
      <Header />
      {getloading ? (
        <div style={{ marginTop: "150px" }}>
          <ThreeDots
            height="40"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{
              display: "flex",
              justifyContent: "center",
            }}
            wrapperClassName="spinner"
            visible={true}
          />
        </div>
      ) : (
        <div className="pt-4">
          {apiDataAvailable && user && steps[currentStep]}
        </div>
      )}
    </div>
  );
};

export default AddInfoMultistep;
