import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { courseUpdate, howDidHappen } from "../../api/server";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import Header from "../../layouts/header";
import { toast } from "react-toastify";

const HowDidThisHappen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");
  const retake = Boolean(
    queryParams.get("retake") !== null &&
      queryParams.get("retake") != "null" &&
      queryParams.get("retake") != "false" &&
      queryParams.get("retake") !== false
  );
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const isJoint = localStorage.getItem("isJoint");

  const handleSubmit = async (values) => {
    setLoading(true);

    await howDidHappen(userId ? "admin" : "user", values)
      .then((res) => {
        const updatedCourseInfo = { video: `5` };
        courseUpdate(
          userId ? "admin" : "user",
          userId ? userId : user.user.data.id,
          updatedCourseInfo,
          isJoint == "true" ? "true" : "false",
          retake ? 1 : 0
        )
          .then((res) => {
            setLoading(false);
            if (userId) {
              navigate(`/user-first-course?id=${userId}&retake=${retake}`);
            } else {
              navigate(`/course?retake=${retake}`);
            }
          })
          .catch((err) => {
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <div className="step_form w-75 mx-auto">
      <Header />
      <div className="pt-4 mt-60">
        <h4 className="f-26 mb-4">Pre-Filing Credit Counseling </h4>
        <div className="head">
          <h1 className="f18">How'd this happen</h1>
        </div>
        <p class="f-16 mt-4">
          All of these conditions and circumstances can place considerable
          pressure on yourself and your family, and the effects are not only
          seen emotionally, but financially as well.
        </p>
        <Formik
          initialValues={{
            userid: user.user.data.id,
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: "",
            q6: "",
            q7: "",
            q8: "",
            q9: "",
            q10: "",
            q11: "",
            q12: "",
          }}
          onSubmit={handleSubmit}
        >
          {({ values, setValues, handleChange, errors }) => (
            <Form>
              <div className="mt-4">
                <div className="radio_wrap_question">
                  <div className="iagree_radio mb-20">
                    <input
                      type="radio"
                      name="q1"
                      id="q1"
                      checked={values.q1 === "Checked"}
                      onClick={() => {
                        setValues({
                          ...values,
                          q1: values.q1 === "Checked" ? "" : "Checked",
                        });
                      }}
                      className=" "
                    />
                    <label htmlFor="q1">Illness or injury</label>
                  </div>
                  <div className="iagree_radio mb-20">
                    <input
                      type="radio"
                      name="q2"
                      id="q2"
                      checked={values.q2 === "Checked"}
                      onClick={() => {
                        setValues({
                          ...values,
                          q2: values.q2 === "Checked" ? "" : "Checked",
                        });
                      }}
                      className=" "
                    />
                    <label htmlFor="q2">Separation or Divorce</label>
                  </div>
                  <div className="iagree_radio mb-20">
                    <input
                      type="radio"
                      name="q3"
                      id="q3"
                      checked={values.q3 === "Checked"}
                      onClick={() => {
                        setValues({
                          ...values,
                          q3: values.q3 === "Checked" ? "" : "Checked",
                        });
                      }}
                      className=" "
                    />
                    <label htmlFor="q3">Death of Spouse</label>
                  </div>
                  <div className="iagree_radio mb-20">
                    <input
                      type="radio"
                      name="q4"
                      id="q4"
                      checked={values.q4 === "Checked"}
                      onClick={() => {
                        setValues({
                          ...values,
                          q4: values.q4 === "Checked" ? "" : "Checked",
                        });
                      }}
                      className=" "
                    />
                    <label htmlFor="q4">Unemployment or Underemployment</label>
                  </div>
                  <div className="iagree_radio mb-20">
                    <input
                      type="radio"
                      name="q5"
                      id="q5"
                      checked={values.q5 === "Checked"}
                      onClick={() => {
                        setValues({
                          ...values,
                          q5: values.q5 === "Checked" ? "" : "Checked",
                        });
                      }}
                      className=" "
                    />
                    <label htmlFor="q5">Business loss or failure</label>
                  </div>
                  <div className="iagree_radio mb-20">
                    <input
                      type="radio"
                      name="q6"
                      id="q6"
                      checked={values.q6 === "Checked"}
                      onClick={() => {
                        setValues({
                          ...values,
                          q6: values.q6 === "Checked" ? "" : "Checked",
                        });
                      }}
                      className=" "
                    />
                    <label htmlFor="q6">Gambling</label>
                  </div>
                  <div className="iagree_radio mb-20">
                    <input
                      type="radio"
                      name="q7"
                      id="q7"
                      checked={values.q7 === "Checked"}
                      onClick={() => {
                        setValues({
                          ...values,
                          q7: values.q7 === "Checked" ? "" : "Checked",
                        });
                      }}
                      className=" "
                    />
                    <label htmlFor="q7">Other Addiction</label>
                  </div>
                  <div className="iagree_radio mb-20">
                    <input
                      type="radio"
                      name="q8"
                      id="q8"
                      checked={values.q8 === "Checked"}
                      onClick={() => {
                        setValues({
                          ...values,
                          q8: values.q8 === "Checked" ? "" : "Checked",
                        });
                      }}
                      className=" "
                    />
                    <label htmlFor="q8">Unnecessary Purchases</label>
                  </div>
                  <div className="iagree_radio mb-20">
                    <input
                      type="radio"
                      name="q9"
                      id="q9"
                      checked={values.q9 === "Checked"}
                      onClick={() => {
                        setValues({
                          ...values,
                          q9: values.q9 === "Checked" ? "" : "Checked",
                        });
                      }}
                      className=" "
                    />
                    <label htmlFor="q9">Credit Card Spending</label>
                  </div>
                  <div className="iagree_radio mb-20">
                    <input
                      type="radio"
                      name="q10"
                      id="q10"
                      checked={values.q10 === "Checked"}
                      onClick={() => {
                        setValues({
                          ...values,
                          q10: values.q10 === "Checked" ? "" : "Checked",
                        });
                      }}
                      className=" "
                    />
                    <label htmlFor="q10">
                      The purchase of a car we couldn't afford
                    </label>
                  </div>
                  <div className="iagree_radio mb-20">
                    <input
                      type="radio"
                      name="q11"
                      id="q11"
                      checked={values.q11 === "Checked"}
                      onClick={() => {
                        setValues({
                          ...values,
                          q11: values.q11 === "Checked" ? "" : "Checked",
                        });
                      }}
                      className=" "
                    />
                    <label htmlFor="q11">
                      The purchase of a house we couldn't afford.
                    </label>
                  </div>
                  <div className="form-group Feedback_input mt-40">
                    <p class="f-16">
                      Please list any additional conditions that contributed to
                      your situation:
                    </p>
                    <textarea
                      className="form-control"
                      name="q12"
                      rows="6"
                      values={values.q12}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          q12: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="btn_wrap mt-20 text-center">
                  <button
                    disabled={loading}
                    className="btn_brnd"
                    type="submit"
                    onClick={() => {
                      if (Object.keys(errors).length > 0) {
                        toast.error("Form validation failed");
                      }
                    }}
                  >
                    {loading ? (
                      <ThreeDots
                        height="20"
                        width="75"
                        radius="9"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          display: "block",
                        }}
                        wrapperClassName="spinner"
                        visible={true}
                      />
                    ) : (
                      "Continue"
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default HowDidThisHappen;
