import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import { useSelector } from "react-redux";
import { useState } from "react";

const BudgetAnalysisDebts = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");
  const retake = Boolean(
    queryParams.get("retake") !== null &&
      queryParams.get("retake") != "null" &&
      queryParams.get("retake") != "false" &&
      queryParams.get("retake") !== false
  );
  const report = useSelector((state) => state.user.report);
  const [calc, setCal] = useState();
  const [total, setTotal] = useState(0);

  return (
    <div>
      <div className="wrapper">
        <DashBoardSidebar />
        {/* <!-- Page Content Holder --> */}
        <div id="content">
          <DashboardNavbar />
          <h1 className="card-title mb-4">Budget Analysis</h1>
          <section className="breadcrumb-section">
            <nav
              style={{ "--bs-breadcrumb-divider": " '>';" }}
              aria-label="breadcrumb"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Clients Budget Analysis</a>
                </li>
                <li className="breadcrumb-item">Income</li>
                <li className="breadcrumb-item">Expenses</li>
                <li className="breadcrumb-item " aria-current="page">
                  Debts
                </li>
              </ol>
            </nav>
          </section>
          <section className="budget-analysis">
            <div className="card main-card">
              <div className="card-body ">
                <div className="row align-items-center">
                  <div className="col-lg-9">
                    <h6>Paying off your debts.</h6>
                    <p className="my-0">
                      The downside of debt is that it ties up your money so you
                      can't spend or save that money as you wish. It also costs
                      a lot money to borrow money, so plan on paying your debts
                      off as soon as you can.
                    </p>
                    <p className="my-0">
                      One key measurement of your financial health is the
                      debt-to-income ratio or DTI.
                    </p>
                    <p className="my-0">
                      The DTI shows what portion of your gross income goes to
                      pay for:
                    </p>
                    <h6 className="my-0">Housing costs + Debt Payments</h6>
                    <p className="my-0">
                      You should keep at least 2/3 of your gross income free to
                      have the flexibility to cover your monthly expenses.
                      Lending guidelines state that you shouldn't exceed 28% of
                      your gross income for housing and 36% of your gross income
                      for housing plus other debt payments.
                    </p>
                    <p className="my-0">
                      Below is a breakdown of your current monthly payments.
                    </p>
                    <p className="my-0">
                      Don't despair! There are hundreds of solutions to any one
                      problem. We will present all of the major issues with your
                      current financial outlook such as your debt-to-income
                      ratio and spending patterns. We will also offer specific
                      Evergreen Suggestions tailored to your unique situation.
                    </p>
                  </div>
                  <div className="col-lg-3 text-lg-end">
                    <button className="grade-btn btn_brnd mb-2">
                      <span>
                        {report.Tab4.debtSecFirstData?.debtToIncome?.toFixed(2)}
                        %
                      </span>
                      Your Debt-to-Income ratio.
                    </button>
                    <button className="grade-btn btn_brnd mb-2">
                      <span>
                        {report.Tab4.debtSecFirstData?.housingToIncome?.toFixed(
                          2
                        )}
                        %
                      </span>
                      Percent of your income just for housing.
                    </button>
                    <button className="grade-btn btn_brnd mb-2">
                      <span>
                        {report.Tab4.debtSecFirstData?.percentPayment?.toFixed(
                          2
                        )}
                        %
                      </span>
                      Percent of your income just for debt.
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card main-card">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Creditor</th>
                    <th scope="col">Balance</th>
                    <th scope="col">Payments</th>
                    <th scope="col">Interest Expense</th>
                  </tr>
                </thead>
                <tbody>
                  {report.Tab4.debtSecSecondData.unsecureDebt &&
                    report.Tab4.debtSecSecondData.unsecureDebt.length > 0 &&
                    report.Tab4.debtSecSecondData.unsecureDebt.map(
                      (item, index) => (
                        <tr>
                          <td>{item.creditor}</td>
                          <td>
                            ${item.balance} @ {item.interest}% APR
                          </td>
                          <td>
                            ${item.payment} for{" "}
                            {(
                              report.Tab4.debtSecSecondData?.yearsLeft * 12
                            )?.toFixed(1)}{" "}
                            months
                          </td>
                          <td>
                            $
                            {report.Tab4.debtSecSecondData?.interestPaidArray[
                              index
                            ]?.toFixed(2)}
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
              <div className="card-body text-center">
                <h6>Debt Summary</h6>
                <p className="mb-0">
                  Your current monthly payments are $
                  {report.Tab4.debtSecSecondData.monthlyPayments}
                </p>
                <p className="mb-0">
                  The amount of interest you'll pay for these loans is $
                  {report.Tab4.debtSecSecondData?.interestTotal?.toFixed(2)}
                </p>
                <p>
                  You'll finish paying off these debts in{" "}
                  {report.Tab4.debtSecSecondData?.yearsLeft?.toFixed(1)} years
                </p>
              </div>
            </div>
            <div className="card main-card">
              <div className="card-body ">
                <h6 className=" ">Paying off your debt faster.</h6>
                <p>
                  The best way to pay off debt is to list the debts in the order
                  you want to pay them off, then as you pay off each debt, you
                  apply the payment forward to the next debt, and so on.
                </p>
                <p>
                  The most often recommended strategy is to payoff your smallest
                  debt first. This is referred to as a 'debt snowball' because
                  you continue to gain momentum as you progress. While you could
                  focus on debts with the highest interest rates, there's
                  nothing that compares to the feeling of accomplishment each
                  time you pay off a debt..
                </p>
              </div>
              <hr />
              <div className="row ">
                <div className="col-lg-8 bordr-right">
                  <div className="p-lg-4 p-2">
                    <div className="row mb-4">
                      <div className="col-lg-5">
                        <div className="d-flex align-items-center">
                          <h6 className="text_success me-2 my-0">$</h6>
                          <input
                            type="number"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={(e) => {
                              setCal(e.target.value);
                            }}
                            value={calc}
                          />
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <p>Add more money each month to pay down faster?</p>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-lg-5">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Smallest debit first</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                      <div className="col-lg-7">
                        <p>What order would you like to pay off your debts?</p>
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        className="btn_brnd"
                        onClick={() => {
                          setTotal(calc);
                        }}
                      >
                        Update Payoff Schedule
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="p-lg-4 p-2">
                    <h6>Paying off your debt faster.</h6>
                    <p>
                      This is what it would look like if you continued to pay $
                      {report.Tab4.debtSecSecondData.monthlyPayments} every
                      month until your debts are eliminated.
                    </p>
                    <p>
                      You can also see the effect of adding more money each
                      month by entering an amount to the left.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card main-card">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Months</th>
                    <th scope="col">Credit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Months{" "}
                      {(report.Tab4.debtSecSecondData?.yearsLeft * 12)?.toFixed(
                        1
                      )}
                    </td>
                    <td>
                      $
                      {Number(report.Tab4.debtSecSecondData.monthlyPayments) +
                        Number(total)}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="card-body text-center">
                <h6>Debt Summary</h6>
                <p className="mb-0">
                  You paid this amount during the debt pay down each month.$
                  {Number(report.Tab4.debtSecSecondData.monthlyPayments) +
                    Number(total)}
                </p>
                <p className="mb-0">
                  The amount of interest you'll pay for these loans is $45,131
                </p>
                <p className="mb-0">
                  how much you saved in interest costs is $-66
                </p>
                <p>
                  You'll finish paying off these debts in 0 months rather than
                  288
                </p>
              </div>
            </div>
            <div className="text-center my-4">
              <Link
                to={`/expenses-analysis?id=${userId}&retake=${retake}`}
                className="text_success mt-4 me-3"
              >
                <u>Previous</u>
              </Link>
              <Link
                to={`/addinfomultistepuser?id=${userId}&retake=${retake}`}
                className="btn_brnd mt-4 me-3"
              >
                Add More Info to My Budget
              </Link>
              <Link to={`/user-first-course?id=${userId}&retake=${retake}`}>
                <button className="btn_brnd mt-4 me-3">Continue Course</button>
              </Link>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default BudgetAnalysisDebts;
