import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import { useSelector } from "react-redux";

const BudgetAnalysisIncome = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");
  const retake = Boolean(
    queryParams.get("retake") !== null &&
      queryParams.get("retake") != "null" &&
      queryParams.get("retake") != "false" &&
      queryParams.get("retake") !== false
  );
  const report = useSelector((state) => state.user.report);

  return (
    <div>
      <div className="wrapper">
        <DashBoardSidebar />
        {/* <!-- Page Content Holder --> */}
        <div id="content">
          <DashboardNavbar />
          <h1 className="card-title mb-4">Budget Analysis</h1>
          <section className="breadcrumb-section">
            <nav
              style={{ "--bs-breadcrumb-divider": " '>';" }}
              aria-label="breadcrumb"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Clients Budget Analysis</a>
                </li>
                <li className="breadcrumb-item " aria-current="page">
                  Income
                </li>
              </ol>
            </nav>
          </section>
          <section className="budget-analysis">
            <div className="card main-card">
              <div className="card-body ">
                <div className="row align-items-center">
                  <div className="col-lg-9">
                    <h6>Earning more than you spend.</h6>
                    <p>
                      While money can't buy happiness, it can provide you with
                      more options and flexibility. Despite what many believe,
                      your income level is not cast in stone. When looking at
                      your financial health, determining your income needs is
                      just as important as scrutinizing your spending.
                    </p>
                    <p>
                      If you live modestly where the cost of living is low, you
                      should be able to cover your basic expenses. With a
                      spending plan and a little discipline, you should be able
                      to stay out of debt, save a little for emergencies, and
                      have a little extra to spend as you like.
                    </p>
                  </div>
                  <div className="col-lg-3 text-lg-end">
                    <button className="grade-btn btn_brnd mb-2">
                      <span>${report.Tab2.Gross}</span>
                      Your monthly gross income
                    </button>
                    <button className="grade-btn btn_brnd mb-2">
                      <span>-${report.Tab2.Deductions}</span>
                      Amount withheld
                    </button>
                    <button className="grade-btn btn_brnd mb-2">
                      <span>${report.Tab2.Netincome}</span>
                      Your monthly net income
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card main-card">
              <div className="card-body p-0">
                <div className="row ">
                  <div className="col-lg-6 bordr-right">
                    <div className="p-lg-4 p-2">
                      <h6 className="text_danger">Your Location</h6>
                      <p>
                        State and county where you live can determine a lot
                        about your income and your expenses. An ideal place to
                        live is one where jobs in your field are readily
                        available and the cost of living is low.
                      </p>
                      <p>
                        Your location helps to define your financial outlook.
                        For example, a household income of $40,000/year in the
                        Mid-West and South may grant more freedom than
                        $80,000/year in Los Angeles. Additionally, individual
                        state laws may allow you to keep more of what you earn
                        or even give you money, as they do in Alaska.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="p-lg-4 p-2">
                      <h6 className="text_success">Evergreen Suggestions</h6>
                      <p>
                        Evaluate why you are living in your current location. If
                        your location is adding a financial hardship possibly
                        remedied by relocating for better opportunities
                        elsewhere, decide if it's worth it to move.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card main-card">
              <div className="card-body p-0">
                <div className="row ">
                  <div className="col-lg-6 bordr-right">
                    <div className="p-lg-4 p-2">
                      <h6 className="text_danger">Starting a side business.</h6>
                      <p>
                        Starting a side business is a fairly simple way to
                        provide additional income. Many small businesses are
                        successful with very little investment. With the time
                        you have available, a{" "}
                      </p>
                      <p>
                        small business might be sustainable. Many small
                        businesses focus of providing needed services, such as
                        child care, home repairs/maintenance, window washing,
                        bookkeeping, house cleaning, etc.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="p-lg-4 p-2">
                      <h6 className="text_success">Evergreen Suggestions</h6>
                      <p>
                        If you could supplement your income by 10% or about $250
                        by working an additional few hours each week, this could
                        be a good option for you.
                      </p>
                      <h6 className="text_success">+$250</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center my-4">
              <Link
                to={`/budget-analysis-report?id=${userId}&retake=${retake}`}
                className="text_success mt-4 me-3"
              >
                <u>Previous</u>
              </Link>
              <Link
                to={`/expenses-analysis?id=${userId}&retake=${retake}`}
                className="btn_brnd-outline mt-4 me-3"
              >
                Next
              </Link>
              <Link
                to={`/addinfomultistepuser?id=${userId}&retake=${retake}`}
                className="btn_brnd mt-4 me-3"
              >
                Add More Info to My Budget
              </Link>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default BudgetAnalysisIncome;
