import React from "react";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import course3_info from "../../assets/images/course3_info.png";
import course3_info_author from "../../assets/images/course3_info_author.png";
import img_1 from "../../assets/images/720_1.png";
import img_2 from "../../assets/images/720_2.png";
import reviews from "../../assets/images/course3_reviews.png";
import course3About from "../../assets/images/course3_about.png";
import course3_reason1 from "../../assets/images/course3_reason1.png";
import course3_reason2 from "../../assets/images/course3_reason2.png";
import course3_reason3 from "../../assets/images/course3_reason3.png";
import course3_reason4 from "../../assets/images/course3_reason4.png";
import course3_reason5 from "../../assets/images/course3_reason5.png";
import course3_reason6 from "../../assets/images/course3_reason6.png";
import cross from "../../assets/images/icomoon-free_cross.svg";
import free from "../../assets/images/free.png";
import premium from "../../assets/images/premium.png";
import { Link } from "react-router-dom";
import profileImage from "../../assets/images/profile.png"

const Course3 = () => {

  return (
    <div className="course3">
      <Header />
      <div style={{ marginTop: "150px" }}></div>
      <section className="course3_info">
        <div className="theme_container">
          <h1 className="heading heading--720">
          About the 7 Steps to a 720 Credit Score
            <span style={{ color: "#4E9C4B" }}> BONUS COURSE </span>
          </h1>
          <br />
          <p className="info-subtext">
            Most people think it takes seven to ten years to improve poor credit. And that’s what most banks and lenders want you to think.
          </p>
          <br />
          <div className="row flex-i box720__row">
            <div className="col-sm-7">
              <p className="info-subtext  text-initial">
              After all, they make more money in the form of high interest payments off borrowers with bad credit scores.  
              </p>
              <p className="info-subtext  text-initial">
              But the truth is: You can have a great credit score (sometimes in under two years), even if you went  through a bankruptcy, a foreclosure, and a repossession yesterday.     
              </p>
              <p className="info-subtext  text-initial">
              Even if you have dozens of collection accounts on your credit report.
              </p>
              <p className="info-subtext  text-initial">
              7 Steps to a 720 Credit Score will show you how!
              </p>
            </div>
            <div className="col-sm-5">
              <div className="box720">
                <div className="box720__header">
                  <h3 className="box720__header-hdng">About 7 Steps to a 720 Credit Score</h3> 
                </div>
                <div className="box720__body">
                  <h3 className="box720__body_text">
                  This bonus self-directed course is available for free to students who have completed Course 2 through Evergreen Financial Counseling.
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="enrolled">
            <h3 className="enrolled__heading">When will I be enrolled?</h3>
            <p className="enrolled__para info-subtext">
            You will automatically be enrolled in 7 Steps to a 720 Credit Score  30 days after completing Course #2. <Link to={"/contactus"} className="enrolled__para__link parret"> Contact us </Link>
               if you do not receive an email  invitation within 30 days of completing Course #2.
            </p>
          </div>

          <div className="row  box720__row">
            <div className="col-sm-6">
              <h1 className="box720__row__sec__heading">Why do I have to wait 30 days?</h1>
              <p className="info-subtext  text-initial">
              For best results, you should start 7 Steps to a 720 Credit Score as  soon as your bankruptcy is discharged (Chapter 7) or confirmed (Chapter  13). Waiting 30 days gives your attorney and the bankruptcy courts time  to process your bankruptcy.
              </p>
            </div>
            <div className="col-sm-6">
              <div className="box720 box720--second">
                <div className="box720__header">
                  <h3 className="box720__header-hdng">In just one or two years, you could have a great credit score and:</h3> 
                </div>
                <div className="box720__body box720__body--720">
                  <ul className="box720__body_list">
                    <li>Qualify for the best credit cards with low interest rates and great rewards.</li>
                    <li>Save thousands of dollars on your car and home loans.</li>
                    <li>Refinance existing debt to lower your monthly payments.</li>
                    <li>Never again feel worried and ashamed when lenders, landlords, or employees pull your credit report.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="d-lg-flex justify-content-between ">
            {" "}
            <div className="info_content mb-5">
              <div className="position-relative">
                <img src={course3_info} alt="" />
                <div className="position-absolute info-text">
                  <p>The Credit Rebuilder Program</p>
                  <br />
                  <h1>Upgrade, and Unlock Better Credit Today</h1>
                </div>
              </div>
              <div className="MO d-lg-flex justify-content-between align-items-center">
                <h1 className="w-lg-50 me-4" style={{ fontStyle: "italic" }}>
                  So far, my credit score has increased at least 100 points; I
                  purchased a car, and I got three credit cards!
                </h1>
                <div
                  className="d-inline-flex align-items-center"
                  style={{ width: "50%" }}
                >
                  <img src={course3_info_author} alt="" />
                  <p className="ms-2 mb-0">
                    Barbara Bonney
                    <br />
                    Troy, MO
                  </p>
                </div>
              </div>
              <div>
                <p className="doing">
                  Without doing anything else, your credit score will
                  improve—perhaps as much as 140 points—simply by upgrading to
                  the Credit Rebuilder Program:
                </p>
                <div className="points140">
                  <h1>
                    <span>01</span>
                    We report your on-time payments to the three credit bureaus.
                  </h1>
                  <p className="ms-lg-5 mt-2">
                    Your payments for this program are reported as an
                    installment line of credit and have the same positive impact
                    on your credit score as on-time car payments.
                  </p>
                  <h1>
                    <span>02</span>
                    We will have errors removed from your credit report.
                  </h1>
                  <p className="ms-lg-5 mt-2">
                    Correcting errors will significantly increase your credit
                    score. And, better yet, if the creditors or credit bureaus
                    are violating your rights with respect to the errors on your
                    credit report, you might be eligible for financial
                    compensation, in which case we will provide free legal
                    support and fight for your rights
                  </p>
                </div>
                <div className="ms-lg-5 mt-5">
                  <a
                    className="upgrade_button d-block me-lg-5"
                    style={{ width: "224px !important" }}
                    target="_blank"
                    href="https://form.typeform.com/to/Uyms4Fwd?typeform-source=www.evergreenclass.com"
                  >
                    <button className="">Upgrade Now</button>
                  </a>
                </div>
              </div>
            </div>
            <div className="credit">
              <div className="unlock_credit">Unlock Better Credit Today</div>
              <div className="credit_build">
                <p>
                  The Credit Rebuilder Program upgrade is designed for people
                  who want fast results.
                </p>
                <ul>
                  <li>Everyone is approved.  </li>
                  <li>Your on-time payments improve your credit score.  </li>
                  <li>We remove all errors from your credit report.</li>
                </ul>
                <p>Plus, you will receive:</p>
                <ul>
                  <li>Free FCRA legal counsel (when qualified)</li>
                  <li>Free debt analysis</li>
                  <li>Free access to our credit-education program</li>
                </ul>
              </div>
              <div className="d-flex justify-content-center">
                <a
                  className="upgrade_button"
                  target="_blank"
                  href="https://form.typeform.com/to/Uyms4Fwd?typeform-source=www.evergreenclass.com"
                >
                  <button className="">Upgrade Now</button>
                </a>
              </div>
              <div className="mt-4">
                <img src={reviews} alt="" />
              </div>
            </div>
          </div> */}
        </div>
      </section>
      {/* <section className="Plans mt-5 pt-5">
        <div className="theme_container">
          <div className="d-flex justify-content-center mb-5 pb-5">
            <div className="plan-wrapper">
              <div
                className="position-relative mbbbb"
                style={{ bottom: "100px", marginBottom: "-100px" }}
              >
                <img src={free} alt="" />
                <h1 className="position-absolute firstHeading">
                  7 Steps to a 720 <br />
                  Credit Score
                </h1>
              </div>
              <p className="dotted-bottor-bottom">Do it yourself.</p>
              <p className="dotted-bottor-bottom">
                Upon completion of Courses 1 and 2
              </p>
              <p
                className="dotted-bottor-bottom"
                style={{ padding: "30px 24px" }}
              >
                <img className="cross_plan" width="32px" src={cross} alt="" />
              </p>
              <p
                className="dotted-bottor-bottom"
                style={{ padding: "16px 24px" }}
              >
                <img className="cross_plan" width="32px" src={cross} alt="" />
              </p>
              <p
                className="dotted-bottor-bottom"
                style={{ padding: "16px 24px" }}
              >
                <img className="cross_plan" width="32px" src={cross} alt="" />
              </p>
              <p
                className="dotted-bottor-bottom"
                style={{ padding: "46px 24px" }}
              >
                One-time review of your credit report
              </p>
              <p
                className="dotted-bottor-bottom"
                style={{ padding: "17px 24px" }}
              >
                <img className="cross_plan" width="32px" src={cross} alt="" />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <div className="d-md-none">
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </p>
              <p
                className="dotted-bottor-bottom"
                style={{ padding: "8px 24px" }}
              >
                Enrollment into the DIY version of 7 Steps to a 720 Credit Score
              </p>
              <p
                className="dotted-bottor-bottom"
                style={{ padding: "20px 24px" }}
              >
                Support during monthly Q&A credit education sessions
              </p>
              <p className="dotted-bottor-bottom" style={{ border: "none" }}>
                Automatic enrollment 30 days after completing Course #2
              </p>
              <br />
              <br />
              <br />
              <div
                className="d-flex justify-content-center position-relative sneak-btn"
                style={{ top: "32px" }}
              >
                <button
                  className="upgrade_button"
                  style={{ background: "#4e9c4b", color: "white !important", padding: '15px' }}
                  onClick={() => {
                    const element = document.getElementById("course720to720");
                    element.scrollIntoView();
                  }}
                >
                  Take a Sneak Peak at 7 Steps
                  <br />to a 720 Credit Course
                </button>
              </div>
            </div>
            <div className="plan-wrapper plan-wrapper-responsive">
              <div
                className="position-relative mbbbb"
                style={{ bottom: "100px", marginBottom: "-100px" }}
              >
                <img src={premium} alt="" />
                <h1 className="position-absolute secondHeading">
                  Credit Rebuilder <br />
                  Program Upgrade.
                </h1>
              </div>

              <p className="dotted-bottor-bottom">We do it for you.</p>
              <p className="dotted-bottor-bottom">
                <a
                  target="_blank"
                  href="https://form.typeform.com/to/Uyms4Fwd?typeform-source=www.evergreenclass.com"
                  style={{ color: "#407BFF", textDecoration: "underline" }}
                >
                  {" "}
                  Start Today
                </a>
              </p>
              <p className="dotted-bottor-bottom">
                Your payments are reported as “in good standing” to the credit
                bureaus (a potential 80-point)
              </p>
              <p className="dotted-bottor-bottom">Free debt analysis</p>
              <p className="dotted-bottor-bottom">
                Involuntary unemployment protection
              </p>
              <p className="dotted-bottor-bottom">
                Unlimited Full Platinum Credit Report Review: We remove all
                errors from your credit report (a potential 60-point).  PLUS, we
                will assist you will future errors.
              </p>
              <p className="dotted-bottor-bottom">
                <b>Priority</b> legal counsel when necessary  <br />
                <br />
                We review for violations of the Fair Credit Reporting Act and,
                when appropriate, file a lawsuit on your behalf.  <br />
                <br />
                We review for violations of the Fair Debt Collections Act and,
                when appropriate, file a lawsuit on your behalf.  <br />
                <br />
                We review for violations of the Fair Credit Billing Act and,
                when appropriate, file a lawsuit on your behalf.  <br />
                <br />
                Is your bankruptcy being reported correctly? If not, we will
                provide legal support.
              </p>
              <p className="dotted-bottor-bottom">
                Lifetime access to 7 Steps to a 720 Credit Score
              </p>
              <p className="dotted-bottor-bottom">
                Priority support during monthly Q&A credit education sessions
              </p>
              <p className="dotted-bottor-bottom" style={{ border: "none" }}>
                Start today
                <br />
                Automatic enrolllement into 7 Steps to a 720 Credit Score
                <br />
                $39/month for 36 months
                <br />
                Or, cancel when you reach your credit-score goal
                <br />
                $99 setup fee
              </p>
              <div
                className="d-flex justify-content-center position-relative upgrade-btn"
                style={{ top: "32px" }}
              >
                <a
                  className="upgrade_button"
                  target="_blank"
                  href="https://form.typeform.com/to/Uyms4Fwd?typeform-source=www.evergreenclass.com"
                >
                  <button className="">Upgrade Now</button>
                </a>
              </div>
            </div>
          </div>
          <div className="plans_footer">
            <p className="text-align-center mb-5">
              You will receive an email inviting you to enroll in our free
              credit-education program (7 Steps to a 720 Credit Score) upon
              completion of Courses 1 and 2. (Or,{" "}
              <span>
                <a
                  style={{ color: "#407BFF", textDecoration: "underline" }}
                  href="https://form.typeform.com/to/Uyms4Fwd?typeform-source=www.evergreenclass.com"
                  target="_blank"
                >
                  join the Credit Rebuilder Program
                </a>
              </span>
              , and start today!)
            </p>
            <p className="doing">
              Questions about the Credit Rebuilder Program? Call <span style={{ color: "#4e9c4b" }}> 800-581-3513 </span>.
            </p>
          </div>

        </div>
      </section> */}
      {/* <section className="course3_reason">
        <h1 className="heading">
          More Reasons to Upgrade to the Credit Rebuilder Program
        </h1>
        <div className="theme_container">
          <div className="mt-60 grid-container">
            <div>
              <img src={course3_reason1} alt="" />
              <h1>No Credit Checks</h1>
              <p>
                Everyone is approved, so you can start your journey to better
                credit without worrying about your current credit score
                impacting eligibility.
              </p>
            </div>
            <div>
              <img src={course3_reason2} alt="" />
              <h1>Free Debt Analysis</h1>
              <p>
                One of our licensed consumer law attorneys will analyze your
                debt and help you develop strategies for financial freedom.
              </p>
            </div>
            <div>
              <img src={course3_reason3} alt="" />
              <h1>Priority Support</h1>
              <p>
                You will receive top-tier support and priority access to credit
                experts during monthly Q&A sessions.
              </p>
            </div>
            <div>
              <img src={course3_reason4} alt="" />
              <h1>Legal and Consumer Protection</h1>
              <p>
                If lenders or credit bureaus have broken laws in collecting or
                reporting your debt, you will receive free legal advice and
                consumer protection counsel. In fact, our attorneys might help
                you receive financial compensation.
              </p>
            </div>
            <div>
              <img src={course3_reason5} alt="" />
              <h1>Involuntary Unemployment Protection</h1>
              <p>
                Continue your membership without disruption, even during periods
                of unexpected unemployment.
              </p>
            </div>
            <div>
              <img src={course3_reason6} alt="" />
              <h1>Lifetime Access and Updates</h1>
              <p>
                The 7 Steps to a 720 Credit Education Course included in your
                membership breaks down the exact rules for building a high
                credit score in just 12 to 24 months.
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <a
              target="_blank"
              className="upgrade_button"
              href="https://form.typeform.com/to/Uyms4Fwd?typeform-source=www.evergreenclass.com"
            >
              <button className="">Upgrade Now</button>
            </a>
          </div>
          <br />
          <br />
          <div id="course720to720"></div>
          <br />
          <br />
        </div>
      </section> */}
      {/* <section className="course3_about">
        <div className="theme_container">
          <h1 className="heading">
            About the 7 Steps to a 720 Credit{" "}
            <span style={{ color: "#4E9C4B" }}>Education Course</span>
          </h1>
          <div className="d-lg-flex justify-content-between align-items-start mt-60">
            <div className="content">
              <p>
                Most people think it takes seven to ten years to improve lousy
                credit. And that’s what most banks and lenders want you to
                think.  <br />
                <br />
                After all, they make more money in the form of high interest
                payments off borrowers with bad credit scores.  <br />
                <br />
                But the truth is: You can have a great credit score in 12 to 24
                months …  <br />
                <br />
                Even if you went through a bankruptcy, a foreclosure, and a
                repossession yesterday.  <br />
                <br />
                Even if you have dozens of collection accounts on your credit
                report.  <br />
                <br />
                You will automatically be enrolled in the 7 Steps to a 720
                Credit Education Course 30 days after completing Course #2.
                <br />
                <br />
                <b> Why do I have to wait 30 days?</b>
                <br />
                For best results, you should start 7 Steps to a 720 Credit Score
                as soon as your bankruptcy is discharged (Chapter 7) or
                confirmed (Chapter 13). Waiting 30 days gives your attorney and
                the bankruptcy courts time to process your bankruptcy.   <br />
                <br />
                <b>About 7 Steps to a 720 Credit Score</b>
                <br />
                This self-directed course is available to students who have
                completed Courses 1 and 2. Otherwise, it is available as part of
                the Credit Rebuilder Program.
              </p>
              <br />
              <a
                target="_blank"
                className="upgrade_button d-block"
                style={{ width: "326px" }}
                href="https://form.typeform.com/to/Uyms4Fwd?typeform-source=www.evergreenclass.com"
              >
                <button className="">UPGRADE AND START TODAY</button>
              </a>
              <br />
              <br />
              <br />
            </div>
            <div className="credit_score">
              <p>
                In just one or two years, you will have a great credit score
                and:
              </p>
              <ul>
                <li>
                  Qualify for the best credit cards with low interest rates and
                  great rewards.
                </li>
                <li>Save thousands of dollars on your car and home loans.</li>
                <li>Refinance existing debt to lower your monthly payments.</li>
                <li>
                  Never again feel worried and ashamed when lenders, landlords,
                  or employees pull your credit report.
                </li>
              </ul>
            </div>
          </div>
          <p>
            7 Steps to a 720 Credit Score teaches you how to implement fast
            credit strategies in about 15 or 20 minutes a week.  <br />
            <br />
            Here’s what makes our program different: We aren’t trying to game
            the system. We don’t do anything unethical. Over the course of three
            months, you will simply take a few easy action steps so that your
            score improves naturally.
          </p>
          <div>
            <p className="doing" style={{ textAlign: "center" }}>
              Questions about the Credit Rebuilder Program? Call <span style={{ color: "#4e9c4b" }}> 800-581-3513 </span>.
            </p>
          </div>

          <img className="mt-60" src={course3About} alt="" />
        </div>
      </section> */}
      <section className="LessonAction">
        <div className="theme_container">
          <div className="row  align-items-center justify-content-between">
            <div className="col-lg-5">
              <div className="">
                <h5 className="f-26">Bite-Sized Lessons</h5>
                <p className="mt-20 f-16">
                  7 Steps to a 720 Credit Score program consists of a handful of
                  instructional videos that are available through our secure
                  credit portal. The tutorials are ten to fifteen minutes long,
                  and you’ll spend about ten more minutes implementing the
                  simple steps for raising your credit score.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="">
                <div className="">
                  <img src={img_1} alt="img_1" />
                </div>
              </div>
            </div>
          </div>
          <div className="row  mt-130  align-items-center justify-content-between row_rev">
            <div className="col-lg-5">
              <div className="">
                <div className="">
                  <img src={img_2} alt="img_2" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about_content">
                <h5 className="f-26">Action Guides</h5>
                <p className="mt-20 f-16">
                  Our videos are accompanied by short, easy-to-follow action
                  guides that are just a couple of pages long. Our action guides
                  are packed with powerful information, like a 153-word letter
                  that you can use to help your score jump 20 to 40 points.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="credit_score_sec">
        <h2 className="credit_score_sec__heading">What Will a Higher <span className="parret"> Credit Score </span>  Do for You?</h2>
        <div className="theme_container">
          <div className=" credit_score_sec__row">
              <div className="credit_score_sec_box">
                <p className="credit_score_sec_box__para">
                So far, my a car, and I got three credit cards!
                </p>
                <div className="flex-i credit_score_sec_box__img__row">
                  <img  src={profileImage} alt="" className="credit_score_sec_box__img" />
                  <div className="credit_score_sec_box__img__content">
                    <h5 className="credit_score_sec_box__img__content__name">Barbara Bonney</h5>
                    <h5 className="credit_score_sec_box__img__content__city">Troy, MO </h5>
                  </div>
                </div>
              </div>
              <div className="credit_score_sec_box">
                <p className="credit_score_sec_box__para">
                So far, s; I purchased a car, and I got three credid at least 100 points; I purchased 
                </p>
                <div className="flex-i credit_score_sec_box__img__row">
                  <img  src={profileImage} alt="" className="credit_score_sec_box__img" />
                  <div className="credit_score_sec_box__img__content">
                    <h5 className="credit_score_sec_box__img__content__name">Barbara Bonney</h5>
                    <h5 className="credit_score_sec_box__img__content__city">Troy, MO </h5>
                  </div>
                </div>
              </div>
              <div className="credit_score_sec_box">
                <p className="credit_score_sec_box__para">
                So far, my a car, and I got three credit cards!
                </p>
                <div className="flex-i credit_score_sec_box__img__row">
                  <img  src={profileImage} alt="" className="credit_score_sec_box__img" />
                  <div className="credit_score_sec_box__img__content">
                    <h5 className="credit_score_sec_box__img__content__name">Barbara Bonney</h5>
                    <h5 className="credit_score_sec_box__img__content__city">Troy, MO </h5>
                  </div>
                </div>
              </div>
              <div className="credit_score_sec_box">
                <p className="credit_score_sec_box__para">
                So far, s; I purchased a car, and I got three credid at least 100 points; I purchased 
                </p>
                <div className="flex-i credit_score_sec_box__img__row">
                  <img  src={profileImage} alt="" className="credit_score_sec_box__img" />
                  <div className="credit_score_sec_box__img__content">
                    <h5 className="credit_score_sec_box__img__content__name">Barbara Bonney</h5>
                    <h5 className="credit_score_sec_box__img__content__city">Troy, MO </h5>
                  </div>
                </div>
              </div>
              <div className="credit_score_sec_box">
                <p className="credit_score_sec_box__para">
                So far, my a car, and I got three credit cards!
                </p>
                <div className="flex-i credit_score_sec_box__img__row">
                  <img  src={profileImage} alt="" className="credit_score_sec_box__img" />
                  <div className="credit_score_sec_box__img__content">
                    <h5 className="credit_score_sec_box__img__content__name">Barbara Bonney</h5>
                    <h5 className="credit_score_sec_box__img__content__city">Troy, MO </h5>
                  </div>
                </div>
              </div>
              <div className="credit_score_sec_box">
                <p className="credit_score_sec_box__para">
                So far, s; I purchased a car, and I got three credid at least 100 points; I purchased 
                </p>
                <div className="flex-i credit_score_sec_box__img__row">
                  <img  src={profileImage} alt="" className="credit_score_sec_box__img" />
                  <div className="credit_score_sec_box__img__content">
                    <h5 className="credit_score_sec_box__img__content__name">Barbara Bonney</h5>
                    <h5 className="credit_score_sec_box__img__content__city">Troy, MO </h5>
                  </div>
                </div>
              </div>
            </div>
          <div className="credit_score_sec__row2">
          <div className="credit_score_sec_box credit_score_sec_box--2">
                <p className="credit_score_sec_box__para">
                I didn’t know anything about the credit system, but when I filed my third bankruptcy, my attorney made me join this program. Now, my credit score is right around 720, and I no longer feel helpless and ashamed. I  encourage anybody who has had a history of problems with their money to  GET EDUCATED!
                </p>
                <div className="flex-i credit_score_sec_box__img__row">
                  <img  src={profileImage} alt="" className="credit_score_sec_box__img" />
                  <div className="credit_score_sec_box__img__content">
                    <h5 className="credit_score_sec_box__img__content__name">Barbara Bonney</h5>
                    <h5 className="credit_score_sec_box__img__content__city">Troy, MO </h5>
                  </div>
                </div>
              </div>
              <div className="credit_score_sec_box credit_score_sec_box--2">
                <p className="credit_score_sec_box__para">
                With a credit score of 780 just five years after my bankruptcy, I closed on  my first home: A three-bedroom rambler on seven acres with enough room  for my dog and my horse. It’s an amazing feeling, and something I  thought would never happen, but it did. It never would have happened  without the help of the 720 credit score program.
                </p>
                <div className="flex-i credit_score_sec_box__img__row">
                  <img  src={profileImage} alt="" className="credit_score_sec_box__img" />
                  <div className="credit_score_sec_box__img__content">
                    <h5 className="credit_score_sec_box__img__content__name">Barbara Bonney</h5>
                    <h5 className="credit_score_sec_box__img__content__city">Troy, MO </h5>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
      <section className="FAQ mt-130">
        <div className="theme_container">
          <div className="heading_Wrap">
            <h1 className="f-52">
              Frequently Asked{" "}
              <span style={{ color: "#4E9C4B" }}>Questions</span>
            </h1>
            <p className="f-16 text-center mt-35">
              Our videos are accompanied by short, easy-to-follow action guides
              that are just a couple of pages long. Our action guides are packed
              with powerful information, like a 153-word letter that you can use
              to help your score jump 20 to 40 points.
            </p>
          </div>
          <div className="faq_Wrap mt-60">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-h1">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-1"
                    aria-expanded="false"
                    aria-controls="flush-1"
                  >
                    When will the class start?
                  </button>
                </h2>
                <div
                  id="flush-1"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-h1"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    If you are enrolled through the Credit Rebuilder Program,
                    your class will start immediately. Otherwise, you will be
                    invited to enroll in your free education class, 7 Steps to a
                    720 Credit Score, 30 days after you have completed the
                    second course through Evergreen Financial Counseling. Be on
                    the lookout for an email from Evergreen with your login
                    information. This email will direct you to a portal that
                    contains all the lessons you need to raise your credit score
                    to 720
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-h2">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-2"
                    aria-expanded="false"
                    aria-controls="flush-2"
                  >
                    I don’t have a lot of time... what do you recommend?
                  </button>
                </h2>
                <div
                  id="flush-2"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-h2"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Watch the first three videos of the sequence, which are the
                    most important. And remember, you can always upgrade to the
                    Credit Rebuilder Program, and we will do the work for you.
                    <br />
                    <a
                      target="_blank"
                      className="d-block"
                      href="https://form.typeform.com/to/Uyms4Fwd?typeform-source=www.evergreenclass.com"
                      style={{ color: "#407BFF", textDecoration: "underline" }}
                    >
                      Upgrade to the Credit Rebuilder Program
                    </a>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-h3">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-3"
                    aria-expanded="false"
                    aria-controls="flush-3"
                  >
                    Can my wife, kids, and family use my login?
                  </button>
                </h2>
                <div
                  id="flush-3"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-h3"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Absolutely. Feel free to share your membership with your
                    family.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-h4">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-4"
                    aria-expanded="false"
                    aria-controls="flush-4"
                  >
                    How much work is involved?
                  </button>
                </h2>
                <div
                  id="flush-4"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-h4"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Not much. Each video is about 3-8 minutes long. If you do
                    nothing but watch the Welcome Video and implement the action
                    items from the first three videos of the regular series,
                    your credit score will start to increase, and in 12 to 24
                    months, you will have a 720 credit score (assuming your
                    credit is currently very poor).
                    <br />
                    Watching the videos and completing the action items from
                    these three videos will take about one hour.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-h5">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-5"
                    aria-expanded="false"
                    aria-controls="flush-5"
                  >
                    What is the difference between this program and credit
                    repair programs?
                  </button>
                </h2>
                <div
                  id="flush-5"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-h5"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Credit repair programs charge between $89-$119 per month for
                    their service, which don’t always work. Many of them dispute
                    accurate information and try to game the system, which is
                    illegal and unethical.
                    <br />7 Steps is credit education that explains how the
                    system works, and how you can earn a great credit score. If
                    you follow the techniques we teach, your credit score will
                    go up every time.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-h6">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-6"
                    aria-expanded="false"
                    aria-controls="flush-6"
                  >
                    What if I have REALLY bad credit as a result of bankruptcy,
                    foreclosure, or some other financial meltdown? Will the
                    program still work?
                  </button>
                </h2>
                <div
                  id="flush-6"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-h6"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Absolutely! This system was designed for people with
                    bankruptcy, foreclosure, or other financial problems. A
                    bankruptcy stays on your credit report for ten years, but
                    keep in mind that a credit report and a credit score are not
                    the same things. By going through our program, you will
                    learn how to rebuild your credit score to 720 in as little
                    as 12 to 24 months.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-h7">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-7"
                    aria-expanded="false"
                    aria-controls="flush-7"
                  >
                    Should I buy my credit score before I start the program?
                  </button>
                </h2>
                <div
                  id="flush-7"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-h7"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    No! Save your money. We prefer that you don’t pull your
                    credit report or buy your credit score until our program
                    directs you to do so. We do not want you to make the mistake
                    of pulling your information from the wrong place. The
                    program will teach you where to get the best credit report
                    and credit score (for free).
                    <br />
                    Or, when you join the Credit Rebuilder Program we will help
                    you pull your credit report for free.
                    <a
                      target="_blank"
                      className="d-block"
                      href="https://form.typeform.com/to/Uyms4Fwd?typeform-source=www.evergreenclass.com"
                      style={{ color: "#407BFF", textDecoration: "underline" }}
                    >
                      Upgrade to the Credit Rebuilder Program and Start Today
                    </a>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-h8">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-8"
                    aria-expanded="false"
                    aria-controls="flush-8"
                  >
                    I have no credit. Will this program still work?
                  </button>
                </h2>
                <div
                  id="flush-8"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-h8"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Yes, our program works faster with people with no credit.
                    From a lender’s perspective, no credit is just as bad as
                    poor credit, so you should start building your credit using
                    the strategies we suggest in the videos. Your score will go
                    up faster than it would if you had a low credit score.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-h9">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-9"
                    aria-expanded="false"
                    aria-controls="flush-9"
                  >
                    How fast will I see my credit score increase?
                  </button>
                </h2>
                <div
                  id="flush-9"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-h9"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    In general, you should have a 720 credit score in 12 to 24
                    months, but this depends on what your score is right now,
                    and how quickly you can implement the steps. If your score
                    is currently 650, you will have a 720 credit score much
                    faster than if you have a 500.
                    <br />
                    Regardless, you will start saving money at each credit
                    milestone. For instance, if your score is 550, you can
                    refinance when your score hits 620, and again when it hits
                    680, and again when it hits 720 and 750.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-h10">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-10"
                    aria-expanded="false"
                    aria-controls="flush-10"
                  >
                    Why is a 720 credit score so important?
                  </button>
                </h2>
                <div
                  id="flush-10"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-h10"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    A 720 credit score is the lowest score you need to ensure
                    great rates.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-h11">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-11"
                    aria-expanded="false"
                    aria-controls="flush-11"
                  >
                    What if I didn’t take Course 2 through Evergreen?
                  </button>
                </h2>
                <div
                  id="flush-11"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-h11"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    This self-directed course is available to students who have
                    completed Courses 1 and 2. Otherwise, it is available as
                    part of the Credit Rebuilder Program.
                    <br />
                    <a
                      target="_blank"
                      className="d-block"
                      href="https://form.typeform.com/to/Uyms4Fwd?typeform-source=www.evergreenclass.com"
                      style={{ color: "#407BFF", textDecoration: "underline" }}
                    >
                      Upgrade to the Credit Rebuilder Program and Start Today
                    </a>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-h12">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-12"
                    aria-expanded="false"
                    aria-controls="flush-12"
                  >
                    When does the course start?
                  </button>
                </h2>
                <div
                  id="flush-12"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-h12"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    For best results, you should start 7 Steps to a 720 Credit
                    Score as soon as your bankruptcy is discharged (Chapter 7)
                    or confirmed (Chapter 13). As such, we will enroll you 30
                    days after you have completed Course #2. This gives your
                    attorney and the bankruptcy courts time to process your
                    bankruptcy.
                    <br />
                    <a
                      target="_blank"
                      className="d-block"
                      href="https://form.typeform.com/to/Uyms4Fwd?typeform-source=www.evergreenclass.com"
                      style={{ color: "#407BFF", textDecoration: "underline" }}
                    >
                      Upgrade to the Credit Rebuilder Program and Start Today
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Course3;
