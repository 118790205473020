import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import { useDispatch } from "react-redux";
import { login } from "../../store/userSlice";
import { editAttorney, getUserData } from "../../api/server";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DeleteModal from "../../common/deleteModal";
import { attorneyBillingOptions } from "../../constants";

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("Name is required"),
  email: Yup.string().required("Your Email is required"),
  phone: Yup.string().required("Phone Number is required"),
  addr1: Yup.string().required("Address Line 1 is required"),
  addr2: Yup.string(),
  city: Yup.string().required("city is required"),
  state: Yup.string().required("State is required"),
  zip: Yup.string().required("Zip Code is required"),
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
  attorneyCode: Yup.string().required("Attorney Code is required"),
  deliverymethod: Yup.string().required("Billing Option is required"),
  secondaryEmails: Yup.array().of(
    Yup.object({
      email: Yup.string().required("Email is required"),
    })
  ),
});

const EditAttorney = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const attorney = queryParams.get("id");
  const [attorneyData, setAttorneyData] = useState();
  const [secondaryEmails, setSecondaryEmails] = useState();
  const [getLoading, setGetLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteEmail, setDeleteEmail] = useState("");
  const [showPass, setShowPass] = useState(false);

  const handleSubmit = async (values) => {
    setUpdateLoading(true);
    try {
      const response = await editAttorney(attorney, values);
      if (response) {
        toast.success("Attorney updated successfully");
        setUpdateLoading(false);
      }
    } catch (error) {
      setUpdateLoading(false);
      toast.error(error.response.data.error);
      console.error("Error updating user data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getUserData(attorney, "?type=Atty")
        .then((res) => {
          const data = {
            ...res.data.data,
            deliverymethod: res.data.data.deliverymethod
              ? res.data.data.deliverymethod
              : "client",
          };
          setSecondaryEmails(res.data.secondaryEmails);
          setAttorneyData({
            ...data,
            firstname: data.firstname.replace("&amp;", "&"),
          });
          setGetLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data.error);
          setGetLoading(false);
        });
    };
    fetchData();
  }, []);

  const handleCopy = async (url) => {
    await navigator.clipboard.writeText(url);
    toast.success("URL copied");
  };


  console.log(attorneyData?.password);
  return (
    <div>
      <div className="wrapper">
        <DashBoardSidebar />
        <div id="content">
          <DashboardNavbar />
          {getLoading ? (
            <p>
              <ThreeDots
                height="40"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                  display: "flex",
                  justifyContent: "center",
                }}
                wrapperClassName="spinner"
                visible={true}
              />
            </p>
          ) : (
            <Formik
              initialValues={{
                ...attorneyData,
                secondaryEmails: [...secondaryEmails],
              }} // Set the initial values from the fetched user data
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                handleChange,
                handleBlur,
                errors,
                touched,
                values,
                setValues,
              }) => (
                <Form>
                  {/* Main Filer Info */}
                  <DeleteModal
                    showModal={deleteEmail}
                    setDelete={setDeleteEmail}
                    type="button"
                    handleSubmit={() => {
                      setValues((prev) => ({
                        ...prev,
                        secondaryEmails: prev.secondaryEmails.filter(
                          (i, ind) => ind !== deleteEmail
                        ),
                      }));
                      setDeleteEmail("");
                    }}
                  />
                  {values.attorneyCode && (
                    <p>
                      Association Link:{" "}
                      <b>
                        https://evergreenclass.com/registration?code=
                        {values.attorneyCode}
                      </b>
                      <button
                        class="bg-white border-0 ms-5"
                        type="button"
                        onClick={() => {
                          handleCopy(
                            "https://evergreenclass.com/registration?code=" +
                            values.attorneyCode
                          );
                        }}
                      >
                        <i class="fa fa-clone"></i>
                      </button>
                    </p>
                  )}
                  <div className="col-lg-12">
                    <div className="input_wrap">
                      <label className="label-title">Notes</label>
                      <textarea
                        name="notes"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.notes}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <section className="client-editing">
                    <div className="client-head">
                      <h1 className="f18">Personal Info</h1>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input_wrap">
                          <label className="label-title">Law Firm Name</label>
                          <Field
                            type="text"
                            name="firstname"
                            readOnly
                            className="form-control"
                          />
                          <ErrorMessage
                            name="firstname"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input_wrap">
                          <label className="label-title">
                            Choose a Username
                          </label>
                          <Field
                            type="text"
                            name="username"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            name="username"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-lg-6 mb-4">
                        <label className="from-label">
                          Automatically upload Certificates?
                        </label>
                      </div>
                      <div className="col-lg-6 mb-4">
                        <div className="iagree_radio  form-check-inline">
                          <div>
                            <input
                              type="checkbox"
                              name="tickbox"
                              checked={values?.attypref === "checked"}
                              onChange={() => {
                                setValues({ ...values, attypref: "checked" });
                              }}
                              id="attyprefYes"
                              className=" "
                            />
                            <label for="attyprefYes">Yes</label>
                          </div>
                        </div>
                        <div className="iagree_radio form-check-inline">
                          <div>
                            <input
                              type="checkbox"
                              name="tickbox"
                              id="attyprefNo"
                              checked={values?.attypref !== "checked"}
                              onChange={() => {
                                setValues({ ...values, attypref: "" });
                              }}
                              className=" "
                            />
                            <label for="attyprefNo">No</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Contact Info */}
                    <div className="client-head">
                      <h1 className="f18">Contact Info</h1>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input_wrap">
                          <label className="label-title">Address Line 1</label>
                          <Field
                            type="text"
                            name="addr1"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="addr1"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input_wrap">
                          <label className="label-title">Address Line 2</label>
                          <Field
                            type="text"
                            name="addr2"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="addr2"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input_wrap">
                          <label className="label-title">City</label>
                          <Field
                            type="text"
                            name="city"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input_wrap">
                          <label className="label-title">State</label>
                          <Field
                            type="text"
                            name="state"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="state"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input_wrap">
                          <label className="label-title">Zip Code</label>
                          <Field
                            type="text"
                            name="zip"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="zip"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input_wrap">
                          <label className="label-title">Phone Number</label>
                          <Field
                            type="text"
                            name="phone"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input_wrap">
                          <label className="label-title">
                            Certificate E-mail Address
                          </label>
                          <Field
                            type="text"
                            name="email"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input_wrap">
                          <label className="label-title">Password</label>
                          <Field
                            name="password"
                            type={showPass ? "text" : "password"}
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          {showPass ? (
                            <i
                              className="fa fa-eye position-absolute cursor-pointer pt-lg-1"
                              style={{ top: "20px", right: "10px" }}
                              onClick={() => {
                                setShowPass(false);
                              }}
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className="fa fa-eye-slash position-absolute cursor-pointer pt-lg-1"
                              style={{ top: "20px", right: "10px" }}
                              onClick={() => {
                                setShowPass(true);
                              }}
                              aria-hidden="true"
                            ></i>
                          )}
                          {errors.password && touched.password ? (
                            <p className="text-danger">{errors.password}</p>
                          ) : null}
                        </div>
                        {attorneyData?.passwordMessage && <p style={{ marginTop: "-40px", marginBottom: "30px", fontSize: "14px", color: "green" }}>{attorneyData?.passwordMessage}</p>}
                      </div>
                      <div className="col-lg-4">
                        <div className="input_wrap">
                          <label className="label-title">Attorney Code</label>
                          <Field
                            type="text"
                            name="attorneyCode"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="attorneyCode"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="input_wrap">
                          <label
                            className="label-title"
                            htmlFor="securedLoanType"
                          >
                            Billing Options
                          </label>
                          <Field
                            as="select"
                            id="securedLoanType"
                            name="deliverymethod"
                            className="form-control custom_select"
                          >
                            {
                              attorneyBillingOptions.map((option)=>(
                                <option value={option.value}>{option.label}</option>
                              ))
                            }
                          </Field>
                          <ErrorMessage
                            name="deliverymethod"
                            component="div"
                            className="error-message text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="client-head">
                      <div className="row align-items-center">
                        <div className="col-lg-4">
                          <h1 className="f18">Secondary Emails</h1>
                        </div>
                        <div className="col-lg-8 text-md-end">
                          <button
                            type="button"
                            className="btn_brnd"
                            onClick={() => {
                              setValues({
                                ...values,
                                secondaryEmails: [
                                  ...values.secondaryEmails,
                                  { email: "" },
                                ],
                              });
                            }}
                          >
                            Add Secondary
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {values.secondaryEmails &&
                        values.secondaryEmails.length > 0 &&
                        values.secondaryEmails.map((item, index) => (
                          <div
                            className="col-lg-4 d-flex align-items-start secondary-emails"
                            key={index}
                          >
                            <div className="input_wrap w-100">
                              <label className="label-title">
                                Email {index + 1}
                              </label>
                              <Field
                                type="text"
                                value={item.email}
                                onChange={(e) =>
                                  setValues({
                                    ...values,
                                    secondaryEmails: values.secondaryEmails.map(
                                      (i, ind) =>
                                        ind !== index
                                          ? i
                                          : { email: e.target.value }
                                    ),
                                  })
                                }
                                className="form-control"
                              />
                              {errors.secondaryEmails &&
                                errors.secondaryEmails.length > index &&
                                errors.secondaryEmails[index]?.email &&
                                touched.secondaryEmails &&
                                touched.secondaryEmails.length > index &&
                                touched.secondaryEmails[index]?.email ? (
                                <p className="error text-danger">
                                  {errors.secondaryEmails[index]?.email}
                                </p>
                              ) : null}
                            </div>
                            <div className="action-btns mt-3 ms-2">
                              <button
                                className="btn-delete"
                                type="button"
                                onClick={() => {
                                  setDeleteEmail(index);
                                }}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </button>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="text-center">
                      <button
                        className="btn_brnd my-3"
                        type="submit"
                        disabled={updateLoading}
                      >
                        {updateLoading ? (
                          <ThreeDots
                            height="20"
                            width="60"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{
                              display: "block",
                            }}
                            wrapperClassName="spinner"
                            visible={true}
                          />
                        ) : (
                          "Update"
                        )}
                      </button>
                    </div>
                  </section>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditAttorney;
