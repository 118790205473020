import React, { useEffect, useState } from "react";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import { toast } from "react-toastify";
import {
  getUserData,
  updateFollowUp,
  updateFollowUpRetake,
} from "../../api/server";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

const Followup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");
  const courseId = queryParams.get("courseId");
  const retake = Boolean(
    queryParams.get("retake") !== null &&
      queryParams.get("retake") != "null" &&
      queryParams.get("retake") != "false" &&
      queryParams.get("retake") !== false
  );

  console.log(retake);

  const [response, setResponse] = useState();
  const [getloading, setGetLoading] = useState(true);

  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await getUserData(userId)
        .then((res) => {
          setResponse(res.data.data.user);
          setGetLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data.error);
          setGetLoading(false);
        });
    };
    fetchData();
  }, []);

  const handleFolloup = async (type) => {
    retake
      ? updateFollowUpRetake(userId, {
          request: type,
          course: "first",
          courseId: courseId,
        })
          .then((res) => {
            if (type === "attempt") {
              toast.success(`Follow-up attempted successfully`);
            } else {
              toast.success(`Follow-up ${type} successfully`);
            }

            setUpdateLoading(false);
            navigate("/certificates");
          })
          .catch((err) => {
            toast.error(err.response.data.error);
            setUpdateLoading(false);
          })
      : updateFollowUp(userId, {
          request: type,
          course: "first",
          courseId: courseId,
        })
          .then((res) => {
            if (type === "attempt") {
              toast.success(`Follow-up attempted successfully`);
            } else {
              toast.success(`Follow-up ${type} successfully`);
            }

            setUpdateLoading(false);
            navigate("/certificates");
          })
          .catch((err) => {
            toast.error(err.response.data.error);
            setUpdateLoading(false);
          });
  };

  return (
    <div>
      <div className="wrapper">
        <DashBoardSidebar />
        <div id="content">
          <DashboardNavbar />
          {getloading || !response ? (
            <p>
              <ThreeDots
                height="40"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                  display: "flex",
                  justifyContent: "center",
                }}
                wrapperClassName="spinner"
                visible={true}
              />
            </p>
          ) : (
            <div>
              <h4 className="card-title">Follow up</h4>
              <div className="head mt-60">
                <h1 className="f18">Identity Confirmation:</h1>
              </div>
              <p className="mt-3 ms-3">
                Ask the client to verify their name, phone number, as well as
                attorney name (as applicable).
              </p>
              <div className="row mt-4 mb-0 ms-md-1">
                <p className="col-md-4">
                  Main Filer{" "}
                  <span className="fw-700">
                    {response.firstname} {response.middleinitial}{" "}
                    {response.lastname}
                  </span>
                </p>
                <p className="col-md-4">
                  Username <span className="fw-700">{response.username}</span>
                </p>
                <p className="col-md-4">
                  SSN <span className="fw-700">21{response.ssn}</span>
                </p>

                {response.joint_username && (
                  <>
                    <p className="col-md-4">
                      Joint Filer Name{" "}
                      <span className="fw-700">
                        {response.joint_firstname} {response.joint_lastname}
                      </span>
                    </p>
                    <p className="col-md-4">
                      Joint Filer SSN{" "}
                      <span className="fw-700">{response.joint_ssn}</span>
                    </p>
                    <p className="col-md-4">
                      Joint Filer Username{" "}
                      <span className="fw-700">{response.joint_username}</span>
                    </p>
                  </>
                )}
                <p className="col-md-4">
                  Phone <span className="fw-700">{response.phone}</span>
                </p>
                <p className="col-md-4">
                  Email <span className="fw-700">{response.email}</span>
                </p>
                {response.attorneyname && (
                  <p className="col-md-4">
                    Attorney{" "}
                    <span className="fw-700">{response.attorneyname}</span>
                  </p>
                )}
              </div>
              <div>
                <p className="fw-700 mt-4 mb-0">Questions:</p>
                <p className="ms-md-3">
                  Ask if Client(s) has any questions regarding Credit Counseling
                  Session: Remember, the purpose of this call is to ensure
                  client fully participated and understood their online credit
                  counseling session. Ask open-ended questions to encourage
                  client participation.
                </p>
                <p className="fw-700 mt-4 mb-0">
                  Review Client's Budget Analysis:
                </p>
                <ol className="ms-md-4">
                  <li>
                    Monthly Household Income and Expenses Overview: Confirm
                    Information provided is full and complete.
                  </li>
                  <li>
                    How'd This Happen: Confirm factors that led to their current
                    financial situation. Use open-ended, judgment-free questions
                    to encourage open dialogue.
                  </li>
                  <li>
                    Detailed Expenses: Highlight areas of overspending, Review
                    Debt-to-Income ratio, and Review Evergreen Suggestions.
                  </li>
                  <li>
                    Detailed Debts: Review Creditor List and Pay-off schedule.
                    Ensure Client understands how to utilize Pay-off Calculator.
                  </li>
                  <li>
                    Client Plan: Confirm Client understands they many ways they
                    can respond to the financial situation.
                  </li>
                </ol>
                <p className="fw-700 mt-4 mb-0">Conclusion:</p>
                <p className="ms-md-3">
                  Remind Client of Evergreen motto: True Worth. “At times it
                  appears as if everything in life has a price tag. However, you
                  as a person are priceless, regardless of how much money you
                  have or owe.”
                </p>
                <p className="fw-700 mt-4 mb-0">Certificate Delivery: </p>
                <p className="ms-md-3">Verify email address</p>
              </div>
              <div className="btn_wrap mt-40 text-center">
                <button className="btn_brnd-outline me-4 mb-3">
                  <Link to={`/editstudent?id=${userId}`}>
                    View Full Account
                  </Link>
                </button>
                <button
                  className="btn_brnd  me-4 mb-3"
                  onClick={() => {
                    setUpdateLoading("completed");
                    handleFolloup("completed");
                  }}
                  disabled={updateLoading}
                >
                  {updateLoading === "completed" ? (
                    <ThreeDots
                      height="20"
                      width="75"
                      radius="9"
                      color="#4fa94d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{
                        display: "block",
                      }}
                      wrapperClassName="spinner"
                      visible={true}
                    />
                  ) : (
                    "Complete"
                  )}
                </button>
                <button
                  className="btn_brnd  me-4 mb-3"
                  onClick={() => {
                    setUpdateLoading("attempt");
                    handleFolloup("attempt");
                  }}
                  disabled={updateLoading}
                >
                  {updateLoading === "attempt" ? (
                    <ThreeDots
                      height="20"
                      width="75"
                      radius="9"
                      color="#4fa94d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{
                        display: "block",
                      }}
                      wrapperClassName="spinner"
                      visible={true}
                    />
                  ) : (
                    "Attempt"
                  )}
                </button>
                <button
                  className="btn_delete btn_brnd"
                  onClick={() => {
                    setUpdateLoading("archived");
                    handleFolloup("archived");
                  }}
                  disabled={updateLoading}
                >
                  {updateLoading === "archived" ? (
                    <ThreeDots
                      height="20"
                      width="75"
                      radius="9"
                      color="#4fa94d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{
                        display: "block",
                      }}
                      wrapperClassName="spinner"
                      visible={true}
                    />
                  ) : (
                    "Archive"
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Followup;
